import CheckboxCustom from '@/components/ui/Checkbox'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import React, {useMemo, useState} from 'react'
import {ButtonGroup, Form} from 'reactstrap'
import {StepContainer} from '@pages/onBoarding/styles.ts'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import StepClinicalInfoCta from '@pages/clinicalInfo/steps/StepClinicalInfoCta.tsx'
import {useNavigate} from 'react-router-dom'
import {PHASES, routes} from '@/utilities/constants'
import CommonModal from '@components/commons/Modal/index.tsx'
import {Card, putCard} from '@services/patient.http.ts'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const StepHabit: React.FC = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {
		step,
		habits,
		setClinicalInfoData,
		userType,
		setGeneralPatientData,
		patient,
		pathologies,
		allergies,
		medications,
		otherAllergies,
		otherPathologies
	} = useBoundStore()

	const {cardId} = patient
	const [modal, setModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const {cardsData, setCommonsData} = useCommonsStore()

	const habitsList = useMemo(() => {
		return cardsData?.clinical_data?.habits
	}, [cardsData])

	const handleConfirm = () => {
		if (cardId) {
			handlePutCard(cardId)
		}
	}

	const handlePutCard = async (id: number) => {
		const body: Card = {
			pathologies: pathologies,
			other_pathologies: otherPathologies || null,
			allergies: allergies,
			other_allergy: otherAllergies || null,
			drugs: medications.map(i => i.value) || null,
			alcoholic: habits?.includes(1),
			smoker: habits?.includes(2),
			drug_addict: habits?.includes(3)
		}
		setIsLoading(true)

		try {
			const {data} = await putCard(body, id)
			if (data) {
				setCommonsData('userPhase', PHASES.symptoms)
				setCommonsData('isNextAnimation', true)
				setGeneralPatientData('step', step + 1)
				navigate(`${routes.SYMPTOMS.path}`)
			}
		} catch (error) {
			setModal(false)
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const onCheckboxBtnClick = (selected: number) => {
		const index = habits?.indexOf(selected)
		if (index < 0) {
			habits?.push(selected)
		} else {
			habits?.splice(index, 1)
		}
		setClinicalInfoData('habits', [...habits])
	}

	return (
		<>
			<StepContainer>
				<Title
					title={t(`${userType}:clinicalInformation:habits:title`)}
					subTitle={t(`${userType}:clinicalInformation:habits:subtitle`)}
				/>
				<Form>
					<ButtonGroup className={'d-flex flex-column gap-2'}>
						{habitsList?.map(i => {
							return (
								<CheckboxCustom
									key={i.id}
									check={habits?.includes(i.id)}
									onChange={() => onCheckboxBtnClick(i.id)}
									name={i.name}
									label={i.name}
									description={i.description}
								/>
							)
						})}
					</ButtonGroup>
					<CommonModal
						title={t('commons:confirm_next') || ''}
						modal={modal}
						setModal={setModal}
						centered
						confirm={t('commons:confirm') || ''}
						isLoading={isLoading}
						onConfirm={handleConfirm}
						cancel={t('commons:edit') || ''}
						onCancel={() => setModal(false)}
					/>
				</Form>
			</StepContainer>

			<StepClinicalInfoCta onClick={() => setModal(true)} />
		</>
	)
}

export default StepHabit
