import styled, {css} from 'styled-components'

export const StyledContainerTextRecap = styled.div`
	${({theme}) => css`
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing * 4}px;
		padding: ${theme.spacing * 2}px ${theme.spacing * 4}px;
		border: 1px solid ${theme.palette.neutral['300']};
		border-radius: ${theme.spacing * 2}px;
	`}
`
export const StyledRecapTitle = styled.p`
	${({theme}) => css`
		text-transform: uppercase;
		${theme.typography.textSm}
		font-weight: 600;
	`}
`

export const StyledLi = styled.li`
	${({theme}) => css`
		${theme.typography.textMd}
		font-weight: 700;
	`}
`

export const StyledSpan = styled.span`
	${({theme}) => css`
		${theme.typography.textMd}
		font-weight: 700;
	`}
`
