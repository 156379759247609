import {useBoundStore} from '@/store/boundStore'
import React from 'react'
import {useTranslation} from 'react-i18next'
import ProgressCustom from '@components/ui/Progress'
import {StyledContainerReceivedData} from '@pages/clinicalInfo/style.ts'
import StepClinicalInfoCta from '@pages/clinicalInfo/steps/StepClinicalInfoCta.tsx'
import QRContainer from '@components/commons/QRContainer/index.tsx'
import {QR_TYPES} from '@/utilities/constants'
import IconsStepsStatus from '@components/commons/IconsStepsStatus'

const StepReceivedData: React.FC = () => {
	const {userType} = useBoundStore()
	const {t} = useTranslation()
	const progressValue = 60

	return (
		<>
			<div className={'overflow-scroll'} style={{height: '100%'}}>
				<StyledContainerReceivedData>
					<div className={'d-flex flex-column justify-content-center align-items-center gap-3'}>
						<div className={'d-flex gap-2 align-items-center'}>
							<IconsStepsStatus step={1} />
						</div>

						<div className={'d-flex flex-column justify-content-center align-items-center gap-2'}>
							<h4>{t(`${userType}:patientData:dataSent:title`)}</h4>
							<p className={'text-center'}>{t(`${userType}:patientData:dataSent:subtitle`)}</p>
						</div>

						<div className={'w-100 d-flex gap-2 justify-content-center align-items-center'}>
							<ProgressCustom value={progressValue} />
							<p className={'fw-600'}>{progressValue}%</p>
						</div>
					</div>
				</StyledContainerReceivedData>

				<QRContainer isColorful={false} codeType={QR_TYPES.white} canShowIt />
			</div>

			<StepClinicalInfoCta />
		</>
	)
}

export default StepReceivedData
