import {Input, Label} from 'reactstrap'
import {ChangeEvent, FC} from 'react'

interface ToggleProps {
	check?: boolean
	name?: string
	label?: string
	disabled?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const ToggleCustom: FC<ToggleProps> = ({check = false, name, label, disabled = false, onChange}) => {
	return (
		<>
			<div className="toggles pt-4 pb-4 ps-2 pe-2">
				<Label check className={'d-flex align-items-center'}>
					<Input
						type="checkbox"
						name={name}
						disabled={disabled}
						checked={check}
						className={'shadow-none'}
						onChange={onChange}
					/>
					<div className="lever m-0" />
					<p className={'ps-3'}>{label}</p>
				</Label>
			</div>
		</>
	)
}

export default ToggleCustom
