import * as pages from '@/pages'

export const routes = {
	ONBOARDING: {
		path: '/',
		element: <pages.OnBoarding />
	},

	CLINICALINFO: {
		path: '/clinical-info',
		element: <pages.ClinicalInfo />
	},

	SYMPTOMS: {
		path: '/symptoms',
		element: <pages.Symptoms />
	},
	FINISHED: {
		path: '/finished',
		element: <pages.Finished />
	},

	CODEASSIGNED: {
		path: '/code-assigned/:id',
		element: <pages.CodeAssigned />
	},

	CODE: {
		path: '/code-explanation',
		element: <pages.CodeExplanation />
	},

	ENTERED_INFORMATION: {
		path: '/entered-information',
		element: <pages.EnteredInformation />
	},

	FEEDBACK: {
		path: '/feedback',
		element: <pages.Feedback />
	},

	TRIAGE_CONCLUDED: {
		path: '/triage-concluded',
		element: <pages.TriageConcluded />
	},

	NOTFOUND: {
		path: '*',
		element: <pages.NotFound />
	}
}
