import ProgressCustom from '@components/ui/Progress'
import {StyledBlueDivider} from '@pages/clinicalInfo/style.ts'

interface AnimatedDividerProps {
	progressValue: number
}
const AnimatedDivider = ({progressValue}: AnimatedDividerProps) => {
	return (
		<StyledBlueDivider>
			<ProgressCustom value={progressValue} color={'primary'} />
		</StyledBlueDivider>
	)
}

export default AnimatedDivider
