import styled, {css} from 'styled-components'

export const StyledContainerChips = styled.div`
	${({theme}) => css`
		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		gap: ${theme.spacing * 3}px;
		align-self: stretch;
		flex-wrap: wrap;
		padding-bottom: ${theme.spacing * 6}px;
	`}
`

export const StyledContainerOptions = styled.div`
	${({theme}) => css`
		position: absolute;
		width: 100%;
		z-index: 1;
		padding: ${theme.spacing * 4}px;
		background-color: ${theme.palette.neutral.white};
		max-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		display: flex;
		flex-direction: column;
		gap: ${theme.spacing}px;
		border: 1px solid ${theme.palette.neutral[300]};
		border-bottom: 0px;
	`}
`

export const StyledContainerOption = styled.div`
	${({theme}) => css`
		& p {
			padding: ${theme.spacing * 2}px;
		}

		& span {
			margin-top: ${theme.spacing * 2}px;
			padding: 0;
			${theme.typography.textSm};
			font-weight: 600;
		}
	`}
`
