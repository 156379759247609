import React, {useEffect, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer, StyledContainerLighterBack} from '../styles.ts'
import Title from '@components/ui/Title'
import CardOption from '@components/ui/CardOption'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import RadioCustom from '@components/ui/Radio'
import {useTranslation} from 'react-i18next'
import {Form} from 'reactstrap'
import PhoneInput from '@components/commons/PhoneInput'
import CommonModal from '@components/commons/Modal/index.tsx'
import {SelectValue} from '@components/ui/Select'

const StepOtherPhone: React.FC = () => {
	const {t} = useTranslation()
	const {step, patient, setPatientData, setGeneralPatientData, userType, isInReview} = useBoundStore()
	const {otherPhoneNumberPrefix, otherPhoneNumber, otherSms} = patient
	const {setCommonsData} = useCommonsStore()

	const formRef = useRef<HTMLFormElement>(null)
	const [showError, setShowError] = useState(false)
	const [modal, setModal] = useState(false)

	useEffect(() => {
		setShowError(!otherSms)
		if (!otherSms) {
			setPatientData('otherPhoneNumber', undefined)
		}
	}, [otherSms])

	useEffect(() => {
		if (otherPhoneNumber) {
			setShowError(false)
		}
	}, [otherPhoneNumber])

	const handleConfirm = () => {
		if (otherSms !== undefined) {
			if (isInReview) {
				setGeneralPatientData('isInDrawer', false)
				setGeneralPatientData('step', 12)
			} else {
				setCommonsData('isNextAnimation', true)
				setGeneralPatientData('step', step + 1)
			}
		} else {
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:empty_field`) || '',
				status: 'danger'
			})
		}
	}

	const checkLength = otherPhoneNumber && otherPhoneNumber?.toString().length >= 10

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (!otherSms && !checkLength) {
			handleConfirm()
		} else if (otherSms && otherPhoneNumber && checkLength) {
			setModal(true)
		} else {
			setShowError(true)
		}
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title
					title={t(`${userType}:patientData:updates:title`)}
					subTitle={t(`${userType}:patientData:updates:subtitle`)}
				/>
				<Form innerRef={formRef} onSubmit={handleSubmit}>
					<CardOption
						withBorder={false}
						havePadding={false}
						title={t(`${userType}:patientData:updates:yes_title`)}
						subtitle={t(`${userType}:patientData:updates:yes_subtitle`)}
						onClick={() => setPatientData('otherSms', true)}
						children={
							<RadioCustom
								className={'m-0 p-0'}
								onChange={() => {
									setPatientData('otherSms', true)
								}}
								check={otherSms || false}
							/>
						}
					/>

					{otherSms && (
						<StyledContainerLighterBack className={'p-3'}>
							<PhoneInput
								prefix={otherPhoneNumberPrefix}
								phoneNumber={otherPhoneNumber}
								setPhoneNumber={(newValue: number) => {
									setPatientData('otherPhoneNumber', newValue)
								}}
								setPhonePrefix={(newValue: SelectValue) => {
									setPatientData('otherPhoneNumberPrefix', newValue)
								}}
								invalid={showError}
							/>
							<small className={'fw-600'}>{t('commons:info:step_11')}</small>
						</StyledContainerLighterBack>
					)}

					<CardOption
						withBorder={false}
						havePadding={false}
						title={t(`${userType}:patientData:updates:no_title`)}
						subtitle={t(`${userType}:patientData:updates:no_subtitle`)}
						onClick={() => setPatientData('otherSms', false)}
						children={
							<RadioCustom
								className={'m-0 p-0'}
								onChange={() => {
									setPatientData('otherSms', false)
								}}
								check={otherSms === false || false}
							/>
						}
					/>
				</Form>
			</StepContainer>
			<CommonModal
				title={t(`${userType}:patientData:phoneNumber:phone`) || ''}
				modal={modal}
				setModal={setModal}
				centered
				confirm={t('commons:confirmPhone') || ''}
				onConfirm={handleConfirm}
				cancel={t('commons:edit') || ''}
				onCancel={() => setModal(false)}
			>
				<h5>
					{otherPhoneNumberPrefix?.label} {otherPhoneNumber}
				</h5>
				<p>{t(`${userType}:patientData:phoneNumber:subtitle`)}</p>
			</CommonModal>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepOtherPhone
