import styled, {css, DefaultTheme} from 'styled-components'

type ChipStyleProps = {
	theme: DefaultTheme
	disabled?: boolean
}
export const StyledChip = styled.div`
	${({theme, disabled}: ChipStyleProps) => css`
		min-height: 36px;
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: ${theme.spacing * 6}px;
		padding: ${theme.spacing * 2}px ${theme.spacing * 3}px;
		color: ${theme.palette.primary[700]};
		border-radius: 50px;
		${theme.typography.textMd};
		font-weight: 700;

		${disabled &&
		css`
			opacity: 0.5;
			color: ${theme.palette.neutral[400]};
		`};

		& svg {
			cursor: pointer;
		}
	`}
`
