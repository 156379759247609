import {FC} from 'react'
import {StyledChip} from '@components/ui/Chip/style.ts'
import {XCloseIcon} from '@assets/icons/icons.tsx'

interface ChipProps {
	text: string
	canClose?: boolean
	disabled?: boolean
	handleClose?: () => void
	className?: string
}

const Chip: FC<ChipProps> = ({text, canClose = false, handleClose, disabled = false, className}) => {
	return (
		<StyledChip className={`chip bg-white border-primary ${className}`} disabled={disabled}>
			<div>{text}</div>
			{canClose && <XCloseIcon onClick={handleClose} />}
		</StyledChip>
	)
}

export default Chip
