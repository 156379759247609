import {createBrowserRouter} from 'react-router-dom'
import PrivateRoute from '@route-guards/private-route/PrivateRoute.tsx'
import PublicRoute from '@route-guards/public-route/PublicRoute.tsx'
import PublicLayout from '@layouts/public-layout/PublicLayout.tsx'
import PrivateLayout from '@layouts/private-layout/PrivateLayout.tsx'
import {routes} from '@utilities/constants'

const router = createBrowserRouter(
	[
		{
			element: <PrivateRoute />,
			children: [
				{
					element: <PrivateLayout />,
					children: [
						{...routes.CLINICALINFO},
						{...routes.SYMPTOMS},
						{...routes.FINISHED},
						{...routes.CODEASSIGNED},
						{...routes.CODE},
						{...routes.ENTERED_INFORMATION},
						{...routes.TRIAGE_CONCLUDED},
						{...routes.FEEDBACK}
					]
				}
			]
		},

		{
			element: <PublicRoute />,
			children: [
				{
					element: <PublicLayout />,
					children: [{...routes.ONBOARDING}]
				}
			]
		},
		{...routes.NOTFOUND}
	],
	{
		basename: '/'
	}
)

export default router
