import {useBoundStore} from '@/store/boundStore'
import React from 'react'
import StepReceivedData2 from './steps/StepReceivedData2'
import StepSearchSymptoms from './steps/StepSearchSymptoms'
import StepNotes from './steps/StepNotes'
import StepSymptom from '@pages/symptoms/steps/StepSymptom.tsx'

const Symptoms: React.FC = () => {
	const {step, selectedSymptoms} = useBoundStore()

	switch (step) {
		case 18:
			return <StepReceivedData2 />
		case 19:
			return <StepSearchSymptoms />
		default:
			if (selectedSymptoms[step - 20]) {
				return (
					<StepSymptom
						key={selectedSymptoms[step - 20].value}
						selectedSymptom={selectedSymptoms[step - 20]}
					/>
				)
			} else {
				return <StepNotes />
			}
	}
}

export default Symptoms
