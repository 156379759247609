import {ReactNode, useState} from 'react'
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap'

interface TabsProps {
	items: Array<{
		id: number
		label: string
		children: ReactNode
	}>
}

const Tabs = ({items}: TabsProps) => {
	const [activeTab, setActiveTab] = useState(0)

	const handleTabClick = (index: number) => {
		setActiveTab(index)
	}

	return (
		<div>
			<Nav
				tabs
				className={'justify-content-around'}
				style={{
					overflowX: 'auto',
					overflowY: 'hidden',
					WebkitOverflowScrolling: 'touch'
				}}
			>
				{items.map((item, index) => (
					<NavItem key={item.id}>
						<NavLink className={activeTab === index ? 'active' : ''} onClick={() => handleTabClick(index)}>
							{item.label}
						</NavLink>
					</NavItem>
				))}
			</Nav>
			<TabContent activeTab={activeTab}>
				{items.map((item, index) => (
					<TabPane tabId={index} key={item.id}>
						<div>{item.children}</div>
					</TabPane>
				))}
			</TabContent>
		</div>
	)
}

export default Tabs
