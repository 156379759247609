import styled, {css} from 'styled-components'

export const StyledContainerNotFound = styled.div`
	${({theme}) => css`
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100vh;
		width: 100%;
		gap: ${theme.spacing * 2}px;
	`}
`

export const StyledContainerP = styled.div`
	max-width: 600px;
	text-align: center;
`

export const StyledContainerButtonNotFound = styled.div`
	margin-top: 40px;
`
