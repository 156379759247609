import CheckboxCustom from '@/components/ui/Checkbox'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import React, {useMemo, useRef, useState} from 'react'
import Title from '@components/ui/Title'
import {StepContainer} from '@pages/onBoarding/styles.ts'
import {useTranslation} from 'react-i18next'
import {StyledContainerAddOther, StyledContainerCheckBox} from '@pages/clinicalInfo/style.ts'
import StepClinicalInfoCta from '@pages/clinicalInfo/steps/StepClinicalInfoCta.tsx'
import Input from 'src/components/ui/InputText'
import {Form} from 'reactstrap'

const StepAllergy: React.FC = () => {
	const {t} = useTranslation()
	const {userType} = useBoundStore()
	const {allergies, otherAllergies, setClinicalInfoData} = useBoundStore()
	const {cardsData} = useCommonsStore()
	const bodyRef = useRef<HTMLDivElement>(null)

	const [nobody, setNobody] = useState(false)
	const [other, setOther] = useState(false)

	const allergiesList = useMemo(() => {
		return cardsData?.clinical_data?.allergies || []
	}, [cardsData])

	const onCheckboxBtnClick = (selected: number) => {
		setNobody(false)
		const index = allergies?.indexOf(selected)
		if (index < 0) {
			allergies?.push(selected)
		} else {
			allergies?.splice(index, 1)
		}
		setClinicalInfoData('allergies', [...allergies])
	}

	const scrollToRef = () => {
		if (bodyRef.current) {
			bodyRef.current.scrollIntoView({behavior: 'smooth'})
		}
	}

	const handleNobody = () => {
		setNobody(!nobody)
		setClinicalInfoData('allergies', [])
		setOther(false)
	}

	const handleOther = () => {
		setOther(!other)
		setNobody(false)
		setClinicalInfoData('otherAllergies', '')
		setTimeout(() => {
			scrollToRef()
		}, 500)
	}

	return (
		<>
			<StepContainer>
				<Title
					title={t(`${userType}:clinicalInformation:allergies:title`)}
					subTitle={t(`${userType}:clinicalInformation:allergies:subtitle`)}
				/>
				<Form>
					<StyledContainerCheckBox>
						<CheckboxCustom
							check={nobody}
							onChange={handleNobody}
							name={t('commons:nothing') || ''}
							description={''}
							label={t('commons:nothing') || ''}
						/>
						{allergiesList?.map((allergy, index) => {
							return (
								<CheckboxCustom
									key={index}
									check={allergies?.includes(allergy.id)}
									onChange={() => onCheckboxBtnClick(allergy.id)}
									name={allergy.name}
									description={allergy.description}
									label={allergy.name}
								/>
							)
						})}

						<div>
							<CheckboxCustom
								check={other}
								onChange={handleOther}
								name={t('commons:other') || ''}
								label={t('commons:other') || ''}
							/>

							{other && (
								<StyledContainerAddOther>
									<div ref={bodyRef} />
									<Input
										type="text"
										label={t('commons:form:allergy')}
										placeholder={t('commons:form:allergy') || ''}
										onChange={e => setClinicalInfoData('otherAllergies', e.target.value)}
										value={otherAllergies}
									/>
								</StyledContainerAddOther>
							)}
						</div>
					</StyledContainerCheckBox>
				</Form>
			</StepContainer>
			<StepClinicalInfoCta />
		</>
	)
}

export default StepAllergy
