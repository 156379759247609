import {createElement, useEffect, useState} from 'react'
import {StepContainer, StyledContainerIconStep2, StyledStep2Animation} from '../styles.ts'
import Title from '@components/ui/Title'
import {HeartIcon, MedicalCrossIcon, UserIcon} from '@assets/icons/icons.tsx'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {useBoundStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'

const StepInfo: React.FC = () => {
	const {userType} = useBoundStore()
	const {t} = useTranslation()

	const [showAnimatedIcons, setShowAnimatedIcons] = useState([false, false, false])

	useEffect(() => {
		const animateIcons = (index: number) => {
			setTimeout(() => {
				setShowAnimatedIcons(prevState => {
					const updatedIcons = [...prevState]
					updatedIcons[index] = true
					return updatedIcons
				})
			}, index * 80)
		}

		const timeouts = [200, 600, 900].map((delay, index) => setTimeout(() => animateIcons(index), delay))

		return () => {
			timeouts.forEach(clearTimeout)
		}
	}, [])

	const iconComponents = [UserIcon, MedicalCrossIcon, HeartIcon]

	const renderCardOption = (index: number) =>
		showAnimatedIcons[index] && (
			<StyledStep2Animation
				key={index}
				title={t(`${userType}:intro:feature${index + 1}Title`)}
				subtitle={t(`${userType}:intro:feature${index + 1}Description`)}
				icon={<StyledContainerIconStep2>{createElement(iconComponents[index])}</StyledContainerIconStep2>}
				havePadding={false}
				withBorder={false}
				isInfo={index !== 2}
			/>
		)

	return (
		<>
			<StepContainer>
				<Title title={t(`${userType}:intro:title`)} />
				{[0, 1, 2].map(index => renderCardOption(index))}
			</StepContainer>

			<StepCta />
		</>
	)
}

export default StepInfo
