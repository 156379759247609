import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import React, {ChangeEvent, useState} from 'react'
import Title from '@components/ui/Title'
import {StepContainer, StyledButtonContainer} from '@pages/onBoarding/styles.ts'
import {useTranslation} from 'react-i18next'
import {StyledInputTextArea} from '@pages/symptoms/style.ts'
import {useNavigate} from 'react-router-dom'
import {PHASES, routes} from '@/utilities/constants'
import Footer from '@/components/ui/Footer'
import CommonModal from '@components/commons/Modal/index.tsx'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import NextButton from '@components/commons/ButtonTypes/NextButton.tsx'
import {CardSymptom, postSymptoms} from '@/services/patient.http'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const StepNotes: React.FC = () => {
	const {t} = useTranslation()
	const {setSymptomsData, step, setGeneralPatientData, patient, symptoms, notes} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const navigate = useNavigate()

	const [modal, setModal] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const handlePostSymptoms = async () => {
		const currentSymptoms = symptoms?.slice(0, 5).map(item => {
			const {symptom, pain, intensity, bodyFront, bodyBack, isLocalized} = item
			const currentZone = isLocalized ? null : bodyBack || bodyFront
			return {
				card: patient?.cardId ? +patient.cardId : null,
				symptom: symptom?.value ? +symptom.value : null,
				zone: currentZone ? currentZone : null,
				pain_intensity: intensity ? intensity : 1,
				pain_type: pain ? +pain : null,
				symptom_feedback: null,
				card_notes: notes || null
			}
		})

		const body: CardSymptom[] = currentSymptoms.length ? currentSymptoms : []
		setIsLoading(true)

		try {
			const {data} = await postSymptoms(body)
			if (data) {
				const newSymptoms = symptoms.map((symptom, index) => {
					return {...symptom, symptomId: data[index].id}
				})
				setSymptomsData('symptoms', newSymptoms)
				setCommonsData('isNextAnimation', true)
				navigate(`${routes.FINISHED.path}`)
				setCommonsData('userPhase', PHASES.finished)
			}
		} catch (error) {
			setModal(false)
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleConfirm = () => {
		handlePostSymptoms()
	}

	return (
		<>
			<StepContainer>
				<Title title={t(`patient:notes:title`)} subTitle={t(`patient:notes:subtitle`)} />

				<StyledInputTextArea
					type="textarea"
					placeholder={t(`commons:form:placeholder:add_notes`) || ''}
					onChange={(e: ChangeEvent<HTMLInputElement>) => setSymptomsData('notes', e.target.value)}
				/>
			</StepContainer>
			<CommonModal
				title={t('commons:confirm_next') || ''}
				modal={modal}
				setModal={setModal}
				centered
				confirm={t('commons:authorize') || ''}
				isLoading={isLoading}
				onConfirm={handleConfirm}
				cancel={t('commons:comeBack') || ''}
				onCancel={() => setModal(false)}
			/>
			<Footer>
				<StyledButtonContainer>
					<BackButton
						onClick={() => {
							setCommonsData('isNextAnimation', false)
							setGeneralPatientData('step', step - 1)
						}}
						text={'commons:back'}
					/>
					<NextButton onClick={() => setModal(true)} isLoading={isLoading} />
				</StyledButtonContainer>
			</Footer>
		</>
	)
}

export default StepNotes
