import {FC, ReactNode, useEffect, useState} from 'react'
import {
	StyledContainerQRIcon,
	StyledContainerRightItem,
	StyledContainerSubHeader,
	StyledNegativeProgress,
	StyledOverlay,
	StyledTooltip
} from '@components/commons/SubHeader/style.ts'
import {useBoundStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import {StyledButtonStart} from '@pages/onBoarding/styles.ts'
import Footer from '@components/ui/Footer'
import {HeartIcon, MedicalCrossIcon, UserIcon, XCloseIcon} from '@assets/icons/icons.tsx'
import QRContainer from '@components/commons/QRContainer/index.tsx'
import {QR_TYPES} from '@/utilities/constants'

interface SubHeaderProps {
	progressValue: number
	titleForSubHeader?: string
	icon?: ReactNode
}

const SubHeader: FC<SubHeaderProps> = ({titleForSubHeader, progressValue, icon}) => {
	const {t} = useTranslation()
	const {userType, showQRCode, setClinicalInfoData, step} = useBoundStore()
	const [isTooltipVisible, setTooltipVisible] = useState(false)

	const hasSeenTooltip = localStorage.getItem('hasSeenTooltip')

	useEffect(() => {
		if (step === 14 && !hasSeenTooltip) {
			const timeoutId = setTimeout(() => {
				setTooltipVisible(true)
			}, 300)

			return () => clearTimeout(timeoutId)
		} else {
			setTooltipVisible(false)
		}
	}, [step, hasSeenTooltip])

	const handleViewQRCode = () => {
		if (!icon) {
			return
		}
		setTimeout(() => {
			setClinicalInfoData('showQRCode', !showQRCode)
		}, 200)
	}

	const currentFlux = () => {
		if (progressValue >= 65 && progressValue <= 70) {
			return {
				icon: <MedicalCrossIcon />,
				label: t('commons:2of3'),
				title: t(`${userType}:clinical_information:sectionName`)
			}
		} else if (progressValue >= 75) {
			return {
				icon: <HeartIcon />,
				label: t('commons:3of3'),
				title: t(`${userType}:symptoms:sectionName`)
			}
		} else {
			return {
				icon: <UserIcon />,
				label: t('commons:1of3'),
				title: t(`${userType}:intro:feature1Title`)
			}
		}
	}

	const handleTooltipClose = () => {
		setTooltipVisible(false)
		localStorage.setItem('hasSeenTooltip', 'true')
	}

	return (
		<>
			<StyledOverlay open={showQRCode}>
				<QRContainer isColorful={false} codeType={QR_TYPES.white} canShowIt isEntireScreen />

				<Footer>
					<StyledButtonStart color="primary" outline={false} type="button" onClick={handleViewQRCode}>
						{t('commons:close')}
					</StyledButtonStart>
				</Footer>
			</StyledOverlay>

			<StyledContainerSubHeader
				blueWidth={progressValue}
				onClick={handleViewQRCode}
				className={'d-flex justify-content-between'}
			>
				<div className={'d-flex gap-2'}>
					{currentFlux().icon}
					<p>{titleForSubHeader || currentFlux().title}</p>
				</div>
				<StyledNegativeProgress />
				<StyledContainerRightItem>
					<p>{currentFlux().label}</p>
					{icon && <StyledContainerQRIcon>{icon}</StyledContainerQRIcon>}
				</StyledContainerRightItem>
			</StyledContainerSubHeader>

			{isTooltipVisible && step === 14 && (
				<StyledTooltip>
					<p>{t('commons:info:tooltip_clinical_info')}</p>
					<div>
						<XCloseIcon onClick={handleTooltipClose} />
					</div>
				</StyledTooltip>
			)}
		</>
	)
}

export default SubHeader
