import {FC} from 'react'
import {useCommonsStore} from '@store/boundStore.ts'
import {StyledAlert} from '@components/ui/Toast/style.ts'

interface ToastProps {
	status?: string
	text?: string
	isVisible?: boolean
}

const Toast: FC<ToastProps> = ({isVisible, status, text}) => {
	const {setCommonsData} = useCommonsStore()

	setTimeout(() => {
		setCommonsData('showToast', {
			isVisible: false
		})
	}, 3000)

	const onDismiss = () => {
		setCommonsData('showToast', {
			isVisible: false
		})
	}

	return (
		<StyledAlert
			isOpen={isVisible}
			color={status}
			className="d-flex justify-content-start align-items-center opacity-100"
			fade
			toggle={onDismiss}
		>
			<h5> {text}</h5>
		</StyledAlert>
	)
}

export default Toast
