import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import {FC, useEffect, useMemo} from 'react'
import Footer from '@components/ui/Footer'
import {StyledButtonContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'

interface CTAProps {
	onClick?: () => void
}

const StepClinicalInfoCta: FC<CTAProps> = ({onClick}) => {
	const {step, setGeneralPatientData} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()

	const isLastStep = step === 17

	const handleNext = () => {
		if (isLastStep && onClick) {
			onClick()
		} else {
			setGeneralPatientData('step', step + 1)
			setCommonsData('isNextAnimation', true)
		}
	}

	const handlePrev = () => {
		setGeneralPatientData('step', step - 1)
		setCommonsData('isNextAnimation', false)
	}

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault()
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [handleNext])

	const buttonToReturn = useMemo(() => {
		const commonButton = (
			<StyledButtonStart color="primary" outline={false} type="button" onClick={handleNext}>
				{t('commons:next')}
			</StyledButtonStart>
		)

		switch (step) {
			case 15:
			case 16:
			case 17:
				return (
					<StyledButtonContainer>
						<BackButton onClick={handlePrev} />
						{commonButton}
					</StyledButtonContainer>
				)
			default:
				return commonButton
		}
	}, [step])

	return (
		<>
			<Footer>{buttonToReturn}</Footer>
		</>
	)
}

export default StepClinicalInfoCta
