import {HeaderWrapper, StyledHeaderSpinner} from '@components/commons/Header/style.ts'
import {LogoIcon} from '@assets/icons/icons.tsx'
import Dropdown from '@components/ui/Dropdown'
import {FC, useEffect, useState} from 'react'
import Avatar from '@components/ui/Avatar'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {LANGUAGES, routes} from '@utilities/constants'
import {useCommonsStore} from '@/store/boundStore'
import {getCardValues} from '@services/commons.http.ts'
import {AxiosError, handleGenericError, languagesList} from '@utilities/helpers.ts'
import {Spinner} from 'reactstrap'

interface HeaderProps {
	haveAvatar?: boolean
}

const Header: FC<HeaderProps> = ({haveAvatar}) => {
	const {t, i18n} = useTranslation()
	const navigate = useNavigate()
	const {cardsData, setCommonsData} = useCommonsStore()
	const defaultLanguage = localStorage.getItem('i18nextLng')

	const [language, setLanguage] = useState<string>('it')
	const [isLoading, setIsLoading] = useState(false)

	const languages = cardsData?.settings?.languages ? languagesList(cardsData?.settings?.languages) : LANGUAGES

	const handleClick = () => {
		localStorage.clear()
		navigate(0)
	}

	const dropdownItemsAvatar = [
		{
			value: routes.ENTERED_INFORMATION.path,
			label: t('commons:info:entered_information')
		},
		{
			value: routes.CODE.path,
			label: t('commons:code:triage_code')
		}
	]

	useEffect(() => {
		if (defaultLanguage !== null) {
			setLanguage(defaultLanguage)
		}
	}, [])

	const handleCardValues = async (lang: string) => {
		setIsLoading(true)
		try {
			const {data} = await getCardValues(lang)
			setCommonsData('cardsData', data)
			setCommonsData('provinces', data.patient_data?.provinces)
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleChangeLanguage = (value: string) => {
		setLanguage(value)
		handleCardValues(value)
		i18n?.changeLanguage(value)
	}

	return (
		<HeaderWrapper className={'bg-primary text-white p-3'}>
			<div className={'d-flex justify-content-between align-items-center h-100'}>
				<LogoIcon onClick={handleClick} />

				<div className={'d-flex justify-content-end align-items-center h-100 gap-3'}>
					<Dropdown
						dropdownItems={languages}
						onClickItems={value => handleChangeLanguage(value)}
						stringToShow={language?.toUpperCase()}
					/>

					{haveAvatar && (
						<Dropdown
							dropdownItems={dropdownItemsAvatar}
							onClickItems={value => {
								setCommonsData('isNextAnimation', true)
								navigate(value)
							}}
						>
							<Avatar />
						</Dropdown>
					)}
				</div>
			</div>
			{isLoading && (
				<StyledHeaderSpinner>
					<Spinner />
				</StyledHeaderSpinner>
			)}
		</HeaderWrapper>
	)
}

export default Header
