import styled, {css, DefaultTheme} from 'styled-components'
import {ReactNode} from 'react'

interface Props {
	status: string
	theme: DefaultTheme
	icon?: ReactNode
}

interface StyledContainerDescriptionProps {
	theme: DefaultTheme
	icon?: ReactNode
}
export const StyledContainerAlert = styled.div`
	${({status, icon, theme: {palette, spacing}}: Props) => css`
		display: grid;
		grid-template-columns: ${icon ? ' 20px 1fr;' : '1fr'};
		gap: ${spacing + 6}px;
		padding: ${spacing * 2}px ${spacing * 4}px;
		border: 1px solid ${palette[status as keyof typeof palette][200]};
		background: ${palette[status as keyof typeof palette][25]};
		border-radius: ${spacing}px;

		& p {
			white-space: pre-wrap;
			word-break: break-word;
		}

		& path {
			fill: ${palette[status as keyof typeof palette]['600']};
		}
	`}
`

export const StyledContainerDescription = styled.div`
	${({theme, icon}: StyledContainerDescriptionProps) => css`
		${!icon &&
		css`
			& p {
				font-weight: 700;
				${theme.typography.textSm};
			}

			& span {
				${theme.typography.textSm};
			}
		`}
	`}
`
