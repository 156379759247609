import styled, {keyframes} from 'styled-components'

const strokeAnimation = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`

const scaleAnimation = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`

const fillAnimation = keyframes`
  100% {
    box-shadow: inset 0 0 0 30px #06c;
  }
`

export const StyledCheckmarkWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const StyledCheckmarkCircle = styled.circle`
	stroke-dasharray: 166;
	stroke-dashoffset: 166;
	stroke-width: 2;
	stroke-miterlimit: 10;
	stroke: #06c;
	fill: none;
	animation: ${strokeAnimation} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
`

export const StyledCheckmark = styled.svg`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: block;
	stroke-width: 2;
	stroke: #fff;
	stroke-miterlimit: 10;
	margin: 10% auto;
	box-shadow: inset 0 0 0 #06c;
	animation: ${fillAnimation} 0.4s ease-in-out 0.4s forwards, ${scaleAnimation} 0.3s ease-in-out 0.9s both;
`

export const StyledCheckmarkCheck = styled.path`
	transform-origin: 50% 50%;
	stroke-dasharray: 48;
	stroke-dashoffset: 48;
	animation: ${strokeAnimation} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
`
