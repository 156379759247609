import {ChangeEvent, FC} from 'react'
import {StyledLabel} from '@pages/onBoarding/styles.ts'

interface RadioProps {
	check?: boolean
	name?: string
	label?: string
	disabled?: boolean
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	className?: string
}

const RadioCustom: FC<RadioProps> = ({check, name, label, disabled = false, onChange, className}) => {
	return (
		<>
			<div className={`form-check ${className}`}>
				<input name={name} type="radio" id={name} onChange={onChange} checked={check} disabled={disabled} />
				<StyledLabel checked={check} htmlFor={name}>
					{label}
				</StyledLabel>
			</div>
		</>
	)
}

export default RadioCustom
