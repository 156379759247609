import React, {useEffect, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import Title from '@components/ui/Title'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import CardOption from '@components/ui/CardOption'
import {CameraIcon, TextInputIcon} from '@assets/icons/icons.tsx'
import StepCreateCF from './StepCreateCF.tsx'
import {Form} from 'reactstrap'
import InputText from 'src/components/ui/InputText'
import CodeScanner from '@components/commons/CodeScanner/index.tsx'
import {useTranslation} from 'react-i18next'
import {CfParseBody, postFiscalCodeParser} from '@services/patient.http.ts'
import dayjs from 'dayjs'
import Drawer from '@components/commons/Drawer/index.tsx'
import {AxiosError, IsItaly, handleGenericError} from '@utilities/helpers.ts'
import Spinner from '@components/ui/Spinner'
import CheckboxCustom from '@/components/ui/Checkbox/index.tsx'

const StepCF: React.FC = () => {
	const {setCommonsData} = useCommonsStore()
	const {step, patient, setPatientData, setGeneralPatientData, setPatientMultiData, userType, isInReview, subStep} =
		useBoundStore()
	const {cf, isResident, isCalculatedCF} = patient
	const {t} = useTranslation()

	const formRef = useRef<HTMLFormElement>(null)
	const [isLoading, setIsLoading] = useState(false)
	const [showError, setShowError] = useState(false)
	const [isCalcOpen, setIsCalcOpen] = useState(false)
	const [isScanOpen, setIsScanOpen] = useState(false)

	useEffect(() => {
		if (cf) {
			setShowError(false)
		}
	}, [cf])

	const cfRegex = /^[A-Za-z]{6}\d{2}[A-Za-z]\d{2}[A-Za-z]\d{3}[A-Za-z]$/

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()

		const isCFEmpty = isResident && cf?.trim() === ''

		if (isCFEmpty) {
			setShowError(true)
		} else {
			if (cf && cfRegex.test(cf)) {
				setShowError(false)
				handlePostCF()
				setPatientMultiData({
					...patient,
					isResident: true,
					isResidentDomicile: true,
					residenceIsDomicile: true,
					birthNation: undefined,
					birthNiCity: undefined,
					residenceNation: IsItaly,
					domicileNation: IsItaly,
					residenceNiAddress: '',
					residenceNiCity: undefined,
					residenceNiCap: undefined,
					domicileNiAddress: '',
					domicileNiCity: undefined,
					domicileNiCap: undefined
				})
			} else if (!isResident) {
				setShowError(false)
				if (isInReview) {
					if (subStep === 6) {
						setGeneralPatientData('subStep', 7)
					}
				} else {
					setCommonsData('isNextAnimation', true)
					setGeneralPatientData('step', step + 1)
				}
				setPatientMultiData({
					...patient,
					cf: undefined,
					isResident: false,
					isResidentDomicile: false,
					residenceIsDomicile: true,
					birthProvince: undefined,
					birthPlace: undefined,
					residenceAddress: '',
					residenceProvince: undefined,
					residenceCity: undefined,
					residenceCap: undefined,
					domicileAddress: '',
					domicileProvince: undefined,
					domicileCity: undefined,
					domicileCap: undefined,
					residenceNation: undefined,
					domicileNation: undefined
				})
			} else {
				setShowError(true)
			}
		}
	}

	const handlePostCF = async () => {
		const body: CfParseBody = {
			code: cf
		}
		setIsLoading(true)
		try {
			const {data} = await postFiscalCodeParser(body)
			if (data) {
				setPatientData('gender', data?.gender)
				setPatientData('birthDate', dayjs(data?.birthdate).format('YYYY-MM-DD'))
				setPatientData('birthProvince', {label: data?.city?.province?.name, value: data?.city?.province?.id})
				setPatientData('birthPlace', {
					label: data?.city?.name,
					value: data?.city?.id,
					data: data?.city?.istat_code
				})
				if (isInReview) {
					setGeneralPatientData('subStep', isCalculatedCF ? subStep + 2 : subStep + 1)
				} else {
					setCommonsData('isNextAnimation', true)
					setGeneralPatientData('step', isCalculatedCF ? step + 2 : step + 1)
				}
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, isCF: boolean) => {
		const value = e.target.value.toUpperCase().replace(/[^A-Z0-9]/g, '')
		setPatientData('isCalculatedCF', false)
		if (isCF) {
			value.length > 16 ? setPatientData('cf', value.slice(0, 16)) : setPatientData('cf', value)
		} else {
			setPatientData('cf', undefined)
		}
		setShowError(false)
	}

	return (
		<>
			{isLoading ? (
				<Spinner size={40} overlay />
			) : (
				<StepContainer notAnimate={isInReview}>
					<Title
						title={t(`${userType}:patientData:italianResidence:cf:title`)}
						subTitle={t('caregiver:patientData:italianResidence:cf:placeholder')}
					/>
					<Form innerRef={formRef} onSubmit={handleSubmit}>
						<InputText
							label={t('caregiver:patientData:italianResidence:cf:cf_code')}
							placeholder={t('caregiver:patientData:italianResidence:cf:cf_code') || ''}
							type="text"
							onChange={e => onChangeHandler(e, true)}
							value={cf}
							invalid={showError}
							message={t(cf ? 'errors:cf_error' : 'errors:cf_error_empty')}
							disabled={isResident === false}
						/>
						<CheckboxCustom
							check={isResident === false || false}
							onChange={() => {
								setShowError(false)
								setPatientData('cf', undefined)
								setPatientData('isResident', !isResident)
							}}
							name={'is-resident'}
							label={t(`${userType}:patientData:noCf`)}
						/>
						{isResident && (
							<div className={'d-grid gap-3 mt-5'}>
								<p className={'text-secondary'}>
									{t('caregiver:patientData:italianResidence:cf:cf_forgotten')}
								</p>

								<CardOption
									title={t(`${userType}:patientData:italianResidence:cf:calculate:title`)}
									subtitle={t(`${userType}:patientData:italianResidence:cf:calculate:subtitle`)}
									icon={<TextInputIcon />}
									onClick={() => setIsCalcOpen(true)}
								/>
								<CardOption
									title={t(`${userType}:patientData:italianResidence:cf:scan:title`)}
									subtitle={t(`${userType}:patientData:italianResidence:cf:scan:subtitle`)}
									icon={<CameraIcon />}
									onClick={() => {
										setIsScanOpen(true)
										setPatientData('isCalculatedCF', false)
									}}
								/>
							</div>
						)}
					</Form>
					<Drawer setIsOpen={setIsCalcOpen} isOpen={isCalcOpen}>
						<StepCreateCF setIsOpen={setIsCalcOpen} />
					</Drawer>

					<Drawer setIsOpen={setIsScanOpen} isOpen={isScanOpen}>
						<CodeScanner setIsOpen={setIsScanOpen} />
					</Drawer>
				</StepContainer>
			)}
			<StepCta formRef={formRef} isLoading={isLoading} />
		</>
	)
}

export default StepCF
