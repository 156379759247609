import {ClearIndicatorProps, components, DropdownIndicatorProps, GroupBase, MultiValueRemoveProps} from 'react-select'
import {SelectValue} from '@components/ui/Select/index.tsx'
import {ChevronDownIcon, XCloseIcon} from '@assets/icons/icons.tsx'

const MultiValueRemove = (props: MultiValueRemoveProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
	return (
		<components.MultiValueRemove {...props}>
			<XCloseIcon data-color size={12} />
		</components.MultiValueRemove>
	)
}

const DropdownIndicator = (props: DropdownIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
	return (
		<components.DropdownIndicator {...props}>
			<ChevronDownIcon size={16} />
		</components.DropdownIndicator>
	)
}

const ClearIndicator = (props: ClearIndicatorProps<SelectValue, boolean, GroupBase<SelectValue>>) => {
	return (
		<components.ClearIndicator {...props}>
			<XCloseIcon data-color size={16} />
		</components.ClearIndicator>
	)
}

export const customComponents = {
	MultiValueRemove,
	DropdownIndicator,
	ClearIndicator
}
