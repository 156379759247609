import Chip from '@components/ui/Chip/index.tsx'
import Alert from '@components/ui/Alert'
import {useTranslation} from 'react-i18next'
import {StyledContainerChips} from '@components/commons/SymptomsList/style.ts'
import {SelectValue} from '@components/ui/Select'

interface SymptomsListProps {
	list: SelectValue[]
	handleClose: (item: string) => void
}

const SymptomsList = ({list, handleClose}: SymptomsListProps) => {
	const {t} = useTranslation()

	if (list?.length === 0) {
		return (
			<Alert
				status={'secondary'}
				text={t('commons:info:no_symptoms_title')}
				subText={t('commons:info:no_symptoms_subtitle')}
				icon={null}
			/>
		)
	} else {
		return (
			<>
				<StyledContainerChips>
					{list?.map((item, index) => {
						return (
							<Chip
								key={index}
								text={item?.label}
								canClose
								handleClose={() => handleClose(item?.value)}
							/>
						)
					})}
				</StyledContainerChips>
				{list?.length >= 5 && <Alert status={'warning'} text={t('commons:info:max_title')} />}
			</>
		)
	}
}
export default SymptomsList
