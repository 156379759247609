import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer, StyledContainerCheckboxStep8, StyledLabel} from '../styles.ts'
import Title from '@components/ui/Title'
import Alert from '@components/ui/Alert'
import Radio from '@components/ui/Radio'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {Form} from 'reactstrap'
import InputDate from '@/components/ui/InputDate'
import {useTranslation} from 'react-i18next'
import Select, {SelectValue} from '@components/ui/Select'
import {
	AxiosError,
	handleGenericError,
	minDate,
	remapSelectOptions,
	seventeenYearsAgo,
	useCitiesList
} from '@utilities/helpers.ts'
import {getCities} from '@/services/commons.http.ts'
import InputText from '@components/ui/InputText'
import CommonModal from '@components/commons/Modal'
import {CfBody, postFiscalCodeGetter} from '@services/patient.http.ts'
import Spinner from '@components/ui/Spinner'

const StepRegistry: React.FC = () => {
	const {step, isInReview, patient, setPatientData, setGeneralPatientData, userType, subStep} = useBoundStore()
	const {firstName, lastName, isResident, gender, birthDate, birthProvince, birthPlace, birthNation, birthNiCity} =
		patient
	const {cardsData, provinces, cities, setCommonsData} = useCommonsStore()

	const {t} = useTranslation()

	const formRef = useRef<HTMLFormElement>(null)

	const memoizedData = useMemo(() => {
		const genderList = cardsData?.patient_data?.genders
		const countryCodes = cardsData?.patient_data?.countries

		return {
			genderList,
			countryCodes
		}
	}, [cardsData])

	const {genderList, countryCodes} = memoizedData

	const citiesList = useCitiesList(cities)
	const [invalidForm, setInvalidForm] = useState({
		firstName: false,
		lastName: false,
		birthNation: false,
		birthNiCity: false
	})

	const [fieldsThatChanged, setFieldsThatChanged] = useState({
		firstName: false,
		lastName: false,
		birthDate: false,
		gender: false,
		birthProvince: false,
		birthPlace: false,
		birthNiCity: false
	})

	const isFormChanged = Object.values(fieldsThatChanged).some(value => value)
	const [openModal, setOpenModal] = useState(false)
	const [newCf, setNewCf] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (birthProvince) {
			handleCities(birthProvince?.label)
		}
	}, [birthProvince])

	const handlePostCF = async () => {
		const body: CfBody = {
			first_name: firstName,
			last_name: lastName,
			gender: gender?.id,
			birth_place: birthPlace?.data || '',
			birth_date: birthDate
		}

		setIsLoading(true)
		try {
			const {data} = await postFiscalCodeGetter(body)
			const code = data?.code
			if (code) {
				setNewCf(code)
				setOpenModal(true)
				setIsLoading(false)
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const haveNotEmptyFields = isResident
		? firstName?.trim() !== '' && lastName?.trim() !== '' && gender && birthPlace && birthProvince && birthDate
		: firstName?.trim() !== '' &&
		  lastName?.trim() !== '' &&
		  gender &&
		  birthNation &&
		  birthNiCity?.trim() !== '' &&
		  birthDate

	const validForm =
		!invalidForm.firstName && !invalidForm.lastName && !invalidForm.birthNiCity && !invalidForm.birthNation
	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault()
		if (haveNotEmptyFields && validForm) {
			setCommonsData('showToast', {
				isVisible: false
			})
			if (isResident && isFormChanged) {
				await handlePostCF()
			} else {
				handleRoutes()
			}
		} else {
			setInvalidForm({
				firstName: firstName === '',
				lastName: lastName === '',
				birthNation: !birthNation,
				birthNiCity: birthNiCity === ''
			})
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:empty_field`) || '',
				status: 'danger'
			})
		}
	}

	const handleCities = async (province: string) => {
		try {
			const {data} = await getCities(province)
			setCommonsData('cities', data)
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
		const value = e.target.value.replace(/[^A-Za-z ]/gi, '')
		switch (type) {
			case 'firstName':
				if (firstName !== value) {
					setFieldsThatChanged({...fieldsThatChanged, firstName: true})
				}
				setPatientData('firstName', value)
				value.length > 0 && setInvalidForm({...invalidForm, firstName: false})
				break
			case 'lastName':
				if (lastName !== value) {
					setFieldsThatChanged({...fieldsThatChanged, lastName: true})
				}
				setPatientData('lastName', value)
				value.length > 0 && setInvalidForm({...invalidForm, lastName: false})
				break
			case 'birthDate':
				if (e.target.value !== birthDate) {
					setFieldsThatChanged({...fieldsThatChanged, birthDate: true})
				}
				setPatientData('birthDate', e.target.value)
				break
			case 'birthNiCity':
				setPatientData('birthNiCity', value)
				value.length > 0 && setInvalidForm({...invalidForm, birthNiCity: false})
				break
		}
	}

	const onConfirm = () => {
		setOpenModal(false)
		setPatientData('cf', newCf)
		handleRoutes()
	}

	useEffect(() => {
		window.onload = () => {
			if (isResident) {
				handlePostCF()
			}
		}
	}, [])

	const handleRoutes = () => {
		if (isInReview) {
			if (subStep === 6) {
				setGeneralPatientData('isInDrawer', false)
				setGeneralPatientData('step', 12)
			} else {
				setGeneralPatientData('subStep', 8)
			}
		} else {
			setCommonsData('isNextAnimation', true)
			setGeneralPatientData('step', step + 1)
		}
	}

	return isLoading ? (
		<Spinner size={40} overlay />
	) : (
		<>
			<CommonModal
				title={t(`${userType}:patientData:italianResidence:cf:new_cf`, {newCfValue: newCf}) || ''}
				modal={openModal}
				setModal={setOpenModal}
				isLoading={isLoading}
				centered
				confirm={t('commons:correct') || ''}
				onConfirm={onConfirm}
				cancel={t('commons:edit') || ''}
				onCancel={() => {
					if (isInReview) {
						setOpenModal(false)
					} else {
						setCommonsData('isNextAnimation', false)
						setGeneralPatientData('step', step - 1)
						setOpenModal(false)
					}
				}}
			>
				<p className={'fw-600'}>{t('commons:info:CF_changed')}</p>
			</CommonModal>

			<StepContainer notAnimate={isInReview && subStep !== 7}>
				<Title title={t(`${userType}:patientData:foreign_residence:personalData:title`)} />

				<Form innerRef={formRef} onSubmit={handleSubmit}>
					{isResident && <Alert text={t('commons:info:step_8')} />}

					<InputText
						label={t(`commons:form:name`)}
						placeholder={t(`commons:form:name`) || ''}
						type="text"
						onChange={e => {
							onChangeHandler(e, 'firstName')
						}}
						value={firstName}
						invalid={invalidForm.firstName}
						message={t(`errors:name_error`)}
					/>
					<InputText
						label={t(`commons:form:last_name`)}
						placeholder={t(`commons:form:last_name`) || ''}
						type="text"
						onChange={e => {
							onChangeHandler(e, 'lastName')
						}}
						value={lastName}
						invalid={invalidForm.lastName}
						message={t(`errors:last_name_error`)}
					/>

					<InputDate
						label={t(`commons:form:birth_date`)}
						placeholder={t(`commons:form:birth_date`) || ''}
						id="exampleDate"
						name="date"
						type="date"
						onChange={e => {
							onChangeHandler(e, 'birthDate')
						}}
						defaultValue={birthDate}
						min={minDate()}
						max={seventeenYearsAgo()}
					/>

					<div>
						<StyledLabel className={'ps-1'}>{t('commons:form:gender')}</StyledLabel>
						<StyledContainerCheckboxStep8>
							{genderList?.map(i => {
								return (
									<Radio
										key={i.id}
										label={i.name || ''}
										name={i.id.toString() || ''}
										onChange={e => {
											if (e.target.id !== gender?.id.toString()) {
												setFieldsThatChanged({...fieldsThatChanged, gender: true})
											}
											setPatientData('gender', {name: e.target.name, id: i.id})
										}}
										check={gender?.id === i.id}
									/>
								)
							})}
						</StyledContainerCheckboxStep8>
					</div>

					<div className={'d-grid gap-4'}>
						{isResident && (
							<>
								<Select
									options={remapSelectOptions(provinces)}
									label={t('commons:form:province_of_birth')}
									placeholder={t('commons:form:placeholder:province_of_birth')}
									onChange={newValue => {
										const value = newValue as SelectValue
										if (newValue !== birthProvince) {
											setFieldsThatChanged({...fieldsThatChanged, birthProvince: true})
										}
										setPatientData('birthPlace', undefined)
										setPatientData('birthProvince', newValue as SelectValue)
										if (value?.label) {
											handleCities(value.label)
										}
									}}
									value={birthProvince}
								/>
								<Select
									options={citiesList as SelectValue[]}
									label={t('commons:form:birthplace')}
									placeholder={t('commons:form:placeholder:birthplace')}
									onChange={newValue => {
										if (newValue !== birthPlace) {
											setFieldsThatChanged({...fieldsThatChanged, birthPlace: true})
										}
										setPatientData('birthPlace', newValue as SelectValue)
									}}
									value={birthPlace}
									disabled={!citiesList.length}
								/>
							</>
						)}
						{!isResident && (
							<>
								<Select
									options={remapSelectOptions(countryCodes)}
									label={t('commons:form:nation_of_birth')}
									placeholder={t('commons:form:placeholder:nation_of_birth')}
									onChange={newValue => {
										setPatientData('birthNation', newValue as SelectValue)
									}}
									value={birthNation}
									invalid={invalidForm.birthNation}
									errorMessage={t(`errors:mandatory_field`)}
								/>
								<InputText
									label={t('commons:form:birthplace')}
									placeholder={t('commons:form:placeholder:write_birthplace') || ''}
									type="text"
									onChange={e => {
										onChangeHandler(e, 'birthNiCity')
									}}
									value={birthNiCity}
									message={t(`errors:mandatory_field`)}
									invalid={invalidForm.birthNiCity}
								/>
							</>
						)}
					</div>
				</Form>
			</StepContainer>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepRegistry
