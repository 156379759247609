import {StyledContainerTextRecap, StyledLi, StyledRecapTitle} from '@pages/enteredInformation/styles.ts'
import {useTranslation} from 'react-i18next'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useMemo} from 'react'
import {handleTranslatedValue} from '@/utilities/helpers'

interface ClinicalInfoItem {
	title: string
	value: (string | {name: string})[]
}

const ClinicalInfo = () => {
	const {t} = useTranslation()
	const {userType, pathologies, medications, allergies, habits} = useBoundStore()
	const {cardsData} = useCommonsStore()

	const memoizedData = useMemo(() => {
		const pathologiesList = cardsData?.clinical_data?.pathologies
		const allergiesList = cardsData?.clinical_data?.allergies
		const habitsList = cardsData?.clinical_data?.habits

		return {
			pathologiesList,
			allergiesList,
			habitsList
		}
	}, [cardsData])

	const {pathologiesList, allergiesList, habitsList} = memoizedData
	const clinicalInfoArray: ClinicalInfoItem[] = [
		{
			title: t(`${userType}:clinical_information:pathologies:title`),
			value: pathologies?.map(pathology => handleTranslatedValue(pathologiesList, pathology)) || []
		},
		{
			title: t(`${userType}:clinicalInformation:drugs:title`),
			value: medications?.map(medication => ({name: medication?.value})) || []
		},
		{
			title: t(`${userType}:clinicalInformation:allergies:title`),
			value: allergies?.map(allergy => handleTranslatedValue(allergiesList, allergy)) || []
		},
		{
			title: t(`${userType}:clinicalInformation:habits:title`),
			value: habits?.map(habit => handleTranslatedValue(habitsList, habit)) || []
		}
	]

	return (
		<div className={'pt-4 d-flex flex-column gap-4'}>
			{clinicalInfoArray.map((item, index) => (
				<StyledContainerTextRecap key={index}>
					<StyledRecapTitle className={'text-secondary'}>{item?.title}</StyledRecapTitle>
					<ul>
						{item?.value?.length > 0 ? (
							item?.value?.map((value, index) => (
								<StyledLi key={index}>{typeof value === 'string' ? value : value.name}</StyledLi>
							))
						) : (
							<StyledLi>{t(`commons:no_option`)}</StyledLi>
						)}
					</ul>
				</StyledContainerTextRecap>
			))}
		</div>
	)
}

export default ClinicalInfo
