import {palette as mainPalette} from './palette.ts'
import mediaQueries from './mediaQueries.ts'
import ResetStyles from './reset.ts'
import {shadows} from './shadows.ts'
import {css, DefaultTheme} from 'styled-components'
import {typography} from './typography.ts'

import 'bootstrap-italia/dist/css/bootstrap-italia.min.css'
import 'typeface-titillium-web'
import 'typeface-roboto-mono'
import 'typeface-lora'

const GlobalStyles = ResetStyles
export const spacing = 4

interface ThemeInterface {
	name: 'light'
	direction?: 'ltr' | 'rtl'
}

const getAppTheme = ({name = 'light'}: ThemeInterface): DefaultTheme => {
	const palette = mainPalette[name]

	const zIndex = {
		modalOverlay: 10,
		modal: 11
	}

	const truncateText = css`
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	`

	const link = css`
		${typography.textSm};
		cursor: pointer;
		text-decoration: none;
		color: ${palette?.primary?.['700']};
		font-weight: 600;
		'&:hover': {
			color: ${palette?.neutral?.['900']};
			text-decoration: none;
		}
		,
        '&:focus': {
			color: ${palette?.neutral?.['900']};
		}
		,
        '&:visited': {
			color: ${palette?.neutral?.['500']};
		}
		,
        '&:active': {
			color: ${palette?.primary?.['700']};
		}
		,
        '&:link': {
			color: ${palette?.neutral?.['600']};
		}
	`

	return {
		spacing,
		zIndex,
		mediaQueries,
		palette,
		typography,
		shadows,
		truncateText,
		link
	}
}

export {GlobalStyles, getAppTheme}
