import {FC, useEffect, useState} from 'react'
import {CarouselItem, CarouselIndicators} from 'reactstrap'
import CarouselCard from '@components/commons/Carousel/CaroselCard'
import {StyledCarousel} from '@components/commons/Carousel/style.ts'

type objectType = {
	src: string
	title: string
	text: string
}

type CustomCarouselProps = {
	items?: objectType[]
}

const CustomCarousel: FC<CustomCarouselProps> = ({items = []}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const [animating, setAnimating] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			next()
		}, 1000)
	}, [])

	const next = () => {
		if (animating) return
		const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1
		setActiveIndex(nextIndex)
	}

	const previous = () => {
		if (animating) return
		const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1
		setActiveIndex(nextIndex)
	}

	const goToIndex = (newIndex: number) => {
		if (animating) return
		setActiveIndex(newIndex)
	}

	const slides = items.map(item => {
		return (
			<CarouselItem onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} key={item.src}>
				<CarouselCard img={item.src} cardTitle={item.title} cardText={item.text} />
			</CarouselItem>
		)
	})

	return (
		<StyledCarousel
			className={'it-carousel-wrapper it-carousel-landscape-abstract-three-cols'}
			activeIndex={activeIndex}
			next={next}
			previous={previous}
		>
			<CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
			{slides}
		</StyledCarousel>
	)
}

export default CustomCarousel
