import styled, {css} from 'styled-components'
import {Button} from 'reactstrap'

export const StyledButton = styled(Button)`
	${({color}) => css`
		${color === 'link' &&
		css`
			white-space: nowrap;
			padding: 8px 2px;
			font-weight: 600;
			width: fit-content;
			height: 48px;
		`}
	`}
`
