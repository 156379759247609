import {StepContainer} from '@pages/onBoarding/styles.ts'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import TabComponent from '@components/ui/Tabs'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import PatientData from '@pages/enteredInformation/TabsPage/PatientData.tsx'
import ClinicalInfo from '@pages/enteredInformation/TabsPage/ClinicalInfo.tsx'
import SymptomsInfo from '@pages/enteredInformation/TabsPage/Symptoms.tsx'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'

const EnteredInformation = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {userType} = useBoundStore()
	const {setCommonsData} = useCommonsStore()

	const items = [
		{
			id: 1,
			label: t(`${userType}:patientData:sectionName`),
			children: <PatientData />
		},
		{
			id: 2,
			label: t(`${userType}:clinical_information:title`),
			children: <ClinicalInfo />
		},
		{
			id: 3,
			label: t(`${userType}:intro:feature3Title`),
			children: <SymptomsInfo />
		}
	]

	return (
		<>
			<div className={'flex-column d-flex justify-content-start align-items-start p-3 pt-4 bg-white'}>
				<BackButton
					text={'commons:back'}
					onClick={() => {
						setCommonsData('isNextAnimation', false)
						navigate(-1)
					}}
					className={'bg-white'}
				/>
			</div>
			<StepContainer className={'h-100 rounded-0 border-0'}>
				<div className={'flex-column d-flex justify-content-start align-items-start pt-2'}>
					<h1>{t('commons:info:entered_information')}</h1>
				</div>
				<div className={'h-100'}>
					<TabComponent items={items} />
				</div>
			</StepContainer>
		</>
	)
}
export default EnteredInformation
