import {StyledContainerAlert, StyledContainerDescription} from '@components/ui/Alert/style.ts'
import {FC, ReactNode} from 'react'
import {InfoIcon} from '@assets/icons/icons.tsx'

interface AlertProps {
	status?: string
	icon?: ReactNode
	text?: string | null
	subText?: string | null
}
const Alert: FC<AlertProps> = ({status = 'primary', icon = <InfoIcon />, text, subText}) => {
	return (
		<>
			<StyledContainerAlert status={status} className={'w-100'} icon={icon}>
				{icon && <div className={'icon'}>{icon}</div>}
				<StyledContainerDescription icon={icon}>
					<p className={'text-secondary'}>{text}</p>
					{subText && <span className={'text-secondary'}>{subText}</span>}
				</StyledContainerDescription>
			</StyledContainerAlert>
		</>
	)
}

export default Alert

Alert.displayName = 'Alert'
