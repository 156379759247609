import {Alert} from 'reactstrap'
import styled, {css, DefaultTheme} from 'styled-components'

interface props {
	color: string
	theme: DefaultTheme
}
export const StyledAlert = styled(Alert)`
	${({theme: {palette, spacing}, color}: props) => css`
		border: none;
		border-top: ${spacing * 2}px solid ${palette[color as keyof typeof palette]['500']};
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
		border-radius: ${spacing}px;
		margin: 0;
		z-index: 4000;
		top: 30px;
		left: 20px;
		right: 20px;
		position: absolute;
		max-width: 96%;

		background-size: 24px !important;
		background-position: 18px 50% !important;
	`}
`
