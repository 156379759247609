import React, {useMemo} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import CardOption from '@/components/ui/CardOption'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {Form} from 'reactstrap'
import {CommonItem} from '@/types/commonType.ts'
import Spinner from '@components/ui/Spinner'

const StepTime: React.FC = () => {
	const {step, howLong, userType, isInReview, setGeneralPatientData} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()
	const {t} = useTranslation()

	const timing = useMemo(() => {
		return cardsData?.patient_data?.access?.timings
	}, [cardsData])

	const handleSubmit = (value: CommonItem) => {
		setGeneralPatientData('howLong', value)
		if (isInReview) {
			setGeneralPatientData('isInDrawer', false)
			setGeneralPatientData('step', 12)
		} else {
			setCommonsData('isNextAnimation', true)
			setGeneralPatientData('step', step + 1)
		}
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title title={t(`${userType}:patientData:time:title`)} />
				<Form>
					{timing?.length ? (
						timing?.map(i => {
							return (
								<CardOption
									title={i.name}
									onClick={() => handleSubmit(i)}
									isAnimated
									key={i.id}
									isSelected={howLong?.id === i.id}
								/>
							)
						})
					) : (
						<Spinner size={40} />
					)}
				</Form>
			</StepContainer>
			<StepCta />
		</>
	)
}

export default StepTime
