import {FC, useEffect, useMemo, useRef, useState} from 'react'
import StepSymptomsCta from '@pages/symptoms/steps/StepSymptomsCta.tsx'
import {SelectValue} from '@components/ui/Select'
import {StepContainer} from '@/pages/onBoarding/styles'
import Title from '@/components/ui/Title'
import IntensityProgress from '@/components/commons/IntensityProgress'
import {Form} from 'reactstrap'
import Radio from '@/components/ui/Radio'
import CheckboxCustom from '@/components/ui/Checkbox'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {GENDER_TYPES} from '@/utilities/constants'
import {useTranslation} from 'react-i18next'
import {BodyModel} from '@/assets/icons/icons'
import {BodyPosition, BodyZone, Outlines} from '@/types/commonType.ts'

interface StepSymptomProps {
	selectedSymptom: SelectValue
}

interface BodyData {
	width?: number
	height?: number
	outlines?: Outlines
	zones?: BodyZone[]
	front?: BodyPosition
	back?: BodyPosition
}

const StepSymptom: FC<StepSymptomProps> = ({selectedSymptom}) => {
	const {t} = useTranslation()
	const {userType, patient, symptoms, setSymptomsData, step, setGeneralPatientData} = useBoundStore()
	const {gender} = patient
	const {cardsData, setCommonsData} = useCommonsStore()

	const memoizedData = useMemo(() => {
		const painTypes = cardsData?.symptoms_data?.pain_types
		const maleBodyFront = cardsData?.symptoms_data?.body_zones?.male?.front
		const maleBodyBack = cardsData?.symptoms_data?.body_zones?.male?.back
		const femaleBodyFront = cardsData?.symptoms_data?.body_zones?.female?.front
		const femaleBodyBack = cardsData?.symptoms_data?.body_zones?.female?.back

		return {
			painTypes,
			maleBodyFront,
			maleBodyBack,
			femaleBodyFront,
			femaleBodyBack
		}
	}, [cardsData])

	const {painTypes, maleBodyFront, maleBodyBack, femaleBodyFront, femaleBodyBack} = memoizedData

	const formRef = useRef<HTMLFormElement>(null)
	const scrollRef = useRef<HTMLDivElement>(null)
	const bodyRef = useRef<HTMLDivElement>(null)

	const [intensity, setIntensity] = useState<number | undefined>(0)
	const [pain, setPain] = useState<string | undefined>(undefined)
	const [isLocalized, setIsLocalized] = useState<boolean | undefined>(false)
	const [selectedFront, setSelectedFront] = useState<number | undefined>(undefined)
	const [selectedBack, setSelectedBack] = useState<number | undefined>(undefined)
	const [isFront, setIsFront] = useState<boolean | undefined>(true)

	useEffect(() => {
		if (scrollRef.current) {
			const currentSymptom = symptoms.find(i => i.symptom === selectedSymptom)
			if (currentSymptom) {
				const {
					pain: storePain,
					isLocalized: storeisLocalized,
					bodyBack: storebodyBack,
					bodyFront: storebodyFront,
					intensity: storeintensity
				} = currentSymptom

				setPain(storePain)
				setIntensity(storeintensity)
				setSelectedBack(storebodyBack)
				setSelectedFront(storebodyFront)
				setIsLocalized(storeisLocalized)
				setIsFront(!storebodyBack)
			} else {
				setPain(undefined)
				setIntensity(undefined)
				setSelectedBack(undefined)
				setSelectedFront(undefined)
				setIsLocalized(undefined)
				setIsFront(true)
			}
			setTimeout(() => {
				if (scrollRef.current) {
					scrollRef.current.scrollIntoView({behavior: 'smooth'})
				}
			}, 600)
		}
	}, [step])

	const scrollToRef = () => {
		if (bodyRef.current) {
			setTimeout(() => {
				bodyRef.current && bodyRef.current.scrollIntoView({behavior: 'smooth'})
			}, 300)
		}
	}

	const renderZones = (zones: BodyZone[] | undefined) => {
		return zones?.map((zone: BodyZone, index: number) => {
			const fillColor = () => {
				if (isFront) {
					return selectedFront === zone.id ? '#BFDFFF' : '#F2F7FC'
				} else {
					return selectedBack === zone.id ? '#BFDFFF' : '#F2F7FC'
				}
			}

			const handlePosition = () => {
				if (isFront) {
					setSelectedBack(undefined)
					setSelectedFront(zone.id)
				} else {
					setSelectedFront(undefined)
					setSelectedBack(zone.id)
				}
			}

			return (
				<path
					key={`Z ${index}`}
					d={zone.svg.d?.toString()}
					fill={isLocalized ? '#F5F5F5' : fillColor()}
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					onClick={handlePosition}
				/>
			)
		})
	}

	const renderOutlines = (outlines: Outlines | undefined) => {
		return (
			<path
				d={outlines?.svg?.d?.toString()}
				fill={isLocalized ? '#D9DADB' : '#0066CC'}
				fillRule={'evenodd'}
				clipRule={'evenodd'}
			/>
		)
	}

	const handleSubmit = () => {
		if (!pain) {
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:at_least_one_pain`) || '',
				status: 'danger'
			})
		} else {
			const existingSymptom = symptoms.find(i => i.symptom === selectedSymptom)

			if (existingSymptom) {
				const updatedSymptoms = symptoms.map(symptom => {
					if (symptom.symptom === selectedSymptom) {
						return {
							...symptom,
							intensity: intensity,
							pain: pain,
							isLocalized: isLocalized,
							bodyFront: selectedFront,
							bodyBack: selectedBack
						}
					}
					return symptom
				})

				setSymptomsData('symptoms', updatedSymptoms)
				setGeneralPatientData('step', step + 1)
				setCommonsData('isNextAnimation', true)
			} else {
				setSymptomsData('symptoms', [
					...symptoms,
					{
						symptom: selectedSymptom || '',
						intensity: intensity || 1,
						pain: pain,
						isLocalized: isLocalized,
						bodyFront: selectedFront,
						bodyBack: selectedBack
					}
				])
				setGeneralPatientData('step', step + 1)
			}
		}
	}

	const renderBodyModel = (genderData: BodyData | undefined, isFront: boolean) => {
		const bodyData = isFront ? genderData : genderData

		return (
			<BodyModel
				width={bodyData?.width}
				height={bodyData?.height}
				renderZones={renderZones(bodyData?.zones)}
				renderOutlines={renderOutlines(bodyData?.outlines)}
			/>
		)
	}

	const renderBody =
		gender?.id === GENDER_TYPES.male
			? isFront
				? renderBodyModel(maleBodyFront, true)
				: renderBodyModel(maleBodyBack, false)
			: isFront
			? renderBodyModel(femaleBodyFront, true)
			: renderBodyModel(femaleBodyBack, false)

	return (
		<>
			<StepContainer>
				<div ref={scrollRef} className="position-absolute" />
				<Title title={selectedSymptom?.label} />
				<div>
					<h5 className={'fw-600'}>{t(`${userType}:symptoms:intensity`)}</h5>
					<span>{t(`${userType}:symptoms:symptom:intensitySubtitle`)}</span>
				</div>

				<Form innerRef={formRef} onSubmit={handleSubmit}>
					<IntensityProgress handleIntensity={e => setIntensity(e)} defaultValue={intensity} />
					<div className={'border-bottom'} />

					<div className={'mb-4 mt-3'}>
						<h5 className={'fw-600 '}>{t(`${userType}:symptoms:pain_type`)}</h5>
						<span>{t(`${userType}:symptoms:symptom:acheSubtitle`)}</span>
					</div>
					<div className={'d-flex flex-column gap-4'}>
						{painTypes?.map(i => {
							return (
								<Radio
									key={i.id}
									label={i.name}
									name={i.id.toString()}
									onChange={e => {
										setPain(e.target.name)
										scrollToRef()
									}}
									check={pain === i.id.toString()}
								/>
							)
						})}
					</div>

					<div className={'border-bottom'} ref={bodyRef} />

					<div className={'mb-4 mt-2'}>
						<h5 className={'fw-600'}>{t(`${userType}:symptoms:symptom:indicate`)}</h5>
						<span>{t(`${userType}:symptoms:symptom:indicateSubtitle`)}</span>
					</div>
					<CheckboxCustom
						check={isLocalized}
						onChange={() => {
							setIsLocalized(!isLocalized)
							setSelectedBack(undefined)
							setSelectedFront(undefined)
						}}
						name={'localized'}
						label={t(`${userType}:symptoms:check_pain:not_localized`)}
					/>
					<p
						className={
							isFront
								? 'text-decoration-underline fw-600 mt-4 text-primary'
								: 'text-decoration-underline fw-600 mt-4 text-muted'
						}
						onClick={() => setIsFront(!isFront)}
					>
						{t(`${userType}:symptoms:symptom:rotate`)}
					</p>
					<div className={'p-3'}>{renderBody}</div>
				</Form>
			</StepContainer>
			<StepSymptomsCta formRef={formRef} />
		</>
	)
}

export default StepSymptom
