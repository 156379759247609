import {StyledContainerTitle} from '@components/ui/Title/style.ts'
import {FC} from 'react'

interface TitleProps {
	label?: string | null
	title?: string | null
	subTitle?: string | null
	isCentered?: boolean
}
const Title: FC<TitleProps> = ({label, title, subTitle, isCentered = false}) => {
	return (
		<StyledContainerTitle className={'row'} isCentered={isCentered}>
			{label && <p>{label}</p>}
			{title && <h3>{title}</h3>}
			{subTitle && <span>{subTitle}</span>}
		</StyledContainerTitle>
	)
}

export default Title
