import {Carousel} from 'reactstrap'
import styled from 'styled-components'

export const StyledCarousel = styled(Carousel)`
	.carousel-indicators {
		gap: 16px;
	}

	.carousel-indicators [data-bs-target] {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		border: none;
		background: #94c4f5;
	}

	.carousel-indicators .active {
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: #0066cc;
	}
`
