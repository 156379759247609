import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import {FC, RefObject, useEffect, useMemo} from 'react'
import Footer from '@components/ui/Footer'
import {StyledButtonContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import NextButton from '@components/commons/ButtonTypes/NextButton.tsx'

const StepSymptomsCta: FC<{formRef?: RefObject<HTMLFormElement>}> = ({formRef}) => {
	const {step, setGeneralPatientData} = useBoundStore()
	const {setCommonsData} = useCommonsStore()
	const {t} = useTranslation()

	const handleOutsideButtonClick = () => {
		const formElement = formRef?.current
		formElement?.dispatchEvent(new Event('submit', {bubbles: true}))
	}

	const handleNext = () => {
		setGeneralPatientData('step', step + 1)
		setCommonsData('isNextAnimation', true)
	}

	const handlePrev = () => {
		setGeneralPatientData('step', step - 1)
		setCommonsData('isNextAnimation', false)
	}

	useEffect(() => {
		const handleKeyPress = (event: KeyboardEvent) => {
			if (event.key === 'Enter') {
				event.preventDefault()
				handleOutsideButtonClick()
			}
		}

		window.addEventListener('keydown', handleKeyPress)

		return () => {
			window.removeEventListener('keydown', handleKeyPress)
		}
	}, [handleOutsideButtonClick, formRef, handleNext])

	const buttonToReturn = useMemo(() => {
		if (step === 18 || step === 19) {
			return (
				<StyledButtonStart
					color="primary"
					outline={false}
					type="button"
					onClick={step === 18 ? handleNext : handleOutsideButtonClick}
				>
					{t('commons:next')}
				</StyledButtonStart>
			)
		} else {
			return (
				<StyledButtonContainer>
					<BackButton onClick={handlePrev} />

					<NextButton onClick={handleOutsideButtonClick} />
				</StyledButtonContainer>
			)
		}
	}, [step])

	return <Footer>{buttonToReturn}</Footer>
}

export default StepSymptomsCta
