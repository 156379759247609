import {StyledInputSearchContainer} from '@pages/symptoms/style.ts'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {SelectValue} from '@components/ui/Select'
import {StyledContainerOption, StyledContainerOptions} from '@components/commons/SymptomsList/style.ts'
import {CloseIcon} from '@assets/icons/icons.tsx'
import {useBoundStore} from '@store/boundStore.ts'

export type Option = {
	label: string
	value: string
	data: {
		related: {
			id: string
			name: string
		}[]
	}
}
interface SearchToSelectSymptomsProps {
	selectedOption: SelectValue[]
	options?: Option[]
}

const SearchToSelectSymptoms: React.FC<SearchToSelectSymptomsProps> = ({selectedOption, options = []}) => {
	const {t} = useTranslation()
	const [showOptions, setShowOptions] = useState<boolean>(false)
	const [inputValue, setInputValue] = useState<string>('')
	const [showClearIcon, setShowClearIcon] = useState<boolean>(false)
	const {setSymptomsData} = useBoundStore()

	const optionsFiltered = options
		? options.filter(
				option =>
					option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
					!selectedOption.some(selected => selected.value === option.value)
		  )
		: []

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newValue: string = e.target.value
		setInputValue(newValue)
		setShowOptions(newValue !== '')
		setShowClearIcon(newValue !== '')
	}

	const handleClearInput = () => {
		setInputValue('')
		setShowOptions(false)
		setShowClearIcon(false)
	}

	const handleOptionSelect = (option: SelectValue) => {
		setSymptomsData('selectedSymptoms', [...selectedOption, option])
		setShowOptions(false)
		setInputValue('')
		setShowClearIcon(false)
	}
	const relatedSymptoms = optionsFiltered
		.map(option => option?.data?.related)
		?.flat()
		.filter((v, i, a) => a.findIndex(t => t?.id === v?.id) === i)

	return (
		<StyledInputSearchContainer>
			<label>
				<input
					type="text"
					value={inputValue}
					onChange={handleSearch}
					placeholder={t(`commons:form:placeholder:symptoms`) || ''}
					disabled={selectedOption.length >= 5}
				/>
				{showClearIcon && <CloseIcon onClick={handleClearInput} />}
			</label>

			{showOptions && (
				<StyledContainerOptions>
					{optionsFiltered.length === 0 ? (
						<p>{t('commons:no_option')}</p>
					) : (
						<>
							{optionsFiltered.map((option, index) => (
								<StyledContainerOption
									key={index}
									onClick={() =>
										handleOptionSelect({
											label: option?.label,
											value: option?.value
										})
									}
								>
									<p>{option?.label}</p>
								</StyledContainerOption>
							))}

							{relatedSymptoms?.length > 0 && (
								<div>
									<StyledContainerOption>
										<span>{t('commons:related_symptoms')}</span>
									</StyledContainerOption>
									{relatedSymptoms.map((option, index) => (
										<StyledContainerOption
											key={index}
											onClick={() =>
												handleOptionSelect({
													label: option?.name,
													value: option?.id
												})
											}
										>
											<p>{option?.name}</p>
										</StyledContainerOption>
									))}
								</div>
							)}
						</>
					)}
				</StyledContainerOptions>
			)}
		</StyledInputSearchContainer>
	)
}

export default SearchToSelectSymptoms
