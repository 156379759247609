import {AvatarRoot} from '@components/ui/Avatar/style.ts'
import {AvatarIcon} from '@assets/icons/icons.tsx'

const Avatar = () => (
	<AvatarRoot>
		<AvatarIcon />
	</AvatarRoot>
)

export default Avatar

Avatar.displayName = 'Avatar'
