import {Outlet, useLocation} from 'react-router-dom'
import {StyledMain} from '../styles'
import React from 'react'
import Header from '@components/commons/Header'
import SubHeader from '@components/commons/SubHeader'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {QRIcon} from '@assets/icons/icons.tsx'
import {StyledHeaderDivider} from '@/components/commons/Header/style'
import {routes} from '@utilities/constants'
import Toast from '@components/ui/Toast'
import Landing from '@/pages/landing/Landing'

const PrivateLayout: React.FC = () => {
	const {pathname} = useLocation()
	const {step, showQRCode, selectedSymptoms, qrCode} = useBoundStore()
	const {
		showToast: {isVisible, text, status}
	} = useCommonsStore()

	const isCodeAssignedPath = qrCode && pathname?.includes(routes.CODEASSIGNED.path.replace(':id', qrCode))
	const codePath = pathname?.includes(routes.CODE.path)
	const enteredInformationPath = pathname?.includes(routes.ENTERED_INFORMATION.path)
	const isFeedbackPath = pathname?.includes(routes.FEEDBACK.path)
	const isStepTriageConcluded = pathname?.includes(routes.TRIAGE_CONCLUDED.path)
	const isLastStep = pathname?.includes(routes.FINISHED.path)

	const progressValueByStep = () => {
		if (!step) {
			return 0
		}

		if (step >= 20 && selectedSymptoms[step - 20]) {
			return 90
		}

		switch (true) {
			case step >= 13 && step <= 15:
				return 60 + (step - 13) * 5
			case step >= 16 && step <= 17:
				return 70
			case step >= 18 && step <= 19:
				return 80
			default:
				return 97
		}
	}

	const renderCommonHeader = () => (
		<>
			<Header />
			<StyledHeaderDivider />
			<SubHeader progressValue={progressValueByStep()} icon={<QRIcon />} />
		</>
	)

	const outletToRender = () => {
		switch (true) {
			case showQRCode:
				return renderCommonHeader()
			case step === 13:
			case step === 18:
			case isCodeAssignedPath:
			case isStepTriageConcluded:
			case isLastStep:
				return (
					<>
						<Header haveAvatar={isCodeAssignedPath || isStepTriageConcluded} />
						<StyledHeaderDivider />
						<Outlet />
					</>
				)
			case codePath:
			case enteredInformationPath:
			case isFeedbackPath:
				return <Outlet />
			default:
				return (
					<>
						{renderCommonHeader()}
						<Outlet />
					</>
				)
		}
	}

	return (
		<>
			<Landing />
			{isVisible && <Toast text={text} status={status} isVisible={isVisible} />}
			<StyledMain>{outletToRender()}</StyledMain>
		</>
	)
}

export default PrivateLayout
