import {FC, ReactNode, useState} from 'react'
import {Dropdown, DropdownMenu} from 'reactstrap'
import {StyledDropdownItem, StyledDropdownToggle} from '@components/ui/Dropdown/style.ts'

type DropdownItem = {
	value: string
	label: string
}

interface DropdownItemProps {
	dropdownItems: DropdownItem[]
	onClickItems: (value: string) => void
	stringToShow?: string | null
	children?: ReactNode
	className?: string
}

const DropdownCustom: FC<DropdownItemProps> = ({dropdownItems, stringToShow, children, onClickItems, className}) => {
	const [dropdownOpen, setDropdownOpen] = useState(false)
	const toggle = () => setDropdownOpen(prevState => !prevState)

	return (
		<div>
			<Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
				<StyledDropdownToggle
					caret
					className={className ? className : 'text-decoration-underline bg-primary p-0 text-white'}
				>
					{children ? children : <span className={'fw-bold '}>{stringToShow}</span>}
				</StyledDropdownToggle>
				<DropdownMenu>
					{dropdownItems?.map((item, index) => {
						return (
							<StyledDropdownItem
								className={'text-primary'}
								key={index}
								onClick={() => onClickItems(item.value)}
							>
								{item.label}
							</StyledDropdownItem>
						)
					})}
				</DropdownMenu>
			</Dropdown>
		</div>
	)
}

export default DropdownCustom
