import {usePatientDataArray} from '@hooks/usePatientDataArray.tsx'
import {StyledContainerTextRecap, StyledRecapTitle, StyledSpan} from '@pages/enteredInformation/styles.ts'

const PatientData = () => {
	const reviewData = usePatientDataArray()

	return (
		<div className={'pt-4 pb-4 d-flex flex-column gap-4'}>
			{reviewData.map((item, index) => (
				<StyledContainerTextRecap key={index}>
					<StyledRecapTitle className={'text-secondary'}>{item?.title}</StyledRecapTitle>
					<StyledSpan>{item?.value}</StyledSpan>
				</StyledContainerTextRecap>
			))}
		</div>
	)
}

export default PatientData
