import React from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import CardOption from '@/components/ui/CardOption'
import Title from '@components/ui/Title'
import {Form} from 'reactstrap'
import {USER_TYPES} from '@utilities/constants'
import {useTranslation} from 'react-i18next'
import {MeIcon, UsersIcon} from '@assets/icons/icons.tsx'

const StepAssistance: React.FC = () => {
	const {step, userType, setGeneralPatientData} = useBoundStore()
	const {setCommonsData} = useCommonsStore()

	const {t} = useTranslation()
	const handleSubmit = (value: string) => {
		setGeneralPatientData('userType', value)
		setCommonsData('isNextAnimation', true)
		setGeneralPatientData('step', step + 1)
	}

	return (
		<>
			<StepContainer>
				<Title title={t('commons:step_1:title')} />
				<Form>
					<CardOption
						title={t('commons:step_1:title_patient')}
						subtitle={t('commons:step_1:subtitle_patient')}
						onClick={() => handleSubmit(USER_TYPES.patient)}
						isAnimated
						icon={<MeIcon />}
						isSelected={userType === USER_TYPES.patient}
					/>
					<CardOption
						title={t('commons:step_1:title_caregiver')}
						subtitle={t('commons:step_1:subtitle_caregiver')}
						onClick={() => handleSubmit(USER_TYPES.caregiver)}
						isAnimated
						icon={<UsersIcon />}
						isSelected={userType === USER_TYPES.caregiver}
					/>
				</Form>
			</StepContainer>
		</>
	)
}

export default StepAssistance
