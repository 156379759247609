import React, {FC, useEffect, useMemo, useState} from 'react'
import {StyledContainerIconStep2} from '@pages/onBoarding/styles.ts'
import {CheckedIcon, HeartIcon, MedicalCrossIcon, UserIcon} from '@assets/icons/icons.tsx'
import AnimatedDivider from '@components/commons/IconsStepsStatus/components/AnimatedDivider.tsx'
import AnimatedCheckmark from '@components/commons/IconsStepsStatus/components/AnimatedCheckmark.tsx'

interface IconsStepsStatusProps {
	step: number
}

const IconsStepsStatus: FC<IconsStepsStatusProps> = ({step}) => {
	const [showAnimatedIcons, setShowAnimatedIcons] = useState([false, false, false])
	const [progressValue, setProgressValue] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setProgressValue(prevState => {
				if (prevState === 100) {
					clearInterval(interval)
				}
				return prevState + 1
			})
		}, 1)

		return () => clearInterval(interval)
	}, [])

	const icons = useMemo(() => {
		switch (step) {
			case 1:
				setShowAnimatedIcons([true, false, false])
				return [CheckedIcon, HeartIcon, MedicalCrossIcon]
			case 2:
				setShowAnimatedIcons([false, true, false])
				return [CheckedIcon, CheckedIcon, MedicalCrossIcon]
			case 3:
				setShowAnimatedIcons([false, false, true])
				return [CheckedIcon, CheckedIcon, CheckedIcon]
			default:
				setShowAnimatedIcons([false, false, false])
				return [UserIcon, HeartIcon, MedicalCrossIcon]
		}
	}, [step])

	return (
		<div className="d-flex gap-1 align-items-center">
			{icons.map((Icon, index) => (
				<React.Fragment key={index}>
					{showAnimatedIcons[index] ? (
						<AnimatedCheckmark />
					) : (
						<StyledContainerIconStep2>
							<Icon />
						</StyledContainerIconStep2>
					)}
					{index < icons.length - 1 && (
						<AnimatedDivider progressValue={step > index + 1 ? progressValue : 0} />
					)}
				</React.Fragment>
			))}
		</div>
	)
}

export default IconsStepsStatus
