import styled, {css} from 'styled-components'
import triage_concluded_background from '../../assets/images/triage_concluded.png'

export const StyledHeaderBackground = styled.div`
	background: url(${triage_concluded_background}) no-repeat center;
	background-size: cover;
	height: 132px;
`

export const StyledContainerInfo = styled.div`
	${({theme}) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		text-align: center;
		padding: ${theme.spacing * 12}px ${theme.spacing * 4}px;
	`}
`
