import React, {useMemo, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '@pages/onBoarding/styles.ts'
import {Form} from 'reactstrap'
import Title from '@components/ui/Title'
import Toggle from '@components/ui/Toggle'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import InputText from '@components/ui/InputText'
import {useTranslation} from 'react-i18next'
import Select, {SelectValue} from '@/components/ui/Select'
import CapInput from '@components/commons/CapInput'
import {AxiosError, IsItaly, handleGenericError, remapSelectOptions, useCitiesList} from '@utilities/helpers.ts'
import {getCities} from '@/services/commons.http'
import {CITY_TYPES} from '@/utilities/constants'
import {Patient} from '@/types/patientType.ts'

const StepResidence: React.FC = () => {
	const {t} = useTranslation()
	const {step, patient, setPatientData, setGeneralPatientData, setPatientMultiData, isInReview, userType, subStep} =
		useBoundStore()
	const {
		isCalculatedCF,
		isResident,
		isResidentDomicile,
		residenceAddress,
		residenceNiAddress,
		residenceProvince,
		residenceCity,
		domicileAddress,
		domicileProvince,
		domicileCity,
		residenceIsDomicile,
		residenceCap,
		domicileCap,
		residenceNation,
		residenceNiCity,
		residenceNiCap,
		domicileNation,
		domicileNiAddress,
		domicileNiCity,
		domicileNiCap
	} = patient

	const {cardsData, provinces, residenceCities, setCommonsData, domicileCities} = useCommonsStore()

	const formRef = useRef<HTMLFormElement>(null)

	const [invalidForm, setInvalidForm] = useState({
		residenceAddress: false,
		residenceProvince: false,
		residenceCity: false,
		residenceCap: false,
		domicileAddress: false,
		domicileProvince: false,
		domicileCity: false,
		domicileCap: false
	})

	const citiesList = useCitiesList(residenceCities)
	const domicileCitiesList = useCitiesList(domicileCities)

	const countryCodes = useMemo(() => {
		return cardsData?.patient_data?.countries
	}, [cardsData])

	const isResidenceValid = residenceAddress?.trim() !== '' && residenceProvince && residenceCity && residenceCap
	const isDomicileValid = domicileAddress?.trim() !== '' && domicileProvince && domicileCity && domicileCap
	const isFormValid = residenceIsDomicile ? isResidenceValid : isResidenceValid && isDomicileValid

	const isResidenceNiValid = residenceNiAddress?.trim() !== '' && residenceNiCity && residenceNiCap
	const isDomicileNiValid = domicileNiAddress?.trim() !== '' && domicileNiCity && domicileNiCap
	const isNiFormValid = residenceIsDomicile ? isResidenceNiValid : isResidenceNiValid && isDomicileNiValid

	const handleCities = async (province: string, type: string) => {
		try {
			const {data} = await getCities(province)
			if (type === CITY_TYPES.residence) {
				setCommonsData('residenceCities', data)
			} else {
				setCommonsData('domicileCities', data)
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()

		if (isResident ? isFormValid : isNiFormValid) {
			if (isInReview) {
				setGeneralPatientData('isInDrawer', false)
				setGeneralPatientData('step', 12)
			} else {
				setCommonsData('isNextAnimation', true)
				setGeneralPatientData('step', step + 1)
			}
			setCommonsData('showToast', {
				isVisible: false
			})
		} else {
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:empty_field`) || '',
				status: 'danger'
			})
			setInvalidForm({
				residenceAddress: residenceAddress?.trim() === '',
				residenceProvince: residenceProvince === undefined,
				residenceCity: residenceCity === undefined,
				residenceCap: residenceCap === undefined,
				domicileAddress: residenceIsDomicile ? false : domicileAddress?.trim() === '',
				domicileProvince: residenceIsDomicile ? false : domicileProvince === undefined,
				domicileCity: residenceIsDomicile ? false : domicileCity === undefined,
				domicileCap: residenceIsDomicile ? false : domicileCap === undefined
			})
		}
	}

	const onPrevHandle = () => {
		if (isCalculatedCF) {
			setGeneralPatientData('step', step - 2)
		} else {
			setGeneralPatientData('step', step - 1)
		}
		setCommonsData('isNextAnimation', false)
	}

	const handleInputChange = (value: string, inputType: keyof Patient) => {
		setPatientData(inputType, value)
		setInvalidForm({...invalidForm, [inputType]: false})
	}

	const handleSelectChange = (newValue: SelectValue[] | SelectValue, inputType: keyof Patient) => {
		setPatientData(inputType, newValue as SelectValue)
		setInvalidForm({...invalidForm, [inputType]: false})
	}

	const countries = remapSelectOptions(countryCodes)

	return (
		<>
			<StepContainer notAnimate={isInReview && subStep !== 8}>
				<Title title={t(`${userType}:patientData:italianResidence:residenceDomicile:title`)} />
				<Form innerRef={formRef} onSubmit={handleSubmit} className={'h-100'}>
					<Select
						options={countries}
						label={t('commons:form:nation_of_residence')}
						placeholder={t('commons:form:placeholder:nation_of_residence')}
						onChange={newValue => {
							const value = newValue as SelectValue
							if (value?.value) {
								if (value.value === IsItaly.value) {
									setPatientMultiData({
										...patient,
										residenceNation: value,
										residenceNiAddress: '',
										residenceNiCity: undefined,
										residenceNiCap: undefined,
										isResident: true
									})
								} else {
									setPatientMultiData({
										...patient,
										residenceNation: value,
										residenceAddress: '',
										residenceProvince: undefined,
										residenceCity: undefined,
										residenceCap: undefined,
										cf: undefined,
										isResident: false
									})
								}
							}
						}}
						value={residenceNation}
					/>
					{isResident && (
						<div className={'d-flex flex-column gap-3'}>
							<InputText
								label={t('commons:form:address')}
								placeholder={t('commons:form:address') || ''}
								type="text"
								onChange={e => {
									handleInputChange(e.target.value, 'residenceAddress')
								}}
								value={residenceAddress}
								invalid={invalidForm.residenceAddress}
								message={t(`errors:mandatory_field`)}
							/>

							<Select
								options={remapSelectOptions(provinces)}
								label={t('commons:form:province_of_residence')}
								placeholder={t('commons:form:placeholder:province_of_residence')}
								onChange={newValue => {
									const value = newValue as SelectValue
									setPatientData('residenceCity', undefined)
									setPatientData('residenceCap', undefined)
									handleSelectChange(newValue as SelectValue, 'residenceProvince')
									if (value?.label) {
										handleCities(value.label, CITY_TYPES.residence)
									}
								}}
								value={residenceProvince}
								invalid={invalidForm.residenceProvince}
								errorMessage={t(`errors:mandatory_field`)}
							/>
							<CapInput
								setSelectValue={(newValue: SelectValue) => {
									setInvalidForm({...invalidForm, residenceCity: false})
									setPatientData('residenceCity', newValue)
									setPatientData('residenceCap', undefined)
								}}
								selectValue={residenceCity}
								selectOptions={citiesList}
								setInputValue={newValue => {
									handleSelectChange(newValue as SelectValue, 'residenceCap')
								}}
								capNumber={residenceCap || undefined}
								selectLabel={t('commons:form:city_of_residence')}
								selectPlaceholder={t('commons:form:placeholder:city_of_residence')}
								cities={residenceCities}
								invalidCity={invalidForm.residenceCity}
								invalidCap={invalidForm.residenceCap}
								errorMessage={t(`errors:mandatory_field`)}
							/>
						</div>
					)}
					{!isResident && (
						<div className={'d-flex flex-column gap-3'}>
							<InputText
								label={t('commons:form:address')}
								placeholder={t('commons:form:address') || ''}
								type="text"
								onChange={e => {
									handleInputChange(e.target.value, 'residenceNiAddress')
								}}
								value={residenceNiAddress}
								message={t(`errors:mandatory_field`)}
							/>
							<div className={'row'}>
								<div className={'col-8'}>
									<InputText
										label={t('commons:form:city_of_residence')}
										placeholder={t('commons:form:placeholder:city_of_residence') || ''}
										type="text"
										onChange={e => {
											setPatientData('residenceNiCity', e.target.value)
										}}
										value={residenceNiCity}
										message={t(`errors:last_name_error`)}
									/>
								</div>
								<div className={'col-4'}>
									<InputText
										label={t('commons:form:postal_code_foreign')}
										placeholder={t('commons:form:postal_code_foreign') || ''}
										type="number"
										onChange={e => {
											setPatientData('residenceNiCap', e.target.value)
										}}
										value={residenceNiCap}
										message={t(`errors:last_name_error`)}
									/>
								</div>
							</div>
						</div>
					)}
					<Toggle
						check={residenceIsDomicile}
						onChange={() => {
							setPatientMultiData({
								...patient,
								residenceIsDomicile: !residenceIsDomicile,
								domicileAddress: '',
								domicileProvince: undefined,
								domicileCity: undefined,
								domicileCap: undefined,
								domicileNiAddress: '',
								domicileNiCity: undefined,
								domicileNiCap: undefined
							})
						}}
						label={t('commons:info:domicile_corresponds_residence') || ''}
					/>
					{!residenceIsDomicile && (
						<>
							<Select
								options={countries}
								label={t('commons:form:nation_of_domicile')}
								placeholder={t('commons:form:placeholder:nation_of_residence')}
								onChange={newValue => {
									const value = newValue as SelectValue
									if (value?.value) {
										if (value.value === IsItaly.value) {
											setPatientMultiData({
												...patient,
												domicileNation: value,
												domicileNiAddress: '',
												domicileNiCity: undefined,
												domicileNiCap: undefined,
												isResidentDomicile: true
											})
										} else {
											setPatientMultiData({
												...patient,
												domicileNation: value,
												domicileAddress: '',
												domicileProvince: undefined,
												domicileCity: undefined,
												domicileCap: undefined,
												isResidentDomicile: false
											})
										}
									}
								}}
								value={domicileNation}
							/>
							{isResidentDomicile && (
								<div className={'d-flex flex-column gap-3'}>
									<InputText
										label={t('commons:form:address')}
										placeholder={t('commons:form:address') || ''}
										type="text"
										onChange={e => {
											handleInputChange(e.target.value, 'domicileAddress')
										}}
										value={domicileAddress}
										invalid={invalidForm.domicileAddress}
										message={t(`errors:mandatory_field`)}
									/>
									<Select
										options={remapSelectOptions(provinces)}
										label={t('commons:form:province_of_domicile')}
										placeholder={t('commons:form:placeholder:province_of_residence')}
										onChange={newValue => {
											const value = newValue as SelectValue
											setPatientData('domicileCity', undefined)
											handleSelectChange(newValue as SelectValue, 'domicileProvince')
											setPatientData('domicileCap', undefined)
											if (value?.label) {
												handleCities(value.label, CITY_TYPES.domicile)
											}
										}}
										value={domicileProvince}
										invalid={invalidForm.domicileProvince}
										errorMessage={t(`errors:mandatory_field`)}
									/>
									<CapInput
										setSelectValue={(newValue: SelectValue) => {
											setPatientData('domicileCity', newValue)
											setPatientData('domicileCap', undefined)
										}}
										selectValue={domicileCity}
										selectOptions={domicileCitiesList}
										setInputValue={newValue => {
											handleSelectChange(newValue as SelectValue, 'domicileCap')
										}}
										capNumber={domicileCap || undefined}
										selectLabel={t('commons:form:place_of_domicile')}
										selectPlaceholder={t('commons:form:placeholder:city_of_residence')}
										cities={domicileCities}
										invalidCap={invalidForm.domicileCap}
										invalidCity={invalidForm.domicileCity}
										errorMessage={t(`errors:mandatory_field`)}
									/>
								</div>
							)}
							{!isResidentDomicile && (
								<div className={'d-flex flex-column gap-3'}>
									<InputText
										label={t('commons:form:address')}
										placeholder={t('commons:form:address') || ''}
										type="text"
										onChange={e => {
											handleInputChange(e.target.value, 'domicileNiAddress')
										}}
										value={domicileNiAddress}
										message={t(`errors:mandatory_field`)}
									/>
									<div className={'row'}>
										<div className={'col-8'}>
											<InputText
												label={t('commons:form:city_of_residence')}
												placeholder={t('commons:form:placeholder:city_of_residence') || ''}
												type="text"
												onChange={e => {
													setPatientData('domicileNiCity', e.target.value)
												}}
												value={domicileNiCity}
												message={t(`errors:last_name_error`)}
											/>
										</div>
										<div className={'col-4'}>
											<InputText
												label={t('commons:form:postal_code_foreign')}
												placeholder={t('commons:form:postal_code_foreign') || ''}
												type="number"
												onChange={e => {
													setPatientData('domicileNiCap', e.target.value)
												}}
												value={domicileNiCap}
												message={t(`errors:last_name_error`)}
											/>
										</div>
									</div>
								</div>
							)}
						</>
					)}
				</Form>
			</StepContainer>
			<StepCta formRef={formRef} onPrevHandle={onPrevHandle} />
		</>
	)
}

export default StepResidence
