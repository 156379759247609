import React, {useLayoutEffect, useRef, useState} from 'react'
import Quagga from '@ericblade/quagga2'
import {StepContainer, StyledButtonContainer} from '@pages/onBoarding/styles.ts'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {StyledFooter} from '@components/ui/Footer/style.ts'
import {
	StyledContainerCorrectBanner,
	StyledContainerResult,
	StyledScannerContainer
} from '@components/commons/CodeScanner/style.ts'
import {CfParseBody, postFiscalCodeParser} from '@services/patient.http.ts'
import dayjs from 'dayjs'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import NextButton from '@components/commons/ButtonTypes/NextButton.tsx'
import Alert from '@components/ui/Alert'
import {CheckCircleIcon} from '@assets/icons/icons.tsx'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

interface Props {
	setIsOpen: (e: boolean) => void
}

const CodeScanner: React.FC<Props> = ({setIsOpen}) => {
	const {t} = useTranslation()
	const resultRef = useRef(null)
	const {setCommonsData} = useCommonsStore()
	const {subStep, userType, setPatientData, isInReview, setGeneralPatientData} = useBoundStore()

	const [isLoading, setIsLoading] = useState(false)
	const [result, setResult] = useState<string>('')

	useLayoutEffect(() => {
		if (resultRef.current != null) {
			Quagga.init(
				{
					inputStream: {
						name: 'Live',
						type: 'LiveStream',
						target: resultRef.current,
						willReadFrequently: true,
						constraints: {
							width: 800,
							height: 800,
							facingMode: 'environment'
						}
					},
					locator: {
						patchSize: 'medium',
						halfSample: true
					},
					numOfWorkers: navigator.hardwareConcurrency || 1,
					decoder: {
						readers: ['code_39_reader'],
						debug: {
							drawBoundingBox: false,
							showFrequency: false,
							drawScanline: false,
							showPattern: false
						},
						multiple: false
					},
					locate: true
				},
				err => {
					if (err) {
						console.error(err)
						return
					}

					Quagga.start()
				}
			)

			Quagga.onProcessed(result => {
				const drawingCtx = Quagga.canvas.ctx.overlay
				const drawingCanvas = Quagga.canvas.dom.overlay

				if (result) {
					if (result.boxes) {
						drawingCtx.clearRect(
							0,
							0,
							parseInt(drawingCanvas.getAttribute('width') || '0', 10),
							parseInt(drawingCanvas.getAttribute('height') || '0', 10)
						)
						result.boxes
							.filter(box => box !== result.box)
							.forEach(box => {
								Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {
									color: 'green',
									lineWidth: 2
								})
							})
					}

					if (result.box) {
						Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {
							color: '#00F',
							lineWidth: 2
						})
					}

					if (result.codeResult && result.codeResult.code) {
						const scannedCode = result.codeResult.code
						setResult(scannedCode)
					}
				}
			})

			Quagga.onDetected(result => {
				Quagga.stop()
				if (result.codeResult.code) {
					setResult(result.codeResult.code)
				}
			})
		}
		return () => {
			if (Quagga) {
				Quagga.stop()
			}
		}
	}, [])

	const destroy = () => {
		Quagga.stop()
		Quagga.offProcessed()
		Quagga.offDetected()
		if (isInReview) {
			if (subStep === 6) {
				setGeneralPatientData('isInDrawer', false)
				setGeneralPatientData('step', 12)
			} else {
				setIsOpen(false)
				setGeneralPatientData('subStep', 6)
			}
		} else {
			setIsOpen(false)
		}
	}

	const handlePostCF = async () => {
		const body: CfParseBody = {
			code: result
		}
		setIsLoading(true)
		try {
			const {data} = await postFiscalCodeParser(body)
			if (data) {
				setPatientData('cf', data?.code)
				setPatientData('gender', data?.gender)
				setPatientData('birthDate', dayjs(data?.birthdate).format('YYYY-MM-DD'))
				setPatientData('birthProvince', {label: data?.city?.province?.name, value: data?.city?.province?.id})
				setPatientData('birthPlace', {
					label: data?.city?.name,
					value: data?.city?.id,
					data: data?.city?.istat_code
				})
				destroy()
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const resetQuagga = () => {
		Quagga.stop()
		setIsOpen(false)
		setResult('')

		setTimeout(() => {
			setIsOpen(true)
			Quagga.start()
		}, 500)
	}

	return (
		<>
			<StepContainer notAnimate>
				{result === '' ? (
					<>
						<Title
							title={t(`${userType}:patientData:italianResidence:cf:scan:title_1`)}
							subTitle={t(`${userType}:patientData:italianResidence:cf:scan:subtitle_1`)}
						/>
						<p>{t(`${userType}:patientData:italianResidence:cf:scan:paragraph`)}</p>
						<StyledScannerContainer ref={resultRef} />
						<Alert text={t(`${userType}:patientData:italianResidence:cf:scan:info`)} />
					</>
				) : (
					<>
						<StyledContainerCorrectBanner>
							<div className={'d-flex flex-column justify-content-center align-items-center'}>
								<CheckCircleIcon />
								<h4>{t(`patient:patientData:italianResidence:cf:detected_cf:title`)}</h4>
								<p>{t(`patient:patientData:italianResidence:cf:detected_cf:subtitle`)}</p>
							</div>

							<StyledContainerResult>
								<h1>{result}</h1>
							</StyledContainerResult>
							<small
								className={'text-primary fw-600'}
								onClick={() => {
									resetQuagga()
								}}
							>
								{t(`patient:patientData:italianResidence:cf:detected_cf:scan_again`)}
							</small>
						</StyledContainerCorrectBanner>
					</>
				)}
			</StepContainer>

			<StyledFooter>
				<StyledButtonContainer>
					<BackButton
						onClick={() => {
							destroy()
						}}
						text={'commons:cancel'}
					/>

					{result && <NextButton onClick={handlePostCF} isLoading={isLoading} />}
				</StyledButtonContainer>
			</StyledFooter>
		</>
	)
}

export default CodeScanner
