import {Progress} from 'reactstrap'
import {FC} from 'react'

interface ProgressProps {
	value: number
	color?: string
}

const ProgressCustom: FC<ProgressProps> = ({value, color = 'success'}) => {
	return (
		<>
			<Progress className={'neutral-1-bg-a3 rounded w-100'} color={color} value={value} />
		</>
	)
}

export default ProgressCustom
