import {create} from 'zustand'
import {createPatientDataSlice} from './slices/patientDataSlice'
import {persist} from 'zustand/middleware'
import {PatientDataAction, PatientDataState} from '../types/patientType.ts'
import {CommonsAction, CommonsDataState} from '../types/commonType.ts'
import {createCommonsDataSlice} from './slices/commonDataSlice'
import {createSymptomsDataSlice} from './slices/symptomsDataSlice'
import {createClinicalInfoDataSlice} from './slices/clinicalInfoDataSlice'
import {SymptomsAction, SymptomsDataState} from '../types/symptomType.ts'
import {ClinicalAction, ClinicalInfoDataState} from '../types/clinicalInfoType.ts'

export const useBoundStore = create<
	PatientDataState & PatientDataAction & ClinicalInfoDataState & ClinicalAction & SymptomsDataState & SymptomsAction
>()(
	persist(
		(...a) => ({
			...createPatientDataSlice(...a),
			...createClinicalInfoDataSlice(...a),
			...createSymptomsDataSlice(...a)
		}),
		{name: 'bound-store'}
	)
)

export const useCommonsStore = create<CommonsDataState & CommonsAction>()(
	persist(
		(...a) => ({
			...createCommonsDataSlice(...a)
		}),
		{name: 'common-store'}
	)
)
