import styled, {css, DefaultTheme} from 'styled-components'
import {GroupBase, StylesConfig} from 'react-select'
import {SelectValue} from '@components/ui/Select/index.tsx'

export const StyledLabel = styled.label`
	${({theme: {palette, truncateText, typography}}) => css`
		display: block;
		color: ${palette.neutral['950']};
		text-align: start;
		font-weight: 600;
		${typography.textSm}
		${truncateText}
	`}
`

export const StyledContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 4px;
	padding-bottom: 12px;
	min-width: 100px;
`
type test = {
	theme: DefaultTheme
	isError: boolean
}
//the following code should be jss for react select custom styling
export const customStyles = ({theme, isError}: test): StylesConfig<SelectValue, boolean, GroupBase<SelectValue>> => ({
	control: (provided, state) => ({
		...provided,
		padding: `0 ${theme.spacing * 2}px`,
		width: '100%',
		minHeight: '46px',
		height: '2.5rem',
		borderRadius: '0',
		borderLeft: 'none',
		borderRight: 'none',
		borderTop: 'none',
		borderBottom: `solid ${theme.palette.neutral['500']} 1px`,
		boxShadow: 'none',
		'&:hover': {borderBottom: `solid ${theme.palette.neutral['500']} 1.25px`},
		cursor: state.isDisabled ? 'not-allowed' : 'pointer',
		...(isError && {
			borderColor: theme.palette.danger['600'] + '!important'
		})
	}),
	placeholder: provided => ({
		...provided,
		//text/md regular
		fontSize: '1rem',
		lineHeight: '24px',
		fontWeight: 400,
		margin: 0,
		color: theme.palette.neutral['500']
	}),
	valueContainer: baseStyles => ({
		...baseStyles,
		padding: 0,
		textAlign: 'start',
		//text/md medium,
		fontSize: '1rem',
		lineHeight: '24px',
		fontWeight: 700,
		color: theme.palette.neutral['900']
	}),
	indicatorsContainer: baseStyles => ({
		...baseStyles,
		padding: `0 0 0 ${theme.spacing * 2}px`,
		'& svg': {
			fill: `${theme.palette.neutral['500']}`
		}
	}),

	dropdownIndicator: (baseStyles, state) => ({
		...baseStyles,
		padding: 0,
		transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
		transition: 'transform 0.2s ease'
	}),

	indicatorSeparator: baseStyles => ({
		...baseStyles,
		display: 'none'
	}),

	menu: baseStyles => ({
		...baseStyles,
		overflow: 'hidden',
		marginTop: theme.spacing,
		zIndex: 999,
		padding: `0 ${theme.spacing * 1.5}px`,
		border: `1px solid ${theme.palette.neutral['200']}`
	}),

	menuList: baseStyles => ({
		...baseStyles,
		padding: `${theme.spacing}px 0`,
		cursor: 'pointer',
		maxHeight: '300px'
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		background: `${theme.palette.neutral.white}`,
		textDecoration: state.isFocused || state.isSelected ? 'underline' : 'none',
		color: state.isSelected ? `${theme.palette.neutral['500']}` : `${theme.palette.primary['700']}`,
		cursor: state.isDisabled ? 'not-allowed' : 'pointer',
		fontWeight: 600,
		textDecorationLine: 'none'
	}),
	multiValue: baseStyles => ({
		...baseStyles,
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		padding: `${theme.spacing * 0.5}px ${theme.spacing}px`,
		gap: theme.spacing,
		height: 24,
		background: theme.palette.neutral.white,
		border: `1px solid ${theme.palette.neutral['300']}`,
		borderRadius: '6px'
	}),
	multiValueLabel: baseStyles => ({
		...baseStyles,
		//Text sm/Medium
		fontWeight: 500,
		fontSize: '1rem',
		lineHeight: '20px',
		textTransform: 'capitalize',
		color: theme.palette.neutral['700']
	}),
	multiValueRemove: baseStyles => ({
		...baseStyles
	}),
	input: baseStyles => ({
		...baseStyles,
		margin: 0
	}),
	singleValue: baseStyles => ({
		...baseStyles,
		fontWeight: 700,
		color: theme.palette.neutral['900'],
		fontSize: '16px'
	})
})
