import styled, {css} from 'styled-components'

export const StyledLandingContainer = styled.div`
	${({theme}) => css`
		overflow-y: auto;
		height: 100%;
		width: 100%;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		background: ${theme.palette.neutral.white};
		z-index: 0;
		display: none;
		@media only screen and (min-width: 1030px) {
			display: flex;
		}
	`}
`

export const StyledContainerDropdown = styled.div`
	${({theme}) => css`
		display: flex;
		justify-content: flex-end;
		align-items: center;
		position: relative;
		padding: ${theme.spacing * 6}px ${theme.spacing * 4}px;
	`}
`

export const StyledContainerDataLanding = styled.div`
	${({theme}) => css`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: ${theme.spacing * 12}px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	`}
`
