import {useTranslation} from 'react-i18next'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {StyledContainerTextRecap, StyledLi, StyledRecapTitle} from '@pages/enteredInformation/styles.ts'
import {getLocalizedTooltipLabels} from '@components/commons/IntensityProgress'
import {useMemo} from 'react'
import {handleTranslatedValue} from '@/utilities/helpers'

const SymptomsInfo = () => {
	const {t} = useTranslation()
	const {userType, symptoms} = useBoundStore()
	const {cardsData} = useCommonsStore()

	const tooltipLabels = getLocalizedTooltipLabels(t)

	const symptomsList = cardsData?.symptoms_data?.list

	const painTypes = useMemo(() => {
		return cardsData?.symptoms_data?.pain_types
	}, [cardsData])

	return (
		<div className={'pt-4 d-flex flex-column gap-4'}>
			<StyledContainerTextRecap>
				<StyledRecapTitle className={'text-secondary'}>
					{t(`${userType}:symptoms:symptoms:title`)}
				</StyledRecapTitle>

				<ul>
					{symptoms?.length > 0 ? (
						symptoms?.map((item, index) => {
							return (
								<div key={index}>
									<li className={'fw-600'} key={index}>
										{handleTranslatedValue(symptomsList, item?.symptom?.value)}
									</li>
									<ul>
										<li>
											<p className={'fw-light text-secondary'}>
												{t(`patient:symptoms:intensity`)}:{' '}
												{item?.intensity
													? tooltipLabels[item.intensity]
													: t(`commons:no_option`)}{' '}
												({item?.intensity})
											</p>
										</li>
										<li>
											<p className={'fw-light text-secondary'}>
												{t(`patient:symptoms:pain_type`)}:{' '}
												{handleTranslatedValue(painTypes, item?.pain)}
											</p>
										</li>
									</ul>
								</div>
							)
						})
					) : (
						<StyledLi>{t(`commons:no_option`)}</StyledLi>
					)}
				</ul>
			</StyledContainerTextRecap>
		</div>
	)
}

export default SymptomsInfo
