import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import React, {useMemo, useRef} from 'react'
import {StepContainer} from '@pages/onBoarding/styles.ts'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import StepSymptomsCta from '@pages/symptoms/steps/StepSymptomsCta.tsx'
import SymptomsList from '@components/commons/SymptomsList/SymptomsList.tsx'
import SearchToSelectSymptoms, {Option} from '@components/commons/SearchToSelectSymptoms'
import {Form} from 'reactstrap'

const StepSearchSymptoms: React.FC = () => {
	const {t} = useTranslation()
	const {userType, step, setGeneralPatientData, selectedSymptoms, symptoms, setSymptomsData} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()

	const formRef = useRef<HTMLFormElement>(null)

	const symptomsDataList = useMemo(() => {
		return cardsData?.symptoms_data?.list
	}, [cardsData])

	const removeOption = (option: string) => {
		setSymptomsData(
			'selectedSymptoms',
			selectedSymptoms.filter(item => item?.value !== option)
		)
		setSymptomsData(
			'symptoms',
			symptoms.filter(item => item?.symptom?.value !== option)
		)
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		if (!selectedSymptoms.length) {
			setCommonsData('showToast', {
				isVisible: true,
				text: t(`errors:at_least_one_symptom`) || '',
				status: 'danger'
			})
		} else {
			setGeneralPatientData('step', step + 1)
			setCommonsData('isNextAnimation', true)
		}
	}
	const remapSelectOptions: Option[] | undefined =
		symptomsDataList?.map(option => ({
			label: option.name,
			value: option.id.toString(),
			data: {
				related:
					option.related?.map(rel => ({
						id: rel.id.toString(),
						name: rel.name
					})) || []
			}
		})) || []

	return (
		<>
			<StepContainer>
				<Title
					title={t(`${userType}:symptoms:symptoms:title`)}
					subTitle={t(`${userType}:symptoms:symptoms:subtitle`)}
				/>

				<Form innerRef={formRef} onSubmit={handleSubmit} className="h-100">
					<span>{t(`${userType}:symptoms:symptoms:caption`)}</span>
					<SearchToSelectSymptoms options={remapSelectOptions} selectedOption={selectedSymptoms} />

					<SymptomsList list={selectedSymptoms} handleClose={removeOption} />
				</Form>
			</StepContainer>

			<StepSymptomsCta formRef={formRef} />
		</>
	)
}

export default StepSearchSymptoms
