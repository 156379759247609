import axios from 'axios'

import {LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN} from '@utilities/constants'
import {envVars} from '@/envVars.ts'

const axiosConfig = {
	baseURL: envVars.VITE_API_ENDPOINT,
	headers: {
		common: {
			// Authorization: localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
			// 	? `Bearer ${localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)}`
			// 	: false,

			Accept: 'application/json',
			'Content-Type': 'application/json',
			'X-Requested-With': 'XMLHttpRequest'
		},
		post: {
			'Access-Control-Allow-Origin': '*'
		}
	}
}

// create axios custom instance with custom config
const axiosInstance = axios.create(axiosConfig)

const attemptRefresh = async (refreshToken: string) => {
	const url = '/auth/refresh-token'
	try {
		const {
			data: {tokens}
		} = await axiosInstance.post(url, {refresh_token: refreshToken})
		localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, tokens['refresh_token'])
		localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, tokens['access_token'])
		return tokens
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.log(error.status)
			console.error(error.response)
			console.log(error.response?.data)
			// Do something with this error...
		} else {
			console.error(error)
		}
	}
}

axiosInstance.interceptors.request.use(request => {
	const token = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN)
	if (token) request.headers['Authorization'] = `Bearer ${token}`
	return request
})

const endpointToIgnore = ''

axiosInstance.interceptors.response.use(
	response => {
		// pass response without change
		return response
	},
	async error => {
		// get error info
		const statusCode = error?.response?.status
		const originalRequest = error.config
		console.log('error', error)
		switch (statusCode) {
			case 401: {
				const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
				localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
				if (refreshToken && !originalRequest._retry && !endpointToIgnore.includes(error.config.url)) {
					originalRequest._retry = true // prevent infinite retry loop
					await attemptRefresh(refreshToken)

					return axiosInstance.request(originalRequest)
				}
				localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
				localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN)
				// TODO: reset user in context
				return Promise.reject(error)
			}
			default:
				return Promise.reject(error)
		}
	}
)

export default axiosInstance
