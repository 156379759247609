import axios from '@services/axiosInstance.ts'

export type CfParseBody = {
	code: string | undefined
}

export type CfBody = {
	first_name: string | undefined
	last_name: string | undefined
	gender: number | undefined
	birth_place: string | undefined
	birth_date: string | undefined
}

export type Patient = {
	id?: number | null
	residence_city: number | null
	residence_zip: number | null
	domicile_city: number | null
	domicile_zip: number | null
	first_name: string | null
	last_name: string | null
	birth_date: string | null
	birth_city: number | null
	gender: number | null
	residence_address: string | null
	domicile_address: string | null
	fiscal_code: string | null
	foreign_code: string | null
	foreign_birth_city: string | null
	foreign_residence_city: string | null
	foreign_residence_zip: string | null
	foreign_residence_address: string | null
	foreign_domicile_city: string | null
	foreign_domicile_zip: string | null
	foreign_domicile_address: string | null
	phone_number: string | null
	phone_number_prefix: string | null
	phone_number_notification: boolean | null
	education: number | null
	work_status: number | null
	work_position: number | null
	work_activity: number | null
	second_phone_number_prefix: string | null
	second_phone_number: string | null
	maritial_status: number | null
	birth_country: number | null
	residence_country: number | null
	domicile_country: number | null
}

export type FeedBackSymptom = {
	id: number | null
	card: number | null
	symptom: number | null
	zone: number | null
	pain_intensity: number | null
	pain_type: number | null
	symptom_feedback: number | null
}

export type CardSymptom = {
	card: number | null
	symptom: number | null
	zone: number | null
	pain_intensity: number | null
	pain_type: number | null
	symptom_feedback: number | null
	card_notes: string | null
}

export type CreateCard = {
	access_date: string | null
	patient: Patient | null
	accessreason: number | null
	timing: number | null
	person: string | null
	language: string | null
}

export type Card = {
	access_date?: string | null
	patient?: Patient | null
	pathologies?: number[] | null
	other_pathologies?: string | null
	allergies?: number[] | null
	other_allergy?: string | null
	drugs?: string[] | null
	alcoholic?: boolean
	smoker?: boolean
	drug_addict?: boolean
	access_reason_duration?: string | null
	personal_data_duration?: string | null
	clinical_data_duration?: string | null
	score?: string | null
	color?: string | null
	person?: string | null
	cardsymptom_set?: CardSymptom[] | null
	notes?: string | null
	timing?: number | null
	accessreason?: number | null
}

export const postFiscalCodeParser = (body: CfParseBody) => {
	return axios.post(`/fiscal_code_parser/`, body)
}

export const postFiscalCodeGetter = (body: CfBody) => {
	return axios.post(`/fiscal_code_getter/`, body)
}

export const postCard = (body: CreateCard) => {
	return axios.post(`/cards/`, body)
}

export const putCard = (body: Card, id: number) => {
	return axios.put(`/cards/${id}/`, body)
}

export const postSymptoms = (body: CardSymptom[]) => {
	return axios.post(`/cardsymptoms/`, body)
}

export const putSymptoms = (body: FeedBackSymptom[]) => {
	return axios.put(`/cardsymptoms/feedbacks_update/`, body)
}

export const getQrColor = (qr: string) => {
	return axios.get(`/cards_patients/${qr}/`)
}
