import {ButtonHTMLAttributes, FC, ReactNode} from 'react'
import {StyledButton} from '@components/ui/Button/style.ts'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string
	children: ReactNode
	fullwidth?: boolean
	size?: 'sm' | 'md' | 'lg'
	color?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'link'
	outline?: boolean
}

const ButtonCustom: FC<ButtonProps> = ({
	children,
	className,
	type = 'button',
	disabled,
	fullwidth = false,
	size = 'md',
	color = 'primary',
	outline = true,
	...rest
}) => (
	<StyledButton
		className={className}
		type={type}
		disabled={disabled}
		fullwidth={fullwidth ? 1 : 0}
		size={size}
		outline={outline}
		color={color}
		{...rest}
	>
		{children}
	</StyledButton>
)

export default ButtonCustom

ButtonCustom.displayName = 'Button'
