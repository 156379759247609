import Select, {SelectValue} from '@components/ui/Select'
import {useTranslation} from 'react-i18next'
import {ChangeEvent, FC, useEffect, useMemo, useRef} from 'react'
import {
	StyledInputNumber,
	StyledInputNumberLabel,
	StyledInputNumberContainer
} from '@components/commons/PhoneInput/style.ts'
import {useCommonsStore} from '@/store/boundStore'

interface PhoneInputProps {
	setPhonePrefix: (newValue: SelectValue) => void
	prefix: SelectValue | undefined
	setPhoneNumber: (newValue: number) => void
	phoneNumber: number | undefined
	autoFocus?: boolean
	invalid?: boolean
}

const PhoneInput: FC<PhoneInputProps> = ({setPhonePrefix, prefix, setPhoneNumber, phoneNumber, autoFocus, invalid}) => {
	const {t} = useTranslation()
	const {cardsData} = useCommonsStore()

	const inputRef = useRef<HTMLInputElement>(null)

	useEffect(() => {
		if (autoFocus && inputRef) {
			setTimeout(() => {
				inputRef.current?.focus()
			}, 300)
		}
	}, [autoFocus])

	const countryCodes = useMemo(() => {
		return cardsData?.patient_data?.countries
	}, [cardsData])

	const prefixNumbers = countryCodes?.map(i => {
		return {
			label: `${i.code} (${i.dial_code})`,
			value: `${i.code} (${i.dial_code})`
		}
	})

	const MAX_LENGTH = prefix?.label?.includes('IT') ? 10 : 11

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.value.length <= MAX_LENGTH) {
			setPhoneNumber(+e.target.value)
		} else {
			setPhoneNumber(+e.target.value.slice(0, MAX_LENGTH))
		}
	}
	return (
		<>
			<div className={'row'}>
				<div className={'col-4 pe-0'}>
					<Select
						options={prefixNumbers || []}
						label={t('commons:form:prefix')}
						onChange={newValue => {
							setPhonePrefix(newValue as SelectValue)
						}}
						value={prefix}
					/>
				</div>

				<StyledInputNumberContainer className={'col-8'}>
					<StyledInputNumberLabel className={phoneNumber ? 'visible' : 'invisible'}>
						{t('commons:form:phone_number')}
					</StyledInputNumberLabel>
					<StyledInputNumber
						placeholder={t('commons:form:placeholder:phone_number') || ''}
						onChange={onChange}
						value={phoneNumber || ''}
						type={'tel'}
						inputMode="numeric"
						pattern="[0-9]*"
						integral="true"
						number="true"
						className={'fw-600 shadow-none'}
						invalid={invalid}
						innerRef={inputRef}
					/>
					{invalid && <div className={'text-danger fw-bold'}>{t('errors:phone_error') || ''}</div>}
				</StyledInputNumberContainer>
			</div>
		</>
	)
}

export default PhoneInput
