import styled, {css} from 'styled-components'
import {OffcanvasBody} from 'reactstrap'

export const StyledOffcanvasBody = styled(OffcanvasBody)`
	${({theme}) => css`
		z-index: 2000;
		border-radius: 8px 8px 0 0;
		height: 100%;
		overflow-y: scroll;
		background: ${theme.palette.neutral.white};
		& .x-icon {
			position: absolute;
			top: 15px;
			right: 15px;
			fill: ${theme.palette.neutral['500']};
		}
	`}
`
