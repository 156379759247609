import {StyledCardInfoDivider, StyledContainerChip, StyledCardOptions} from '@components/ui/CardOption/style.ts'
import {forwardRef, ReactNode} from 'react'

interface CardOptionsProps {
	icon?: ReactNode
	isInfo?: boolean
	title: string
	subtitle?: string | null
	withBorder?: boolean
	isAnimated?: boolean
	onClick?: () => void
	children?: ReactNode
	havePadding?: boolean
	isChecked?: boolean
	info?: string | null
	haveChip?: boolean
	chipChildren?: ReactNode
	isSelected?: boolean
}

const CardOption = forwardRef<HTMLInputElement, CardOptionsProps>(
	(
		{
			icon,
			isInfo,
			title,
			subtitle,
			withBorder = true,
			isAnimated,
			isSelected,
			onClick,
			children,
			havePadding = true,
			isChecked,
			info,
			haveChip = false,
			chipChildren,
			...props
		},
		ref
	) => {
		return (
			<StyledCardOptions
				onCopy={e => e.preventDefault()}
				withBorder={withBorder}
				isAnimated={isAnimated}
				havePadding={havePadding}
				isSelected={isSelected}
				icon={icon || children}
				className={withBorder ? 'border rounded' : 'rounded'}
				onClick={onClick}
				ref={ref}
				{...props}
			>
				{icon ? (
					<div>
						{icon}
						{isInfo && <StyledCardInfoDivider />}
					</div>
				) : (
					<div>{children}</div>
				)}

				<div className={info ? 'd-flex flex-column justify-content-between gap-2' : 'd-flex flex-column gap-1'}>
					<div className={'d-flex justify-content-between'}>
						<p className={isChecked ? 'primary-color-b1 title' : 'title'}>{title}</p>
						{haveChip && <StyledContainerChip>{chipChildren}</StyledContainerChip>}
					</div>
					{info && <p className={'text-black-50'}>{info}</p>}
					{subtitle && <span className={'text-secondary'}>{subtitle}</span>}
				</div>
			</StyledCardOptions>
		)
	}
)

export default CardOption
