import {ChangeEvent, FC, InputHTMLAttributes, ReactNode} from 'react'
import {FieldError} from 'react-hook-form'
import {DefaultTFuncReturn} from 'i18next'
import {FormGroup, Input, Label} from 'reactstrap'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string
	label?: string | DefaultTFuncReturn
	name?: string
	type: 'date'
	typeIcon?: ReactNode
	copyAction?: boolean
	touched?: boolean
	error?: FieldError
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	/**
	 * how to use errorMessage with translations
	 *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
	 */
	errorMessage?: string | DefaultTFuncReturn
	visibilityToggle?: boolean
	helpText?: string | DefaultTFuncReturn
	placeholder?: string
	inputSize?: 'sm' | 'md' | 'lg'
}

const InputDate: FC<InputProps> = ({label, onChange, name, type, placeholder, ...rest}) => {
	return (
		<FormGroup>
			<Label className="custom-label" for={name}>
				{label}
			</Label>
			<Input id={name} name={name} placeholder={placeholder} type={type} onChange={onChange} {...rest} />
		</FormGroup>
	)
}

export default InputDate
