import {RouterProvider} from 'react-router-dom'
import {StyleSheetManager, ThemeProvider} from 'styled-components'
import {getAppTheme, GlobalStyles} from '@/theme'
import router from './router.tsx'

function App() {
	const themeName = 'light'

	return (
		<ThemeProvider theme={getAppTheme({name: themeName})}>
			<StyleSheetManager>
				<>
					<GlobalStyles />
					<RouterProvider router={router} />
				</>
			</StyleSheetManager>
		</ThemeProvider>
	)
}

export default App
