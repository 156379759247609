import styled, {css} from 'styled-components'

export const StyledMain = styled.div`
	${({theme}) => css`
		overflow-y: auto;
		height: 100%;
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		position: relative;
		background: ${theme.palette.neutral.white};
		z-index: 0;

		@media only screen and (min-width: 1279px) {
			display: none;
		}
	`}
`
