import StepReason from '@/pages/onBoarding/steps/StepReason'
import StepTime from '@/pages/onBoarding/steps/StepTime'
import {StyledContainerSpan} from '@pages/onBoarding/styles.ts'
import StepCF from '@/pages/onBoarding/steps/StepCF'
import StepRegistry from '@/pages/onBoarding/steps/StepRegistry'
import StepResidence from '@/pages/onBoarding/steps/StepResidence'
import {CheckIcon, UncheckedCircleIcon} from '@assets/icons/icons.tsx'
import StepPhone from '@/pages/onBoarding/steps/StepPhone'
import StepOtherPhone from '@/pages/onBoarding/steps/StepOtherPhone'
import StepOtherInfo from '@/pages/onBoarding/steps/StepOtherInfo'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {capitalize, handleTranslatedValue} from '@utilities/helpers.ts'

export const usePatientDataArray = () => {
	const {t} = useTranslation()
	const {whatHappened, howLong, patient, userType, subStep} = useBoundStore()
	const {cardsData} = useCommonsStore()
	const {
		firstName,
		lastName,
		isResident,
		cf,
		birthDate,
		gender,
		birthNation,
		birthNiCity,
		birthProvince,
		birthPlace,
		residenceNiAddress,
		maritalStatus,
		residenceAddress,
		residenceProvince,
		residenceCity,
		residenceCap,
		residenceIsDomicile,
		domicileAddress,
		domicileProvince,
		domicileCity,
		domicileCap,
		residenceNation,
		residenceNiCity,
		residenceNiCap,
		phoneNumberPrefix,
		phoneNumber,
		sms,
		otherPhoneNumber,
		otherPhoneNumberPrefix,
		qualification,
		workCondition,
		position,
		activity,
		otherSms
	} = patient

	const reasonValue = handleTranslatedValue(cardsData?.patient_data?.access?.reasons, whatHappened?.id)
	const timingValue = handleTranslatedValue(cardsData?.patient_data?.access?.timings, howLong?.id)
	const maritialStatusValue = handleTranslatedValue(cardsData?.patient_data?.maritial_status, maritalStatus?.value)
	const educationValue = handleTranslatedValue(
		cardsData?.patient_data?.professional_data?.education,
		qualification?.value
	)
	const workStatusValue = handleTranslatedValue(
		cardsData?.patient_data?.professional_data?.work_status,
		workCondition?.value
	)
	const workPositionValue = handleTranslatedValue(
		cardsData?.patient_data?.professional_data?.work_position,
		position?.value
	)
	const workActivityValue = handleTranslatedValue(
		cardsData?.patient_data?.professional_data?.work_activity,
		activity?.value
	)

	const currentSubStep = () => {
		switch (subStep) {
			case 6:
				return <StepCF />
			case 7:
				return <StepRegistry />
			case 8:
				return <StepResidence />
			default:
				return <StepCF />
		}
	}

	return [
		{
			title: t(`${userType}:review:step_1_review`),
			step: <StepReason />,
			value: <p>{reasonValue}</p>
		},
		{
			title: t(`${userType}:review:step_2_review`),
			step: <StepTime />,
			value: <p>{timingValue}</p>
		},
		{
			title: t(`${userType}:review:step_5_review`),
			step: currentSubStep(),
			value: (
				<>
					<p>{cf || '-'}</p>
					{isResident === false && (
						<div className={'d-flex gap-2'}>
							<CheckIcon />
							<p>{t(`${userType}:patientData:noCf`)}</p>
						</div>
					)}
				</>
			)
		},
		{
			title: t(`${userType}:review:step_6_review`),
			step: <StepRegistry />,
			value: (
				<div className={'d-flex flex-column gap-3'}>
					<div className={'d-flex flex-column gap-2'}>
						<StyledContainerSpan className={'text-secondary'}>{t(`commons:form:name`)}</StyledContainerSpan>
						<p>{capitalize(firstName || '')}</p>
					</div>
					<div className={'d-flex flex-column gap-2'}>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:last_name`)}
						</StyledContainerSpan>
						<p>{capitalize(lastName || '')}</p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:birth_date`)}
						</StyledContainerSpan>
						<p>{dayjs(birthDate).format('DD/MM/YYYY')} </p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:gender`)}
						</StyledContainerSpan>
						<p>{gender?.id === 1 ? t(`commons:form:male`) : t(`commons:form:female`)} </p>
					</div>
					{isResident ? (
						<>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:birthplace`)}
								</StyledContainerSpan>
								<p> {birthProvince?.label || '-'}</p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:province_of_birth`)}
								</StyledContainerSpan>
								<p>{birthPlace?.label || '-'}</p>
							</div>
						</>
					) : (
						<>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:nation_of_birth`)}
								</StyledContainerSpan>
								<p> {birthNation?.label || '-'}</p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:birthplace`)}
								</StyledContainerSpan>
								<p>{birthNiCity ? birthNiCity : '-'}</p>
							</div>
						</>
					)}
				</div>
			)
		},
		{
			title: t(`${userType}:review:step_7_review`),
			step: <StepResidence />,
			value: (
				<div className={'d-flex flex-column gap-3'}>
					{isResident ? (
						<>
							{' '}
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:address`)}
								</StyledContainerSpan>
								<p>{capitalize(residenceAddress || '')} </p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:province_of_residence`)}
								</StyledContainerSpan>
								<p>{residenceProvince && residenceProvince?.label} </p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:city_of_residence`)}
								</StyledContainerSpan>
								<p>{residenceCity?.label}</p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:postal_code`)}
								</StyledContainerSpan>
								<p>{residenceCap?.label}</p>
							</div>
						</>
					) : (
						<>
							{' '}
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:address`)}
								</StyledContainerSpan>
								<p>{capitalize(residenceNiAddress || '')} </p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:nation_of_residence`)}
								</StyledContainerSpan>
								<p>{residenceNation?.label} </p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:city_of_residence`)}
								</StyledContainerSpan>
								<p>{(residenceNiCity && residenceNiCity) || '-'}</p>
							</div>
							<div>
								<StyledContainerSpan className={'text-secondary'}>
									{t(`commons:form:postal_code_foreign`)}
								</StyledContainerSpan>
								<p>{(residenceNiCap && residenceNiCap) || '-'}</p>
							</div>
						</>
					)}
					<div>
						{residenceIsDomicile ? (
							<div className={'d-flex gap-2'}>
								<CheckIcon />
								<p>{t('commons:info:domicile_corresponds_residence')}</p>
							</div>
						) : (
							<div className={'d-flex flex-column gap-3'}>
								<div>
									<StyledContainerSpan className={'text-secondary'}>
										{t(`commons:form:address`)}
									</StyledContainerSpan>
									<p>{capitalize(domicileAddress || '')} </p>
								</div>
								<div>
									<StyledContainerSpan className={'text-secondary'}>
										{t(`commons:form:province_of_domicile`)}
									</StyledContainerSpan>
									<p>{domicileProvince && domicileProvince?.label} </p>
								</div>
								<div>
									<StyledContainerSpan className={'text-secondary'}>
										{t(`commons:form:place_of_domicile`)}
									</StyledContainerSpan>
									<p>{domicileCity?.label}</p>
								</div>
								<div>
									<StyledContainerSpan className={'text-secondary'}>
										{t(`commons:form:postal_code`)}
									</StyledContainerSpan>
									<p>{domicileCap?.label}</p>
								</div>
							</div>
						)}
					</div>
				</div>
			)
		},
		{
			title: t(`${userType}:review:step_8_review`),
			step: <StepPhone />,
			value: (
				<div className={'d-flex flex-column gap-4'}>
					<div className={'d-flex gap-5'}>
						<div className={'d-flex flex-column gap-2'}>
							<StyledContainerSpan className={'text-secondary'}>
								{t('commons:form:prefix')}
							</StyledContainerSpan>
							<p>{phoneNumberPrefix?.label}</p>
						</div>

						<div className={'d-flex flex-column gap-2'}>
							<StyledContainerSpan className={'text-secondary'}>
								{t('commons:form:phone_number')}
							</StyledContainerSpan>
							<p>{phoneNumber}</p>
						</div>
					</div>
					{sms && (
						<div className={'d-flex align-items-center gap-2'}>
							<CheckIcon />
							<p>{t(`${userType}:patientData:updates:yes_subtitle`)}</p>
						</div>
					)}
				</div>
			)
		},
		{
			title: t(`${userType}:review:step_9_review`),
			step: <StepOtherPhone />,
			value: (
				<div>
					{otherSms ? (
						<div className={'d-flex flex-column gap-4'}>
							<div className={'d-flex gap-5 neutral-1-bg-a1 p-3'}>
								<div className={'d-flex flex-column gap-2'}>
									<StyledContainerSpan className={'text-secondary'}>
										{t('commons:form:prefix')}
									</StyledContainerSpan>
									<p>{otherPhoneNumberPrefix?.label}</p>
								</div>

								<div className={'d-flex flex-column gap-2'}>
									<StyledContainerSpan className={'text-secondary'}>
										{t('commons:form:phone_number')}
									</StyledContainerSpan>
									<p>{otherPhoneNumber}</p>
								</div>
							</div>
							<div className={'d-flex align-items-center gap-2'}>
								<CheckIcon />
								<p>{t(`${userType}:patientData:updates:yes_title`)}</p>
							</div>
						</div>
					) : (
						<div className={'d-flex gap-2'}>
							<UncheckedCircleIcon />
							<p>{t(`${userType}:patientData:updates:no_title`)}</p>
						</div>
					)}
				</div>
			)
		},
		{
			title: t(`${userType}:review:step_10_review`),
			step: <StepOtherInfo />,
			value: (
				<div className={'d-flex flex-column gap-3'}>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:marital_status`)}
						</StyledContainerSpan>
						<p> {maritialStatusValue}</p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:qualification`)}
						</StyledContainerSpan>
						<p>{educationValue}</p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:work_situation`)}
						</StyledContainerSpan>
						<p> {workStatusValue}</p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:position`)}
						</StyledContainerSpan>
						<p>{workPositionValue}</p>
					</div>
					<div>
						<StyledContainerSpan className={'text-secondary'}>
							{t(`commons:form:activity`)}
						</StyledContainerSpan>
						<p>{workActivityValue}</p>
					</div>
				</div>
			)
		}
	]
}
