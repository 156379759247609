import styled from 'styled-components'
import {DropdownItem, DropdownToggle} from 'reactstrap'

export const StyledDropdownToggle = styled(DropdownToggle)`
	font-size: 18px;
	font-weight: 700;
`

export const StyledDropdownItem = styled(DropdownItem)`
	font-size: 18px;
`
