import styled, {css} from 'styled-components'
import {Input} from 'reactstrap'

export const StyledInputSearchContainer = styled.div`
	${({theme}) => css`
		position: relative;
		label {
			position: relative;
			width: 100%;
		}

		label:before {
			content: '';
			position: absolute;
			left: 10px;
			top: 0;
			bottom: 0;
			width: 20px;
			background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 15.3L21.9 21.1L21.1 21.9L15.3 16C13.8225 17.2676 11.9465 17.9755 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C17.9755 11.9465 17.2676 13.8225 16 15.3ZM3 10C3 13.866 6.13401 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10Z' fill='%235C6F82'/%3E%3C/svg%3E%0A")
				center / contain no-repeat;
		}

		input {
			padding: ${theme.spacing * 3}px ${theme.spacing * 10}px;
			&:disabled {
				background-color: ${theme.palette.neutral[300]};
			}
		}

		input + svg {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			cursor: pointer;
		}
	`}
`

export const StyledInputTextArea = styled(Input)`
	${({theme}) => css`
		border: 1px solid ${theme.palette.neutral[300]};
		min-height: 150px !important;
		font-weight: 700;
		&::placeholder {
			font-weight: normal;
		}
	`}
`
