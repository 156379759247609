import {StyledContainerIcon} from '@pages/onBoarding/styles.ts'
import {ArrowIcon} from '@assets/icons/icons.tsx'
import ButtonCustom from '@components/ui/Button'
import {useTranslation} from 'react-i18next'
import {FC} from 'react'

interface BackButtonProps {
	onClick?: () => void
	text?: string
	disabled?: boolean
	className?: string
}

export const BackButton: FC<BackButtonProps> = ({onClick, text = 'commons:back', disabled, className}) => {
	const {t} = useTranslation()
	return (
		<ButtonCustom
			color="link"
			outline={false}
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={className}
		>
			<StyledContainerIcon>
				<div>
					<ArrowIcon />
				</div>
				{t(text)}
			</StyledContainerIcon>
		</ButtonCustom>
	)
}

export default BackButton
