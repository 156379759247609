import styled, {css, DefaultTheme, keyframes} from 'styled-components'
import ButtonCustom from '@components/ui/Button'
import {useCommonsStore} from '@/store/boundStore'
import CardOption from '@components/ui/CardOption'
interface StyledButtonAddDrugProps {
	theme: DefaultTheme
	isSmall?: boolean
}
const alertAnimation = keyframes`
	0% {
  right: -250px;
	opacity: 0;
	}
	10% {
		right: -1px;
		opacity: 1;
	}
	89% {
		right: -1px;
		opacity: 1;
	}
	90% {
		right: -1px;
		opacity: .5;
	}
  100%{
	right: -1px;
	opacity: 0;
}
`

const enterAnimation = keyframes`
	0% {
		right: -250px;
	}
	10% {
		right: 0;
	}
	100% {
		right: 0;
	}
`

const exitAnimation = keyframes`
	0% {
		left: -250px;
	}
	10% {
		left: 0;
	}
	100% {
		left: 0;
	}
`

export const StepContainer = styled.div(
	({step0, notAnimate, theme: {spacing, palette}}: {theme: DefaultTheme; step0?: boolean; notAnimate?: boolean}) => {
		const {isNextAnimation} = useCommonsStore()
		return css`
			padding: 0 ${spacing * 4}px;
			display: flex;
			flex-direction: column;
			gap: ${spacing * 6}px;
			height: calc(100vh - 123px);
			overflow-y: scroll;
			overflow-x: hidden;
			position: relative;
			border-radius: 8px 8px 0 0;
			background: ${palette.neutral.white};
			${!notAnimate &&
			css`
				animation: ${isNextAnimation ? enterAnimation : exitAnimation} 2s ease;
			`}

			.alert-danger {
				position: absolute;
				right: -1px;
				top: 10px;
				animation: ${alertAnimation} 3s linear;
				opacity: 0;
			}
			form {
				display: flex;
				flex-direction: column;
				gap: ${spacing * 4}px;
			}

			.custom-label {
				font-weight: 600;
				color: #1a1a1a !important;
				padding-left: 0.25rem !important;
				transition: opacity 0.1s ease-in-out;
				font-size: 1rem;
			}
			.custom-label.visible {
				opacity: 1;
			}

			input {
				font-weight: 700;
			}
			input::placeholder {
				font-weight: normal;
			}
			button {
				background: ${palette.neutral['200']};
				padding: ${spacing}px;
			}

			${step0 &&
			css`
				border-radius: 0;
				background: ${palette.primary['25']};
			`}
		`
	}
)

export const StyledButtonContainer = styled.div`
	display: grid;
	grid-template-columns: 80px 115px;
	justify-content: space-between;
	gap: 16px;
`

export const StyledContainerIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
`

export const StyledContainerCheckboxStep8 = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 90px;
	padding-top: 10px;
`

export const StyledLabel = styled.label`
	${({theme, checked}: {theme: DefaultTheme; checked?: boolean}) => css`
		${theme.typography.textSm};
		font-weight: 600;

		${checked &&
		css`
			color: ${theme.palette.primary['700']};
		`}
	`}
`

export const StyledContainerIconStep2 = styled.div`
	${({theme}) => css`
		width: 36px;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background-color: ${theme.palette.primary['50']};
	`}
`

export const StyledButtonStart = styled(ButtonCustom)`
	width: 100%;
`

//review page styles
export const StyledReviewContainer = styled.div`
	${({theme}) => css`
		display: flex;
		flex-flow: column;
		gap: ${theme.spacing * 4}px;
		padding: ${theme.spacing * 4}px;
		border: 1px solid ${theme.palette.neutral['200']};
		border-radius: ${theme.spacing * 2}px;
		& .headerCta {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		& .editCta {
			color: ${theme.palette.primary['700']};
			text-decoration: underline;
			${theme.typography.textSm};
			font-weight: 600;
		}
		p {
			font-weight: bold;
		}
	`}
`

export const StyledContainerSpan = styled.span`
	${({theme}) => css`
		${theme.typography.textSm};
		font-weight: 600;
	`}
`

export const StyledButtonAddDrug = styled.div`
	${({theme, isSmall = false}: StyledButtonAddDrugProps) => css`
		width: ${isSmall ? '40px' : '100%'};
		height: ${isSmall ? '40px' : '48px'};
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		border-radius: 4px;
		border: 2px solid ${theme.palette.neutral['700']};

		& > div {
			padding: ${isSmall ? '12px 12px' : '12px 24px'};
			color: ${theme.palette.neutral['700']};
		}

		& p {
			font-weight: 600;
			${theme.typography.textMd};
		}
	`}
`

export const StyledContainerLighterBack = styled.div`
	${({theme}) => css`
		background-color: ${theme.palette.primary['25']};
	`}
`

const enterAnimationStep2 = keyframes`
	0% {
		opacity: 0;
		transform: scale(0.8, 0.8);
	}
	100% {
		opacity: 1;
		transform: scale(1, 1);
	}
`

export const StyledStep2Animation = styled(CardOption)`
	animation: ${enterAnimationStep2} 0.5s cubic-bezier(0.26, 0.53, 0.74, 1.48);
`
