const breakpoints = {
	width: {
		xs: 365,
		s: 414,
		mUp: 768,
		l: 1200,
		lUp: 1279,
		xl: 1440,
		xxl: 1920
	}
}

const mediaQueries = {
	xs: `@media only screen and (max-width: ${breakpoints.width.xs}px)`,
	s: `@media only screen and (max-width: ${breakpoints.width.s}px)`,
	mUp: `@media only screen and (min-width: ${breakpoints.width.mUp}px)`,
	l: `@media only screen and (max-width: ${breakpoints.width.l}px)`,
	lUp: `@media only screen and (min-width: ${breakpoints.width.lUp}px)`,
	xl: `@media only screen and (max-width: ${breakpoints.width.xl}px)`
}

export default mediaQueries
