import {FormGroup, Input, Label} from 'reactstrap'
import {FC} from 'react'

interface CheckboxProps {
	check?: boolean
	name?: string
	label?: string | null
	description?: string | null
	disabled?: boolean
	onChange?: () => void
}

const CheckboxCustom: FC<CheckboxProps> = ({check = false, name, label, description, disabled = false, onChange}) => {
	return (
		<>
			<FormGroup check={check} className="form-check mb-3 mt-0">
				<Input id={name} name={name} type="checkbox" checked={check} onChange={onChange} disabled={disabled} />
				<Label className={check ? 'fw-600 text-primary' : 'fw-600'} check={check} for={name}>
					{label}
				</Label>
				<div className={'d-grid'} onClick={onChange}>
					<div className={'row'}>
						<div className={'col-1'} />

						<div className={'col'}>
							<p className={'text-muted'}>{description}</p>
						</div>
					</div>
				</div>
			</FormGroup>
		</>
	)
}

export default CheckboxCustom
