import styled, {css} from 'styled-components'
import {ButtonGroup} from 'reactstrap'

export const StyledContainerReceivedData = styled.div`
	${({theme}) => css`
		padding: ${theme.spacing * 8}px ${theme.spacing * 4}px ${theme.spacing * 6}px ${theme.spacing * 4}px;
		border-bottom: 1px solid ${theme.palette.neutral[300]};
		background: ${theme.palette.neutral.white};
	`}
`

export const StyledContainerCheckBox = styled(ButtonGroup)`
	display: flex;
	flex-direction: column;
	padding: 0 8px 0 8px;

	& label {
		font-weight: 600;
	}
`

export const StyledContainerRowInput = styled.div`
	${({theme}) => css`
		display: grid;
		grid-template-columns: 1fr 40px;
		gap: ${theme.spacing * 4}px;
		align-items: end;
	`}
`
export const StyledContainerAddOther = styled(ButtonGroup)`
	display: grid;
	grid-template-columns: 26px 1fr;
`

export const StyledBlueDivider = styled.div`
	height: 2px;
	width: 15px;
`
