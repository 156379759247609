import {FC, ReactNode, useEffect, useState} from 'react'
import {Offcanvas} from 'reactstrap'
import {StyledOffcanvasBody} from '@components/commons/Drawer/styles.ts'
import {useBoundStore} from '@store/boundStore.ts'

interface DraggableDrawerProps {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	children: ReactNode
}

const Drawer: FC<DraggableDrawerProps> = ({isOpen, setIsOpen, children}) => {
	const {isInDrawer, setGeneralPatientData, isInReview} = useBoundStore()
	const [fading, setFading] = useState(false)

	useEffect(() => {
		handleFadeTimer(isOpen)
	}, [isOpen])

	useEffect(() => {
		if (!isInDrawer) {
			setGeneralPatientData('subStep', 6)
			handleFadeTimer(false)
		}
	}, [isInDrawer])

	const handleToggleDrawer = () => {
		if (isInReview) {
			handleFadeTimer(true)
		} else {
			handleFadeTimer(!isOpen)
			setGeneralPatientData('isInDrawer', !isOpen)
		}
	}

	const handleFadeTimer = (value: boolean) => {
		setIsOpen(value)
		setTimeout(() => {
			setFading(value)
		}, 300)
	}

	return (
		<Offcanvas
			direction="bottom"
			fade={true}
			scrollable={false}
			isOpen={fading}
			toggle={handleToggleDrawer}
			style={{
				height: `95%`,
				borderRadius: '8px 8px 0px 0px',
				transform: isOpen ? '' : fading ? 'translateY(100%)' : 'none'
			}}
		>
			<StyledOffcanvasBody className={'p-0'}>{children}</StyledOffcanvasBody>
		</Offcanvas>
	)
}

export default Drawer
