import styled, {css} from 'styled-components'
import * as AvatarPrimitive from '@radix-ui/react-avatar'

export const AvatarRoot = styled(AvatarPrimitive.Root)`
	${({theme: {palette, spacing}}) => css`
		display: inline-flex;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
		user-select: none;
		width: 40px;
		height: 40px;
		border-radius: 100%;
		background: ${palette.neutral.white};
		padding: ${spacing + 6}px;
	`}
`
