import {useBoundStore} from '@/store/boundStore'
import React from 'react'

import StepReceivedData from './steps/StepReceivedData'
import StepPatology from './steps/StepPatology'
import StepMedication from './steps/StepMedication'
import StepAllergy from './steps/StepAllergy'
import StepHabit from './steps/StepHabit'

interface StepsMap {
	[key: number]: React.ReactNode
}

const stepsMap: StepsMap = {
	13: <StepReceivedData />,
	14: <StepPatology />,
	15: <StepMedication />,
	16: <StepAllergy />,
	17: <StepHabit />
}

const ClinicalInfo = () => {
	const {step} = useBoundStore()

	return <>{stepsMap[step] || <StepReceivedData />}</>
}

export default ClinicalInfo
