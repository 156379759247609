import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {useTranslation} from 'react-i18next'
import Title from '@components/ui/Title'
import QRContainer from '@components/commons/QRContainer/index.tsx'
import {StepContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import Alert from '@components/ui/Alert'
import Footer from '@components/ui/Footer'
import {useNavigate, useParams} from 'react-router-dom'
import {PHASES, routes} from '@utilities/constants'
import {useEffect, useState} from 'react'
import {AxiosError, handleGenericError} from '@/utilities/helpers'
import {getQrColor} from '@/services/patient.http'
import Spinner from '@components/ui/Spinner'

const CodeAssigned = () => {
	const {userType, selectedSymptoms, colorCode, setGeneralPatientData} = useBoundStore()
	const {t} = useTranslation()
	const {id} = useParams()
	const navigate = useNavigate()
	const [footerShown, setFooterShown] = useState(false) // Nuovo stato
	const [showFooter, setShowFooter] = useState(false)
	const {setCommonsData} = useCommonsStore()

	useEffect(() => {
		//TODO: remove this when the feature is ready after the demo
		const timeoutInMilliseconds = 10 * 1000 // 10 seconds
		// 30 * 60 * 1000;

		const timeoutId = setTimeout(() => {
			setShowFooter(true)
			localStorage.setItem('footerShown', 'true')
		}, timeoutInMilliseconds)

		return () => clearTimeout(timeoutId)
	}, [])

	useEffect(() => {
		if (id !== undefined) {
			setGeneralPatientData('colorCode', null)
			handleGetColor(id)
		}
	}, [])

	const random = Math.floor(Math.random() * 4) + 1

	const handleGetColor = async (qr: string) => {
		try {
			const {data} = await getQrColor(qr)
			if (data) {
				if (data?.color) {
					setGeneralPatientData('colorCode', data?.color?.value)
				} else {
					setGeneralPatientData('colorCode', random)
				}
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		}
	}

	const handleHowIsItGoing = () => {
		setCommonsData('isNextAnimation', true)
		navigate(`${routes.FEEDBACK.path}`)
	}

	useEffect(() => {
		const footerShown = localStorage.getItem('footerShown')
		if (footerShown === 'true') {
			setFooterShown(true)
		}
	}, [])

	return (
		<>
			<StepContainer className={'h-100'}>
				<Title
					title={t(`${userType}:waiting:assignedCode:title`)}
					subTitle={t(`${userType}:waiting:assignedCode:subtitle`)}
					isCentered
				/>

				{colorCode ? <QRContainer codeType={colorCode} isColorful /> : <Spinner size={30} />}
				<button
					style={{padding: '4px', textAlign: 'center'}}
					type="button"
					onClick={() => {
						setCommonsData('userPhase', PHASES.triageConcluded)
						navigate(`${routes.TRIAGE_CONCLUDED.path}`)
					}}
				>
					<p>Triage conluso cta</p>
				</button>
				<div className={'mb-5'}>
					<Alert text={t(`${userType}:waiting:assignedCode:subtitle2`)} status={'secondary'} />
				</div>
			</StepContainer>
			{showFooter && selectedSymptoms.length > 0 && !footerShown && (
				<Footer>
					<StyledButtonStart color="primary" outline={false} type="button" onClick={handleHowIsItGoing}>
						<p>{t(`${userType}:waiting:howIsItGoing:title`)}</p>
					</StyledButtonStart>
				</Footer>
			)}
		</>
	)
}

export default CodeAssigned
