import {Modal} from 'reactstrap'
import styled, {css} from 'styled-components'

export const StyledModal = styled(Modal)`
	${({theme}) => css`
		&.modal-dialog {
			max-width: 350px;

			${theme.mediaQueries.mUp} {
				max-width: 500px;
			}
		}
	`}
`
