import {Navigate, Outlet} from 'react-router-dom'
import {routes} from '@/utilities/constants'
import {useBoundStore} from '@/store/boundStore'

const PublicRoute = () => {
	const {qrCode} = useBoundStore()

	return qrCode ? <Navigate to={routes.CLINICALINFO.path} /> : <Outlet />
}

export default PublicRoute
