import styled, {css, DefaultTheme, keyframes} from 'styled-components'

type StyledSpinnerProps = {
	color?: string
}

const pulse = (color?: string) => keyframes`
  50% { fill: ${color} }
  to { fill: transparent }
`

interface StyledSpinnerContainerProps {
	theme: DefaultTheme
	overlay?: boolean
	isSmall?: boolean
}

export const StyledSpinnerContainer = styled.div`
	${({theme, overlay, isSmall}: StyledSpinnerContainerProps) => css`
		display: flex;
		align-items: center;
		justify-content: center;
		padding: ${isSmall ? 0 : theme.spacing * 8}px;

		${overlay &&
		css`
			background-color: ${theme.palette.neutral.white};
			height: 100vh;
		`}
	`}
`

export const StyledSpinner = styled.svg<StyledSpinnerProps>`
	${({color}) => css`
		margin: 0 auto;
		path:nth-of-type(1) {
			animation: ${pulse(color)} 1s infinite linear;
		}

		path:nth-of-type(2) {
			animation: ${pulse(color)} 1s -0.083s infinite linear;
		}

		path:nth-of-type(3) {
			animation: ${pulse(color)} 1s -0.166s infinite linear;
		}

		path:nth-of-type(4) {
			animation: ${pulse(color)} 1s -0.249s infinite linear;
		}

		path:nth-of-type(5) {
			animation: ${pulse(color)} 1s -0.332s infinite linear;
		}

		path:nth-of-type(6) {
			animation: ${pulse(color)} 1s -0.415s infinite linear;
		}

		path:nth-of-type(7) {
			animation: ${pulse(color)} 1s -0.498s infinite linear;
		}

		path:nth-of-type(8) {
			animation: ${pulse(color)} 1s -0.581s infinite linear;
		}

		path:nth-of-type(9) {
			animation: ${pulse(color)} 1s -0.664s infinite linear;
		}

		path:nth-of-type(10) {
			animation: ${pulse(color)} 1s -0.747s infinite linear;
		}

		path:nth-of-type(11) {
			animation: ${pulse(color)} 1s -0.83s infinite linear;
		}

		path:nth-of-type(12) {
			animation: ${pulse(color)} 1s -0.913s infinite linear;
		}
	`}
`
