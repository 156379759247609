import React, {ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer, StyledContainerSpan, StyledReviewContainer} from '../styles.ts'
import Title from '@components/ui/Title'
import {useTranslation} from 'react-i18next'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {usePatientDataArray} from '@hooks/usePatientDataArray.tsx'
import {Form} from 'reactstrap'
import Drawer from '@components/commons/Drawer/index.tsx'
import {CreateCard, Patient, postCard} from '@services/patient.http.ts'
import dayjs from 'dayjs'
import {PHASES, USER_TYPES} from '@utilities/constants'
import CommonModal from '@components/commons/Modal'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const ReviewItem = ({title, step, value}: {title: string; step: ReactNode; value: ReactNode}) => {
	const {setGeneralPatientData} = useBoundStore()
	const {t} = useTranslation()

	const [isOpen, setIsOpen] = useState(false)

	return (
		<StyledReviewContainer>
			<div className="headerCta">
				<StyledContainerSpan>{title}</StyledContainerSpan>
				<span
					onClick={() => {
						setIsOpen(true)
						setGeneralPatientData('isInDrawer', true)
					}}
					className="editCta"
				>
					{t('commons:edit')}
				</span>
				<Drawer setIsOpen={setIsOpen} isOpen={isOpen}>
					<>{step}</>
				</Drawer>
			</div>
			{value}
		</StyledReviewContainer>
	)
}

const StepReview: React.FC = () => {
	const {t} = useTranslation()
	const {
		step,
		patient,
		userType,
		setClinicalInfoData,
		setPatientData,
		setGeneralPatientData,
		isInDrawer,
		triageStarted,
		whatHappened,
		howLong
	} = useBoundStore()
	const {
		otherSms,
		firstName,
		lastName,
		cf,
		birthDate,
		gender,
		birthPlace,
		residenceIsDomicile,
		residenceAddress,
		residenceCap,
		residenceCity,
		domicileCity,
		domicileAddress,
		domicileCap,
		birthNation,
		birthNiCity,
		residenceNation,
		residenceNiAddress,
		residenceNiCap,
		residenceNiCity,
		domicileNation,
		domicileNiAddress,
		domicileNiCity,
		domicileNiCap,
		phoneNumberPrefix,
		phoneNumber,
		sms,
		otherPhoneNumber,
		otherPhoneNumberPrefix,
		qualification,
		position,
		activity,
		workCondition,
		maritalStatus,
		isResident,
		isResidentDomicile
	} = patient

	const {setCommonsData, cardsData} = useCommonsStore()
	const reviewData = usePatientDataArray()

	const formRef = useRef<HTMLFormElement>(null)
	const [openModal, setOpenModal] = useState(false)
	const currentLanguage = localStorage.getItem('i18nextLng')

	useEffect(() => {
		setGeneralPatientData('isInReview', true)
	}, [])

	const [isLoading, setIsLoading] = useState(false)

	const handleSubmit = () => {
		if (isInDrawer) {
			return
		} else {
			handlePostCard()
		}
	}

	const countryCodes = useMemo(() => {
		return cardsData?.patient_data?.countries
	}, [cardsData])

	const italianCountry = countryCodes?.filter(i => i.code === 'IT')[0]
	const italianCountryId = italianCountry?.id || null

	const handlePostCard = async () => {
		const currentPatient: Patient = {
			fiscal_code: cf ? cf : null,
			foreign_code: null,
			first_name: firstName ? firstName : null,
			last_name: lastName ? lastName : null,
			birth_date: birthDate ? birthDate : null,
			gender: gender ? gender.id : null,
			birth_city: birthPlace ? +birthPlace?.value : null,

			residence_city: residenceCity ? +residenceCity?.value : null,
			residence_zip: residenceCap ? +residenceCap.value : null,
			domicile_city: residenceIsDomicile
				? residenceCity
					? +residenceCity?.value
					: null
				: domicileCity
				? +domicileCity?.value
				: null,
			domicile_zip: residenceIsDomicile
				? residenceCap
					? +residenceCap?.value
					: null
				: domicileCap
				? +domicileCap?.value
				: null,

			residence_address: residenceAddress ? residenceAddress : null,
			domicile_address: residenceIsDomicile
				? residenceAddress
					? residenceAddress
					: null
				: domicileAddress
				? domicileAddress
				: null,

			birth_country: isResident ? italianCountryId : birthNation ? +birthNation.value : null,
			residence_country: isResident ? italianCountryId : residenceNation ? +residenceNation?.value : null,
			domicile_country: isResidentDomicile ? italianCountryId : domicileNation ? +domicileNation?.value : null,

			foreign_birth_city: birthNiCity ? birthNiCity : null,
			foreign_residence_city: residenceNiCity ? residenceNiCity : null,
			foreign_residence_zip: residenceNiCap ? residenceNiCap : null,
			foreign_residence_address: residenceNiAddress ? residenceNiAddress : null,

			foreign_domicile_city: domicileNiCity ? domicileNiCity : null,
			foreign_domicile_zip: domicileNiCap ? domicileNiCap : null,
			foreign_domicile_address: domicileNiAddress ? domicileNiAddress : null,

			phone_number_prefix: phoneNumberPrefix?.label || null,
			phone_number: phoneNumber ? `${phoneNumber}` : null,
			phone_number_notification: sms || null,
			second_phone_number_prefix: otherSms === false ? null : otherPhoneNumberPrefix?.label || null,
			second_phone_number: otherPhoneNumber ? `${otherPhoneNumber}` : null,

			maritial_status: maritalStatus ? +maritalStatus.value : null,
			education: qualification ? +qualification.value : null,
			work_status: workCondition ? +workCondition.value : null,
			work_position: position ? +position?.value : null,
			work_activity: activity ? +activity?.value : null
		}
		const body: CreateCard = {
			accessreason: whatHappened ? +whatHappened.id : null,
			timing: howLong ? +howLong.id : null,
			patient: currentPatient,
			access_date: triageStarted
				? dayjs(triageStarted, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').format('YYYY-MM-DDTHH:mm:ssZ')
				: null,
			person: userType === USER_TYPES.patient ? 'Me' : 'Other',
			language: currentLanguage
		}
		setIsLoading(true)
		try {
			const {data} = await postCard(body)
			if (data) {
				setPatientData('patientId', data?.patient?.id)
				setPatientData('cardId', data?.id)
				setGeneralPatientData('isInReview', false)
				setClinicalInfoData('qrCode', data?.qrcode)
				setGeneralPatientData('step', step + 1)
				setCommonsData('userPhase', PHASES.clinicalInfo)
			}
		} catch (error) {
			setOpenModal(false)
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}

	const handleOutsideButtonClick = () => {
		if (formRef?.current) {
			formRef?.current.dispatchEvent(new Event('submit', {bubbles: true}))
		}
	}

	return (
		<>
			<StepContainer>
				<Title title={t(`${userType}:review:title`)} subTitle={t(`${userType}:review:subtitle`)} />
				<Form innerRef={formRef} onSubmit={handleSubmit}>
					{reviewData.map(i => {
						return <ReviewItem title={i.title} step={i.step} value={i.value} key={i.title} />
					})}
				</Form>
				<CommonModal
					title={t('commons:confirm_next') || ''}
					modal={openModal}
					setModal={setOpenModal}
					centered
					confirm={t('commons:confirm') || ''}
					isLoading={isLoading}
					onConfirm={handleOutsideButtonClick}
					cancel={t('commons:edit') || ''}
					onCancel={() => setOpenModal(false)}
				/>
			</StepContainer>
			<StepCta handleSubmit={() => setOpenModal(true)} isLoading={isLoading} />
		</>
	)
}

export default StepReview
