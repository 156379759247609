import styled from 'styled-components'

export const StyledFormGroup = styled.div`
	min-height: 72px;
	margin: 0 !important;

	input {
		text-transform: capitalize;
	}

	.was-validated .form-control:invalid,
	.form-control.is-invalid {
		background: url('data:image/svg+xml,<svg width="10" height="10" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22 7.9V16.2L16.1 22H7.9L2 16.2V7.9L7.9 2H16.1L22 7.9ZM11.5 5.7V14.2H12.7V5.7H11.5ZM12.6 18.3H11.4V16.5H12.6V18.3ZM15.7 3L21 8.3V15.8L15.7 21H8.3L3 15.8V8.3L8.3 3H15.7Z" fill="%23CC334D"/></svg>');
		background-size: 24px !important;
		background-position: right 10px center !important;
	}

	.was-validated .form-control:valid,
	.form-control.is-valid {
		background: none;
	}

	& label {
		font-size: 14px;
		font-weight: 600;
		padding-left: 4px;
	}
`
