export const palette = {
	light: {
		neutral: {
			white: '#FFFFFF',
			25: '#FCFCFD',
			50: '#F9FAFB',
			100: '#F2F4F7',
			200: '#EAECF0',
			300: '#D0D5DD',
			400: '#98A2B3',
			500: '#667085',
			600: '#475467',
			700: '#344054',
			800: '#1D2939',
			900: '#101828',
			950: '#0C111D',
			black: '#000000'
		},
		primary: {
			25: '#F2F7FC',
			50: '#EFF8FF',
			100: '#D1E9FF',
			200: '#B2DDFF',
			300: '#84CAFF',
			400: '#53B1FD',
			500: '#2E90FA',
			600: '#1570EF',
			700: '#175CD3',
			800: '#1849A4',
			900: '#194185',
			950: '#17324D'
		},
		secondary: {
			25: '#F5F5F5',
			50: '#B2B1B7',
			100: '#8A909E',
			200: '#6A707E',
			300: '#464A53',
			400: '#8A909E',
			500: '#6A707E',
			600: '#464A53',
			700: '#8A909E',
			800: '#6A707E',
			900: '#464A53',
			950: '#2D3138'
		},
		success: {
			25: '#F6FEF9',
			50: '#ECFDF3',
			100: '#DCFAE6',
			200: '#ABEFC6',
			300: '#75E0A7',
			400: '#47CD89',
			500: '#17B26A',
			600: '#079455',
			700: '#067647',
			800: '#085D3A',
			900: '#074D31',
			950: '#053321'
		},
		danger: {
			25: '#FFFBFA',
			50: '#FEF3F2',
			100: '#FEE4E2',
			200: '#FECDCA',
			300: '#FDA29B',
			400: '#F97066',
			500: '#F04438',
			600: '#CC334D',
			700: '#B42318',
			800: '#912018',
			900: '#7A271A',
			950: '#55160C'
		},
		warning: {
			25: '#FFFCF5',
			50: '#FFFAEB',
			100: '#FEF0C7',
			200: '#FEDF89',
			300: '#FEC84B',
			400: '#FDB022',
			500: '#F79009',
			600: '#DC6803',
			700: '#B54708',
			800: '#93370D',
			900: '#7A2E0E',
			950: '#4E1D09'
		}
	}
}
