import ButtonCustom from '@/components/ui/Button'
import React, {ReactNode} from 'react'
import {ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import Spinner from '@components/ui/Spinner'
import {StyledModal} from '@components/commons/Modal/styles.ts'

interface Props {
	title?: string
	children?: ReactNode
	modal: boolean
	setModal: (e: boolean) => void
	centered?: boolean
	confirm?: string
	onConfirm: () => void
	cancel?: string
	onCancel: () => void
	isLoading?: boolean
}

const CommonModal: React.FC<Props> = ({
	modal,
	setModal,
	title,
	children,
	centered,
	confirm,
	onConfirm,
	cancel,
	onCancel,
	isLoading
}) => {
	const toggle = () => {
		if (isLoading) return
		setModal(!modal)
	}

	return (
		<StyledModal isOpen={modal} toggle={toggle} centered={centered} scrollable={false} className="m-0 mx-auto">
			<ModalHeader>{title}</ModalHeader>
			<ModalBody>
				<div className={'d-flex flex-column gap-3'}>
					{children}
					{confirm && (
						<ButtonCustom color="primary" outline={false} type="button" onClick={onConfirm}>
							{isLoading ? <Spinner color={'#fff'} isSmall={true} size={24} /> : confirm}
						</ButtonCustom>
					)}
					{cancel && (
						<ButtonCustom color="primary" outline={true} type="button" onClick={onCancel}>
							{cancel}
						</ButtonCustom>
					)}
				</div>
			</ModalBody>
			<ModalFooter />
		</StyledModal>
	)
}

export default CommonModal
