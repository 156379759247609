import styled, {css, DefaultTheme, keyframes} from 'styled-components'

interface Props {
	theme: DefaultTheme
	blueWidth: number
}

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const slideInOutAnimation = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`

export const StyledContainerSubHeader = styled.div`
	${({theme, blueWidth}: Props) => css`
		padding: 12px 16px;
		max-height: 48px;
		align-items: center;
		position: relative;
		z-index: -1;
		& p {
			padding: 0;
		}

		& div {
			padding: 0;
			display: grid;
			grid-template-columns: 100px;

			& p {
				${theme.typography.textMd}
				font-weight: 600;
			}

			& progress {
				width: 100px;
			}
		}
		&::before {
			z-index: -1;
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: ${blueWidth}%;
			background: linear-gradient(to right, #bfdfff, #bfdfff);
			transition: width 0.5s ease-in-out;
			height: 55px;
		}
	`}
`

export const StyledNegativeProgress = styled.div`
	${({theme}) => css`
		position: absolute;
		z-index: -1;
		width: 100%;
		left: 0;
		top: 47px;
		height: 125px;
		overflow: hidden;
		border-radius: 8px 8px 0 0;
		background: ${theme.palette.neutral.white};
		border: 1px solid ${theme.palette.neutral['300']};
		border-bottom: 0;
	`}
`

export const StyledContainerRightItem = styled.span`
	${({theme}) => css`
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${theme.spacing * 4}px;
	`}
`

export const StyledContainerQRIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
`

type StyledOverlayProps = {
	open: boolean
	theme: DefaultTheme
}

export const StyledOverlay = styled.div`
	${({theme, open}: StyledOverlayProps) => css`
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${theme.palette.neutral.white};
		z-index: 1;
		opacity: ${open ? 1 : 0};
		transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
		transform: translateY(${open ? 0 : '-100%'});
		animation: ${slideInOutAnimation} 0.5s ease-in-out;
		animation-direction: ${open ? 'normal' : 'reverse'};
	`}
`

export const StyledTooltip = styled.span`
	${({theme}) => css`
		position: absolute;
		display: inline-flex;
		gap: ${theme.spacing * 2}px;
		right: 16px;
		top: 115px;
		width: 310px;
		background: ${theme.palette.primary[950]};
		color: ${theme.palette.neutral.white};
		border-radius: ${theme.spacing}px;
		padding: ${theme.spacing * 4}px;
		animation: ${fadeAnimation} 0.5s linear;
		opacity: 1;

		&::before {
			content: '';
			position: absolute;
			bottom: 85%;
			left: 95%;
			width: 24px;
			height: 24px;
			transform: rotate(45deg);
			margin-left: -12px;
			border-width: 5px;
			border-style: solid;
			border-color: ${theme.palette.primary[950]};
			background: ${theme.palette.primary[950]};
			border-radius: ${theme.spacing}px;
		}

		& svg {
			fill: ${theme.palette.neutral.white};
		}

		& p {
			${theme.typography.textSm}
			font-weight: 400;
			line-height: 24px;
		}
	`}
`
