import {QueryClient} from '@tanstack/react-query'

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			networkMode: 'online',
			retry: 1,
			retryOnMount: true,
			retryDelay: 1000,
			staleTime: 600000, //10 min
			refetchOnMount: true,
			refetchOnWindowFocus: false,
			refetchOnReconnect: true
		}
	}
})
