import {FC, ReactNode} from 'react'
import {CardBody} from 'reactstrap'
import {
	StyledCard,
	StyledCardText,
	StyledCardTitle,
	StyledContainerCardImage
} from '@components/commons/Carousel/CaroselCard/style.ts'

interface CarouselCardProps {
	img?: string
	cardTitle?: string
	cardText?: string
	action?: ReactNode
}

const CarouselCard: FC<CarouselCardProps> = ({img, cardTitle, cardText, action}) => {
	return (
		<StyledCard>
			{img && <StyledContainerCardImage url={img} />}
			<CardBody>
				<StyledCardTitle className={'text-primary'}>{cardTitle}</StyledCardTitle>

				<StyledCardText className={'text-muted'}>{cardText}</StyledCardText>
				{action}
			</CardBody>
		</StyledCard>
	)
}

export default CarouselCard
