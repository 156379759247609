import {ChangeEvent, FC, InputHTMLAttributes, useLayoutEffect, useRef} from 'react'
import {DefaultTFuncReturn} from 'i18next'
import {FormFeedback, FormText, Input, Label} from 'reactstrap'
import {StyledFormGroup} from './styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string | DefaultTFuncReturn
	name?: string
	type: 'text' | 'number' | 'textarea' | 'date'
	value?: string | number
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void
	/**
	 * how to use errorMessage with translations
	 *  errorMessage={t(errors.text?.message || '')} this avoid undefined value problems
	 */
	message?: string | DefaultTFuncReturn
	helpText?: string | DefaultTFuncReturn
	placeholder?: string
	valid?: boolean
	invalid?: boolean
	autoFocus?: boolean
}

const InputText: FC<InputProps> = ({
	label,
	onChange,
	name,
	type,
	placeholder,
	value,
	valid,
	helpText,
	message,
	invalid,
	autoFocus,
	...rest
}) => {
	const inputRef = useRef<HTMLInputElement>(null)
	const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent)

	useLayoutEffect(() => {
		const handleFocus = () => {
			if (inputRef.current && autoFocus) {
				const inputElement = inputRef.current as HTMLInputElement | undefined
				inputElement?.focus()
			}
		}

		if (autoFocus) {
			if (isIOS()) {
				handleFocus()
			} else {
				setTimeout(() => {
					handleFocus()
				}, 300)
			}
		}
	}, [autoFocus])

	return (
		<StyledFormGroup>
			<Label htmlFor={name} for={name} className={value ? 'custom-label visible' : 'invisible'}>
				{label}
			</Label>
			<Input
				id={name}
				name={name}
				placeholder={placeholder}
				type={type}
				onChange={onChange}
				value={value || ''}
				valid={valid}
				invalid={invalid}
				autoComplete={'off'}
				innerRef={inputRef}
				{...rest}
			/>

			<FormFeedback className={'ms-0'} valid={valid}>
				{message}
			</FormFeedback>
			<FormText>{helpText}</FormText>
		</StyledFormGroup>
	)
}

export default InputText
