import {useState} from 'react'
import {StyledContainerDataLanding, StyledContainerDropdown, StyledLandingContainer} from './styles'
import {StyledHeaderWrapperLight} from '@components/commons/Header/style.ts'
import {LANGUAGES} from '@utilities/constants'
import Dropdown from '@components/ui/Dropdown'
import {useTranslation} from 'react-i18next'
import {ColorfulLogoIcon} from '@assets/icons/icons.tsx'
import QRCode from 'react-qr-code'

const Landing = () => {
	const {t, i18n} = useTranslation()

	const defaultLanguage = localStorage.getItem('i18nextLng')
		? localStorage.getItem('i18nextLng')
		: navigator.language.slice(0, 2)

	const [language, setLanguage] = useState(defaultLanguage)

	const handleChangeLanguage = (value: string) => {
		setLanguage(value)
		i18n?.changeLanguage(value)
	}

	return (
		<StyledLandingContainer>
			<StyledHeaderWrapperLight>
				<StyledContainerDropdown>
					<Dropdown
						className={'text-decoration-underline text-primary bg-white'}
						dropdownItems={LANGUAGES}
						onClickItems={value => handleChangeLanguage(value)}
						stringToShow={language?.toUpperCase()}
					/>
				</StyledContainerDropdown>
			</StyledHeaderWrapperLight>

			<StyledContainerDataLanding>
				<div className={'d-flex align-items-center justify-content-center gap-2'}>
					<ColorfulLogoIcon />
					<p className={'fw-600'}>{t('commons:landing:icon_text')}</p>
				</div>

				<div className={'d-flex flex-column align-items-center justify-content-center gap-3'}>
					<h2>{t('commons:landing:title')}</h2>
					<p className={'text-muted text-center'}>{t('commons:landing:subtitle')}</p>
				</div>

				<div className={'d-flex flex-column align-items-center justify-content-center gap-2'}>
					<small>{t('commons:landing:qr_code')}</small>

					<div className={'mb-3 p-1'}>
						<QRCode value={'https://triage-app.k-stage.dev/'} size={146} />
					</div>

					<small dangerouslySetInnerHTML={{__html: t('commons:landing:link') as TrustedHTML}} />
				</div>
			</StyledContainerDataLanding>
		</StyledLandingContainer>
	)
}

export default Landing
