import {StyledFooter} from '@components/ui/Footer/style.ts'
import {FC, ReactNode} from 'react'

interface FooterProps {
	children?: ReactNode
}
const Footer: FC<FooterProps> = ({children}) => {
	return <StyledFooter>{children}</StyledFooter>
}

export default Footer
