import styled, {css, DefaultTheme} from 'styled-components'

type ContainerTitleProps = {
	theme: DefaultTheme
	isCentered?: boolean
}
export const StyledContainerTitle = styled.div`
	${({theme, isCentered}: ContainerTitleProps) => css`
		display: flex;
		align-items: center;
		gap: ${theme.spacing * 2}px;
		margin-top: 32px;
		padding: 0 16px;
		text-align: ${isCentered ? 'center' : 'left'};
		& p,
		h3,
		span {
			padding: 0;
		}

		& span {
			${theme.typography.textLg}
		}
	`}
`
