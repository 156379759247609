import {useNavigate} from 'react-router-dom'
import React from 'react'
import {StyledContainerButtonNotFound, StyledContainerNotFound, StyledContainerP} from './styles'
import ButtonCustom from '@/components/ui/Button'
import {useTranslation} from 'react-i18next'
import {ColorfulLogoIcon} from '@assets/icons/icons.tsx'

const NotFound: React.FC = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	return (
		<StyledContainerNotFound>
			<div className={'d-flex align-items-center justify-content-center gap-2 pb-4'}>
				<ColorfulLogoIcon />
				<p className={'fw-600'}>{t('commons:landing:icon_text')}</p>
			</div>

			<h1>{t('not_found:page_not_found')}</h1>

			<StyledContainerP>
				<p>{t('not_found:page_not_exist')}</p>
			</StyledContainerP>

			<StyledContainerButtonNotFound>
				<ButtonCustom type="button" onClick={() => navigate('/')}>
					{t('not_found:back_home')}
				</ButtonCustom>
			</StyledContainerButtonNotFound>
		</StyledContainerNotFound>
	)
}

export default NotFound
