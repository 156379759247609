import {StateCreator} from 'zustand'
import {SymptomsAction, SymptomsDataState} from '@/types/symptomType.ts'

const initialState = {
	symptoms: [],
	notes: undefined,
	selectedSymptoms: []
}

export const createSymptomsDataSlice: StateCreator<SymptomsDataState & SymptomsAction> = set => ({
	...initialState,
	setSymptomsData: (key, value) => set(() => ({[key]: value}))
})
