import styled, {css, DefaultTheme} from 'styled-components'
import {ReactNode} from 'react'

interface CardProps {
	theme: DefaultTheme
	icon?: boolean | ReactNode
	withBorder?: boolean
	isAnimated?: boolean
	havePadding?: boolean
	isSelected?: boolean
}

export const StyledCardOptions = styled.div`
	${({theme, icon, withBorder, isAnimated, havePadding, isSelected}: CardProps) => css`
		display: grid;
		grid-template-columns: ${icon ? 'auto 1fr' : '1fr'};
		gap: ${withBorder ? theme.spacing * 2 : theme.spacing * 3}px;

		.title {
			font-weight: 600;
		}
		&:active {
			${isAnimated &&
			css`
				transform: scale(0.97);
				transition: transform 0.3s ease;
			`}
		}

		${!icon &&
		css`
			gap: 0;
		`}

		${havePadding
			? css`
					padding: ${theme.spacing * 3}px ${theme.spacing * 4}px;
			  `
			: css`
					padding: ${theme.spacing * 4}px 0;
			  `}
				${isSelected &&
		css`
			background: #f2f7fc;
			border: 2px solid #0066cc !important;
			color: #0066cc;
		`}
	`}
`
export const StyledCardInfoDivider = styled.div`
	${({theme}: CardProps) => css`
		height: 100%;
		background: ${theme.palette.neutral[300]};
		width: 2px;
		margin: ${theme.spacing * 2}px auto 0;
	`}
`

export const StyledContainerChip = styled.div`
	${({theme}) => css`
		background: ${theme.palette.neutral['500']};
		color: ${theme.palette.neutral.white};

		height: 24px;
		padding: ${theme.spacing}px ${theme.spacing * 4}px;
		border-radius: ${theme.spacing}px;
		display: flex;
		align-items: center;
		justify-content: center;
		& p {
			${theme.typography.textSm};
		}
	`}
`
