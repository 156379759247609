import {StyledSmall, StyledSmallError} from '@components/ui/Select/InputHelpText/style.ts'
import {DefaultTFuncReturn} from 'i18next'

interface InputHelpTextProps {
	errorMessage?: string | DefaultTFuncReturn
	helpText?: string | DefaultTFuncReturn
	invalid?: boolean
}
const InputHelpText = ({errorMessage, helpText, invalid}: InputHelpTextProps) => {
	return (
		<>
			{invalid && <StyledSmallError>{errorMessage}</StyledSmallError>}
			{helpText && !invalid && <StyledSmall>{helpText}</StyledSmall>}
		</>
	)
}

export default InputHelpText

InputHelpText.displayName = 'InputHelpText'
