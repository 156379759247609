import {
	StyledCheckmark,
	StyledCheckmarkCheck,
	StyledCheckmarkCircle,
	StyledCheckmarkWrapper
} from '@components/commons/IconsStepsStatus/styles.ts'

const AnimatedCheckmark = () => {
	return (
		<StyledCheckmarkWrapper>
			<StyledCheckmark viewBox="0 0 52 52">
				<StyledCheckmarkCircle cx="26" cy="26" r="25" fill="none" />
				<StyledCheckmarkCheck fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
			</StyledCheckmark>
		</StyledCheckmarkWrapper>
	)
}

export default AnimatedCheckmark
