import {StepContainer, StyledButtonStart} from '@pages/onBoarding/styles.ts'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {useBoundStore, useCommonsStore} from '@store/boundStore.ts'
import {StyledP} from '@pages/feedback/styles.ts'
import {useMemo, useState} from 'react'
import {routes} from '@utilities/constants'
import Footer from '@components/ui/Footer'
import {FeedBackSymptom, putSymptoms} from '@/services/patient.http'
import BackButton from '@components/commons/ButtonTypes/BackButton.tsx'
import Radio from '@components/ui/Radio'
import {AxiosError, handleGenericError} from '@utilities/helpers.ts'

const Feedback = () => {
	const {t} = useTranslation()
	const navigate = useNavigate()
	const {userType, selectedSymptoms, symptoms, patient, qrCode} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()

	const dataList = useMemo(() => {
		return cardsData?.symptoms_data?.feedback
	}, [cardsData])

	const [isLoading, setIsLoading] = useState(false)
	const [checkedStates, setCheckedStates] = useState<(number | null)[]>(selectedSymptoms.map(() => null))

	const handlePutSymptoms = async () => {
		const currentSymptoms = symptoms?.map((item, index) => {
			const {symptom, pain, intensity, bodyFront, symptomId} = item
			return {
				id: symptomId ? symptomId : null,
				card: patient?.cardId ? +patient.cardId : null,
				symptom: symptom?.value ? +symptom.value : null,
				zone: bodyFront ? bodyFront : null,
				pain_intensity: intensity ? intensity : 1,
				pain_type: pain ? +pain : null,
				symptom_feedback: checkedStates[index] || null
			}
		})

		const body: FeedBackSymptom[] = currentSymptoms.length ? currentSymptoms : []
		setIsLoading(true)
		try {
			const {data} = await putSymptoms(body)
			if (data) {
				setCommonsData('isNextAnimation', true)
				if (qrCode) {
					navigate(routes.CODEASSIGNED.path.replace(':id', qrCode))
				}

				setCommonsData('showToast', {
					isVisible: true,
					text: t(`commons:info:thanks`) || '',
					status: 'success'
				})
			}
		} catch (error) {
			handleGenericError({
				error: error as AxiosError,
				setCommonsData,
				t
			})
		} finally {
			setIsLoading(false)
		}
	}
	const handleRadioChange = (index: number, radioIndex: number) => {
		setCheckedStates(prevStates => {
			const newStates = [...prevStates]
			newStates[index] = radioIndex
			return newStates
		})
		const nextElement = document.getElementById(`radio_${index + 1}_${index + 1}`)
		if (nextElement) {
			nextElement.scrollIntoView({behavior: 'smooth'})
		}
	}

	return (
		<>
			<div className={'flex-column d-flex justify-content-start align-items-start p-3 pt-4 bg-white'}>
				<BackButton
					text={'commons:back'}
					onClick={() => {
						setCommonsData('isNextAnimation', false)
						navigate(-1)
					}}
					className={'bg-white'}
				/>
			</div>
			<StepContainer className={'h-100 rounded-0 border-0'}>
				<div className={'flex-column d-flex justify-content-start align-items-start pt-2'}>
					<div className={'d-flex flex-column gap-2'}>
						<h3>{t(`${userType}:waiting:howIsItGoing:title`)}</h3>
						<StyledP>{t(`${userType}:waiting:howIsItGoing:subtitle`)}</StyledP>
					</div>
				</div>

				{selectedSymptoms?.map((symptom, index) => {
					return (
						<div key={index} className={index !== selectedSymptoms.length - 1 ? 'border-bottom' : ''}>
							<h5 className={'fw-600 pb-3'}>{symptom?.label}</h5>

							{dataList && dataList?.length > 0 && (
								<div className={'d-flex flex-column fw-600'}>
									<Radio
										label={dataList[0]?.name || ''}
										name={`radio_${index}_1`}
										onChange={() => handleRadioChange(index, 0)}
										check={checkedStates[index] === 0}
										className={'m-0 pb-3 pt-3'}
									/>
									<Radio
										label={dataList[1].name || ''}
										name={`radio_${index}_2`}
										onChange={() => handleRadioChange(index, 1)}
										check={checkedStates[index] === 1}
										className={'m-0 pb-3 pt-3'}
									/>
									<Radio
										label={dataList[2].name || ''}
										name={`radio_${index}_3`}
										onChange={() => handleRadioChange(index, 2)}
										check={checkedStates[index] === 2}
										className={'m-0 pb-3 pt-3'}
									/>
								</div>
							)}
						</div>
					)
				})}
			</StepContainer>

			<Footer>
				<StyledButtonStart color="primary" outline={false} type="button" onClick={handlePutSymptoms}>
					<p>{isLoading ? t(`commons:loading`) : t(`commons:confirm`)}</p>
				</StyledButtonStart>
			</Footer>
		</>
	)
}

export default Feedback
