import {useTranslation} from 'react-i18next'
import ButtonCustom from '@components/ui/Button'
import Spinner from '@components/ui/Spinner'

interface NextButtonProps {
	onClick?: () => void
	disabled?: boolean
	text?: string
	className?: string
	isLoading?: boolean
}

const NextButton = ({onClick, disabled, text = 'commons:next', className, isLoading}: NextButtonProps) => {
	const {t} = useTranslation()
	return (
		<ButtonCustom
			color="primary"
			outline={false}
			type="button"
			onClick={onClick}
			disabled={disabled}
			className={className}
		>
			{isLoading ? <Spinner color={'#fff'} isSmall={true} size={24} /> : t(text)}
		</ButtonCustom>
	)
}

export default NextButton
