import Select, {SelectValue} from '@components/ui/Select'
import {useTranslation} from 'react-i18next'
import {FC, useEffect, useMemo} from 'react'
import {City} from '@/types/commonType'

interface CapInputProps {
	setSelectValue: (newValue: SelectValue) => void
	selectValue: SelectValue | undefined
	setInputValue: (newValue: SelectValue) => void
	selectOptions: SelectValue[] | undefined
	capNumber: SelectValue | undefined
	selectLabel: string
	selectPlaceholder: string
	cities: City[]
	invalidCity: boolean
	invalidCap: boolean
	errorMessage: string
}

const CapInput: FC<CapInputProps> = ({
	selectOptions,
	setSelectValue,
	selectValue,
	setInputValue,
	capNumber,
	selectLabel,
	selectPlaceholder,
	cities,
	invalidCity,
	invalidCap,
	errorMessage
}) => {
	const {t} = useTranslation()

	const cityCap = useMemo(() => {
		if (selectValue) {
			const result = cities
				?.find(i => i.name === selectValue?.label)
				?.zips.map(i => {
					return {
						label: i.value,
						value: i.id.toString()
					}
				})
			return result
		}
	}, [selectValue])

	useEffect(() => {
		if (!capNumber && cityCap) {
			setInputValue(cityCap[0])
		}
	}, [capNumber, cityCap])

	return (
		<div className={'row'}>
			<div className={'col-8'}>
				<Select
					options={selectOptions || []}
					label={selectLabel}
					placeholder={selectPlaceholder}
					onChange={newValue => {
						setSelectValue(newValue as SelectValue)
					}}
					value={selectValue}
					disabled={!selectOptions?.length}
					invalid={invalidCity}
					errorMessage={errorMessage}
				/>
			</div>
			<div className={'col-4'}>
				<Select
					options={cityCap || []}
					label={t('commons:form:postal_code')}
					placeholder={t('commons:form:postal_code')}
					onChange={newValue => {
						setInputValue(newValue as SelectValue)
					}}
					value={capNumber}
					isSearchable={cityCap && cityCap?.length > 1}
					disabled={!cityCap?.length}
					invalid={invalidCap}
					errorMessage={errorMessage}
					onKeyDown={e => {
						if (e.key !== 'Backspace' && e.key !== 'Delete' && isNaN(Number(e.key))) {
							e.preventDefault()
						}
					}}
				/>
			</div>
		</div>
	)
}

export default CapInput
