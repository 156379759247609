import React, {useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import Title from '@components/ui/Title'
import CheckboxCustom from '@/components/ui/Checkbox'
import {useTranslation} from 'react-i18next'
import {Form} from 'reactstrap'
import PhoneInput from '@components/commons/PhoneInput'
import CommonModal from '@components/commons/Modal/index.tsx'
import {SelectValue} from '@components/ui/Select'

const StepPhone: React.FC = () => {
	const {t} = useTranslation()
	const {step, patient, setPatientData, userType, isInReview, setGeneralPatientData} = useBoundStore()
	const {phoneNumberPrefix, phoneNumber, sms} = patient
	const {setCommonsData} = useCommonsStore()

	const formRef = useRef<HTMLFormElement>(null)
	const [modal, setModal] = useState(false)
	const phoneNumberIsValid = phoneNumber && phoneNumber?.toString().length >= 10
	const [isInvalid, setIsInvalid] = useState(false)

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()
		if (phoneNumberIsValid) {
			setModal(true)
			setIsInvalid(false)
		} else {
			setIsInvalid(true)
		}
	}

	const handleConfirm = () => {
		if (isInReview) {
			setGeneralPatientData('isInDrawer', false)
			setGeneralPatientData('step', 12)
		} else {
			setCommonsData('isNextAnimation', true)
			setGeneralPatientData('step', step + 1)
		}
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title title={t(`${userType}:patientData:phoneNumber:title`)} />
				<Form innerRef={formRef} onSubmit={handleSubmit} className={'h-100'}>
					<PhoneInput
						setPhonePrefix={(newValue: SelectValue) => setPatientData('phoneNumberPrefix', newValue)}
						prefix={phoneNumberPrefix}
						setPhoneNumber={(newValue: number) => {
							setPatientData('phoneNumber', newValue)
							setIsInvalid(false)
						}}
						phoneNumber={phoneNumber}
						invalid={isInvalid}
						autoFocus={!isInReview}
					/>
					<CheckboxCustom
						check={sms}
						onChange={() => setPatientData('sms', !sms)}
						name={'sms'}
						label={t(`${userType}:patientData:phoneNumber:checkbox`)}
					/>
				</Form>
			</StepContainer>
			<CommonModal
				title={t(`${userType}:patientData:phoneNumber:phone`) || ''}
				modal={modal}
				setModal={setModal}
				centered
				confirm={t('commons:confirmPhone') || ''}
				onConfirm={handleConfirm}
				cancel={t('commons:edit') || ''}
				onCancel={() => setModal(false)}
			>
				<h5>
					{phoneNumberPrefix?.label} {phoneNumber}
				</h5>
				<p>
					{sms
						? t(`${userType}:patientData:phoneNumber:subtitleUpdate`)
						: t(`${userType}:patientData:phoneNumber:subtitle`)}
				</p>
			</CommonModal>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepPhone
