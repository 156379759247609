import styled, {css} from 'styled-components'

export const StyledFooter = styled.footer`
	${({theme}) => css`
		min-height: 80px;
		height: 80px;
		width: 100%;
		box-shadow: 0 16px 48px 0 rgba(0, 0, 0, 0.15);
		padding: ${theme.spacing * 4}px;
		background: ${theme.palette.neutral.white};
		position: sticky;
		bottom: 0;
		@media screen and (max-height: 600px) {
			footer {
				position: initial; /* cambia la posizione iniziale per dispositivi con altezza dello schermo inferiore a 600px */
			}
		}
	`}
`
