import styled, {css} from 'styled-components'

export const StyledContainerCorrectBanner = styled.div`
	${({theme}) => css`
		position: absolute;
		top: 30%;
		left: 50%;
		transform: translate(-50%, -30%);
		width: 100%;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: ${theme.spacing * 6}px;
	`}
`
export const StyledContainerResult = styled.div`
	${({theme}) => css`
		display: flex;
		padding: ${theme.spacing * 3}px;
		justify-content: center;
		align-items: center;

		border-radius: ${theme.spacing}px;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);

		h1 {
			font-size: 28px;
			font-weight: 600;
			line-height: 32px;
			letter-spacing: 2px;
		}

		span {
			${theme.typography.textMd};
			font-weight: 400;
		}
	`}
`

export const StyledScannerContainer = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;

	& video {
		max-width: 375px;
	}

	& canvas {
		position: absolute;
		max-width: 375px;
	}
`
