import {z} from 'zod'

const boolEnvvar = z
	.string()
	.optional()
	.transform(s => s == 'true')

export const envVars = z
	.object({
		VITE_APP_TITLE: z.string().optional(),
		VITE_API_ENDPOINT: z.string().url(),
		IS_SENTRY_ENABLED: boolEnvvar,
		SENTRY_ORG: z.string().email().optional(),
		SENTRY_PROJ: z.string().optional(),
		SENTRY_AUTH: z.string().optional()
	})
	.parse(import.meta.env)
