import {css} from 'styled-components'
const defaultFontFamily = `'Titillium Web', sans-serif`

export const typography = {
	display2xl: css`
		font-family: ${defaultFontFamily};
		font-size: 4.5rem; /* 72px; */
		line-height: 90px;
		letter-spacing: -0.02em;
	`,
	displayXl: css`
		font-family: ${defaultFontFamily};
		font-size: 3.75rem; /* 60px */
		line-height: 72px;
		letter-spacing: -0.02em;
	`,
	displayLg: css`
		font-family: ${defaultFontFamily};
		font-size: 3rem; /* 48px */
		line-height: 60px;
		letter-spacing: -0.02em;
	`,
	displayMd: css`
		font-family: ${defaultFontFamily};
		font-size: 2.25rem; /* 36px */
		line-height: 44px;
		letter-spacing: -0.02em;
	`,
	displaySm: css`
		font-family: ${defaultFontFamily};
		font-size: 2rem; /* 32px */
		line-height: 38px;
	`,
	displayXs: css`
		font-family: ${defaultFontFamily};
		font-size: 1.5rem; /* 24px */
		line-height: 32px;
	`,
	textXl: css`
		font-family: ${defaultFontFamily};
		font-size: 1.25rem; /* 20px */
		line-height: 30px;
	`,
	textLg: css`
		font-family: ${defaultFontFamily};
		font-size: 1.125rem; /* 18px */
		line-height: 28px;
	`,
	textMd: css`
		font-family: ${defaultFontFamily};
		font-size: 1rem; /* 16px */
		line-height: 24px;
	`,
	textSm: css`
		font-family: ${defaultFontFamily};
		font-size: 0.875rem; /* 14px */
		line-height: 20px;
	`,
	textXs: css`
		font-family: ${defaultFontFamily};
		font-size: 0.75rem; /* 12px */
		line-height: 18px;
	`
}
