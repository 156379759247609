import styled, {css, DefaultTheme} from 'styled-components'

export const StyledInputRangeContainer = styled.input`
	${({theme}) => css`
		height: 24px;
		background: linear-gradient(90deg, #5ebfcc 0%, #5ecc8c 25%, #f19e38 50%, #e44f5e 75%, #6d4fe4 100%);
		border-radius: 50px;
		width: 100%;

		&::-webkit-slider-thumb {
			-webkit-appearance: none;
			appearance: none;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			cursor: pointer;
			box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			border: 3px solid ${theme.palette.neutral.white};
			background-color: transparent;
		}
	`}
`

export const StyledLabel = styled.label`
	${({theme}) => css`
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: ${theme.spacing * 2}px;
	`}
`

interface StyledTooltipProps {
	tooltipPosition: number
	theme: DefaultTheme
	value: number
}
export const StyledTooltip = styled.div`
	${({theme, tooltipPosition, value}: StyledTooltipProps) => css`
		position: absolute;
		top: -55px;
		left: calc(${tooltipPosition}%);
		transform: translateX(-50%);
		background-color: ${theme.palette.neutral.white};
		color: ${theme.palette.neutral.black};
		padding: ${theme.spacing * 3}px ${theme.spacing * 4}px;
		border-radius: ${theme.spacing}px;
		pointer-events: none;
		transition: opacity 0.3s ease-in-out;
		z-index: 10;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

		&::before {
			content: '';
			position: absolute;
			top: 100%;
			left: ${value !== 1 ? `calc(${tooltipPosition}%)` : '16%'};
			transform: translateX(-50%);
			border-width: ${theme.spacing * 2}px;
			border-style: solid;
			border-color: ${theme.palette.neutral.white} transparent transparent transparent;
		}

		&.visible {
			opacity: 1;
		}

		& p {
			${theme.typography.textSm}
			font-weight: 600;
		}
	`}
`
