import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react'
import {StyledInputRangeContainer, StyledLabel, StyledTooltip} from '@components/commons/IntensityProgress/style.ts'
import {useTranslation} from 'react-i18next'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'

interface Props {
	handleIntensity: (e: number) => void
	defaultValue?: number
}

export interface TooltipLabels {
	[key: number]: string
}

export const getLocalizedTooltipLabels = (t: (key: string) => string): TooltipLabels => {
	return {
		1: t('commons:info:range_symptoms:mild'),
		2: t('commons:info:range_symptoms:moderate'),
		3: t('commons:info:range_symptoms:medium'),
		4: t('commons:info:range_symptoms:severe'),
		5: t('commons:info:range_symptoms:unbearable')
	}
}
const IntensityProgress: FC<Props> = ({handleIntensity, defaultValue}) => {
	const {t} = useTranslation()
	const {cardsData} = useCommonsStore()

	const painIntensity = useMemo(() => {
		return cardsData?.symptoms_data?.pain_intensity
	}, [cardsData])

	const rangeNumber = painIntensity?.map(i => i?.value) || [1, 2, 3, 4, 5]
	const tooltipLabels = painIntensity?.map(i => i?.name) || getLocalizedTooltipLabels(t)

	const [tooltipText, setTooltipText] = useState(tooltipLabels[0])
	const [value, setValue] = useState<number>(1)

	const {step} = useBoundStore()

	useEffect(() => {
		setValue(1)
		setTooltipText(tooltipLabels[0])
	}, [step])

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(event.target.value, 10)
		setValue(newValue)
		setTooltipText(tooltipLabels[newValue - 1])
		handleIntensity(newValue)
	}

	useEffect(() => {
		if (defaultValue) {
			setValue(defaultValue)
			setTooltipText(tooltipLabels[defaultValue - 1])
			handleIntensity(defaultValue)
		}
	}, [defaultValue])

	const calculateTooltipPosition = useMemo(() => {
		const positionMapping: Record<number, number> = {
			1: 10,
			2: 32,
			3: 50,
			4: 70,
			5: 85
		}
		const position = defaultValue ? defaultValue : value
		return positionMapping[position]
	}, [defaultValue, value])

	return (
		<div className="position-relative mt-5">
			<StyledInputRangeContainer
				type="range"
				min="1"
				max="5"
				onChange={handleInputChange}
				value={defaultValue || 0}
			/>
			<StyledTooltip tooltipPosition={calculateTooltipPosition} value={value}>
				<p>{tooltipText}</p>
			</StyledTooltip>
			<StyledLabel>
				{rangeNumber.map(number => (
					<p key={number}>{number}</p>
				))}
			</StyledLabel>
		</div>
	)
}

export default IntensityProgress
