import React, {useEffect, useMemo, useRef, useState} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import Title from '@components/ui/Title'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {useTranslation} from 'react-i18next'
import Select, {SelectValue} from '@components/ui/Select'
import {Form} from 'reactstrap'
import {WORK_TYPES} from '@/utilities/constants/utils.ts'
import {remapSelectOptions} from '@utilities/helpers.ts'
import {Patient} from '@/types/patientType.ts'

const StepOtherInfo: React.FC = () => {
	const {t} = useTranslation()
	const {step, patient, userType, setGeneralPatientData, isInReview, setPatientData} = useBoundStore()
	const {qualification, workCondition, position, activity, maritalStatus} = patient
	const {cardsData, setCommonsData} = useCommonsStore()

	const memoizedData = useMemo(() => {
		const maritialStatus = cardsData?.patient_data?.maritial_status
		const qualificationArray = cardsData?.patient_data?.professional_data?.education
		const workConditionArray = cardsData?.patient_data?.professional_data?.work_status
		const positionArray = cardsData?.patient_data?.professional_data?.work_position
		const workActivityArray = cardsData?.patient_data?.professional_data?.work_activity

		return {
			maritialStatus,
			qualificationArray,
			workConditionArray,
			positionArray,
			workActivityArray
		}
	}, [cardsData])

	const {maritialStatus, qualificationArray, workConditionArray, positionArray, workActivityArray} = memoizedData

	const formRef = useRef<HTMLFormElement>(null)

	const [invalidForm, setInvalidForm] = useState({
		maritalStatus: false,
		qualification: false,
		workCondition: false
	})

	useEffect(() => {
		if (workCondition && workCondition?.label !== WORK_TYPES.employed) {
			setPatientData('position', undefined)
			setPatientData('activity', undefined)
		}
	}, [workCondition])

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault()

		const hasRequiredFields = maritalStatus && qualification && workCondition

		if (hasRequiredFields) {
			if (isInReview) {
				setGeneralPatientData('isInDrawer', false)
			}
			setCommonsData('isNextAnimation', true)
			setGeneralPatientData('step', step + 1)
		} else {
			setInvalidForm({
				maritalStatus: !maritalStatus,
				qualification: !qualification,
				workCondition: !workCondition
			})
		}
	}

	const handleSelectChange = (newValue: SelectValue, inputType: keyof Patient) => {
		setPatientData(inputType, newValue)
		setInvalidForm({...invalidForm, [inputType]: false})
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title title={t(`${userType}:patientData:professional_data:title`)} />
				<Form innerRef={formRef} onSubmit={handleSubmit} className={'gap-4 h-100'}>
					<Select
						options={remapSelectOptions(maritialStatus)}
						label={t('commons:form:marital_status')}
						placeholder={t('commons:form:placeholder:marital_status')}
						onChange={newValue => {
							handleSelectChange(newValue as SelectValue, 'maritalStatus')
						}}
						value={maritalStatus}
						invalid={invalidForm.maritalStatus}
						errorMessage={t('errors:mandatory_field')}
					/>
					<Select
						options={remapSelectOptions(qualificationArray)}
						label={t('commons:form:qualification')}
						placeholder={t('commons:form:placeholder:qualification')}
						onChange={newValue => {
							handleSelectChange(newValue as SelectValue, 'qualification')
						}}
						value={qualification}
						invalid={invalidForm.qualification}
						errorMessage={t('errors:mandatory_field')}
					/>
					<Select
						options={remapSelectOptions(workConditionArray)}
						label={t('commons:form:work_situation')}
						placeholder={t('commons:form:placeholder:work_situation')}
						onChange={newValue => {
							handleSelectChange(newValue as SelectValue, 'workCondition')
						}}
						value={workCondition}
						invalid={invalidForm.workCondition}
						errorMessage={t('errors:mandatory_field')}
					/>
					{workCondition && workCondition?.label === WORK_TYPES.employed && (
						<>
							<Select
								options={remapSelectOptions(positionArray)}
								label={t('commons:form:position')}
								placeholder={t('commons:form:placeholder:position')}
								onChange={newValue => {
									handleSelectChange(newValue as SelectValue, 'position')
								}}
								value={position}
							/>
							<Select
								options={remapSelectOptions(workActivityArray)}
								label={t('commons:form:activity')}
								placeholder={t('commons:form:placeholder:activity')}
								onChange={newValue => {
									handleSelectChange(newValue as SelectValue, 'activity')
								}}
								value={activity}
							/>
						</>
					)}
				</Form>
			</StepContainer>
			<StepCta formRef={formRef} />
		</>
	)
}

export default StepOtherInfo
