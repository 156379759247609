import React, {useMemo} from 'react'
import {useBoundStore, useCommonsStore} from '@/store/boundStore'
import {StepContainer} from '../styles.ts'
import CardOption from '@/components/ui/CardOption'
import Title from '@components/ui/Title'
import {Form} from 'reactstrap'
import {useTranslation} from 'react-i18next'
import StepCta from '@pages/onBoarding/steps/StepCta.tsx'
import {CommonItem} from '@/types/commonType.ts'
import Spinner from '@/components/ui/Spinner/index.tsx'
import {USER_TYPES} from '@utilities/constants'

const StepReason: React.FC = () => {
	const {step, whatHappened, setGeneralPatientData, userType, isInReview} = useBoundStore()
	const {cardsData, setCommonsData} = useCommonsStore()
	const {t} = useTranslation()

	const accessReason = useMemo(() => {
		return cardsData?.patient_data?.access?.reasons
	}, [cardsData])

	const handleSubmit = (value: CommonItem) => {
		setGeneralPatientData('whatHappened', value)
		if (isInReview) {
			setGeneralPatientData('isInDrawer', false)
			setGeneralPatientData('step', 12)
		} else {
			setCommonsData('isNextAnimation', true)
			setGeneralPatientData('step', step + 1)
		}
	}

	return (
		<>
			<StepContainer notAnimate={isInReview}>
				<Title title={t(`${userType}:patientData:cause:title`)} />
				<Form className={'h-100'}>
					{accessReason?.length ? (
						accessReason.map(i => {
							return (
								<CardOption
									title={i.name}
									subtitle={
										userType === USER_TYPES.patient ? i.description_self : i.description_caregiver
									}
									onClick={() => handleSubmit(i)}
									isAnimated
									key={i.id}
									isSelected={whatHappened?.id === i.id}
								/>
							)
						})
					) : (
						<Spinner size={40} />
					)}
				</Form>
			</StepContainer>
			<StepCta />
		</>
	)
}

export default StepReason
